import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import moneyTransferBackground from "../../assets/images/councilAndFaith/council-and-faith.jpg";
import ServiceDetailsContent from "../../components/service-details/councilAndFaith/ServiceDetailsContent";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import CouncilAndFaithServices from "../../components/service-details/councilAndFaith/CouncilAndFaithServices";


const CouncilAndFaithDetails = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={moneyTransferBackground}
        heading1={`Council and Faith: We value your faiths.`}
        description="We help you to connect to the people with same faith and beliefs."
        currentPage="CouncilAndFaith"
      />
      <div className="service-details pt-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="service-details-wrapper">
                <CouncilAndFaithServices />
                <ServiceDetailsContent />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default CouncilAndFaithDetails;
