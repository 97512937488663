import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import FooterOne from "../common/footer/FooterOne";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import CookiePolicyComponent from "../components/cookie/CookiePolicyComponent";


const CookiePolicy = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      
      <CookiePolicyComponent />
      <FooterOne />
    </>
  );
};

export default CookiePolicy;
