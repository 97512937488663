import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const PrivateHealthInsuranceBenefits = () => {
  const styles = {

    header: {
      textAlign: "center",
      marginBottom: "35px",
    },
    section: {
      marginBottom: "40px",
    },
    card: {
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
    },
    number: {
      fontSize: "2em",
      fontWeight: "bold",
      color: "#007bff",
    },
    title: {
      fontSize: "1.5em",
      fontWeight: "bold",
      margin: "10px 0",
    },
    subtitle: {
      fontSize: "1.2em",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    text: {
      fontSize: "1em",
      lineHeight: "1.6",
    },
    highlight: {
      color: "#007bff",
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.container} className="container pt-90">
      <div style={styles.header}>
        <h1>Benefits of a private health insurance for foreigners</h1>
        <p>
          Much more important than the amount of your insurance premium is what
          you get for it.
        </p>
      </div>
      <div style={styles.section}>
        <p>
          In the public health insurance, your health is covered with basic
          benefits. However, the scope of benefits can change at any time. In
          order to save money, the government can cancel or reduce benefits. <span style={{color: "#ED3137"}}>In
          private health insurance</span>, insured persons not only receive first-class
          benefits – they can even decide for themselves which benefits they
          place particular value on (e.g. preventive medical check-ups,
          alternative practitioners, etc.) by choosing their tariff. Once the
          private health insurance contract has been concluded, the insured
          benefits are guaranteed for life.
        </p>
      </div>
      {/* <div style={styles.section}>
        <h2 className="text-center">Better insured with private health insurance</h2>
        <p className="text-center mb-4">
          Wondering if private health insurance makes sense? Here are three
          everyday situations where you’ll notice the benefits of private health
          insurance.
        </p>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <div style={styles.card}>
              <div style={styles.number}>1.</div>
              <div style={styles.title}>Visual acuity correction</div>
              <div style={styles.subtitle}>Keep your vision</div>
              <p style={styles.text}>
                Jens is nearsighted and has been wearing glasses and sometimes
                contact lenses for years. How fortunate that he is insured with
                private health insurance. Because <span style={styles.highlight}>OnBoarding-Mate</span> pays him up to 500
                euros every two years for vision aids. Now he has decided to
                have his visual acuity corrected by laser. After a few days, he
                was able to see clearly without glasses. Now he starts every day
                with full vision.
              </p>
              <p style={styles.text}>
                The public health insurance would not have paid anything, but
                <span style={styles.highlight}> OnBoarding-Mate</span> did.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div style={styles.card}>
              <div style={styles.number}>2.</div>
              <div style={styles.title}>Private room</div>
              <div style={styles.subtitle}>First class in hospital</div>
              <p style={styles.text}>
                Tanja rides her racing bike to work every day. When she got
                caught in a streetcar track and fell off her bike, she broke her
                collarbone and needed surgery. Her <span style={styles.highlight}>OnBoarding-Mate</span> tariff covers both
                treatment by a chief physician and a private room in hospital.
                And so she was able to recover quickly undisturbed.
              </p>
              <p style={styles.text}>
                Eva, who is in the same hospital, is insured with public health
                insurance and unfortunately has no supplementary hospital
                insurance. She regularly wakes up every night in the multi-bed
                room because Bettina, her fellow patient, snores loudly.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mb-4">
            <div style={styles.card}>
              <div style={styles.number}>3.</div>
              <div style={styles.title}>Dentures & orthodontics</div>
              <div style={styles.subtitle}>For your best smile</div>
              <p style={styles.text}>
                Thomas had a large gap between his incisors for a long time,
                which is why he often didn’t dare to smile broadly and show his
                teeth in public. Unlike public health insurance, his <span style={styles.highlight}>OnBoarding-Mate </span> 
                tariff made orthodontic treatment possible for him, even as an
                adult.
              </p>
              <p style={styles.text}>
                His wife Marina is also insured with <span style={styles.highlight}>OnBoarding-Mate</span> and needs
                dentures. She is missing a tooth in the lower right-hand corner,
                which needs to be replaced. That, too, is covered up to 100
                percent in her tariff.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PrivateHealthInsuranceBenefits;
