import React from "react";

const AboutUsWhoAreWe = () => {
  return (
    <>
      <div className="about-block pt-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10 ">
              <h2 className="section-title mb-70">Who are we</h2>
              <p>
                Welcome to <b>Onboarding Mate</b> your common platform and
                trusted companion for all individuals who are new to Germany and
                seeking assistance with various aspects of settling down in a
                foreign land. We understand that the challenges of navigating a
                new country, especially without fluency in the local language,
                can be overwhelming.
                <br /> <br /> That's why we are here to simplify your
                transition, provide guidance, and offer solutions to help you
                thrive in your new German life.
              </p>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsWhoAreWe;
