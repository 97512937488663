import React from "react";
import { Card } from "react-bootstrap";
import { HouseholdInsurancePartners, LandingPageLink } from "../../../../utilities/constants";
import { Link } from "react-router-dom";

const HouseholdInsuranceDetails = () => {
  return (
    <>
      <h2>Protection for You & Your possessions</h2>
      <p>
        <b>Securing against fires, thefts, and water-related damage:</b> Whether
        your possessions are stolen, subjected to fire, or damaged by water,
        home insurance has got you covered.
        <br />
        <br />
        <b>Shelter in Times of Crisis</b>
        In the event that a fire or flood renders your apartment uninhabitable
        (extended coverage), insurance will take care of your accommodation
        expenses for 180 days.
        <br />
      </p>
      <br />

      <h2>Do I need Household Insurance?</h2>
      <p>
        While home contents and liability insurance might seem similar, they
        actually provide different types of protection. Home contents insurance
        safeguards the items in your residence, whereas liability insurance
        covers any harm you inflict on others, regardless of your location.
        <br />
      </p>
      <br />

      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <Link
            href={LandingPageLink[0].affiliateLink}
            rel="noopener noreferrer"
            className="l-btn"
          >
           Consult Advisors
          </Link>
        </div>
      </div>
      <br /><br />
      <h2>Our Partners</h2>

      <div className="health-insurance-partners current-account-partners">
        {HouseholdInsurancePartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div>
    </>
  );
};

export default HouseholdInsuranceDetails;
