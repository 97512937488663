import React from "react";

const ServiceDetailsContent = () => {
  return (
    <>
      <h2>Embrace a World Without Financial Barriers</h2>
      <p>
        <ol>
          <li>
            Our vision is to enable seamless living and working anywhere by
            offering instant, transparent, and convenient money transfers across
            borders—ultimately, at no cost to you.
            <br />
            <br />
          </li>
          <li>
            The Wise account is your global solution for managing money across
            borders. Designed for the world, it helps you save both money and
            time, allowing you to prioritize the activities you cherish.
          </li>
        </ol>
      </p>
      <h2>We Outsmart Thieves</h2>
      <p>
        Each month, our customers rely on us to securely handle X amount of
        their money. Here are key measures we take to protect them:
        <br />
        <br />
        <ol>
          <li>
            Our expert fraud and security teams are committed to safeguarding
            your funds.
          </li>
          <li>We employ 2-factor authentication to fortify your account.</li>
          <li>
            We store your money with reputable financial institutions for added
            peace of mind.
          </li>
        </ol>
      </p>
    </>
  );
};

export default ServiceDetailsContent;
