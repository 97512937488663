import React from "react";
import { individualSubService } from "../../../utilities/constants";
import Dropdown from "react-bootstrap/Dropdown";

const AboutExperienceItem = ({
  heading,
  description,
  img,
  index,
  id,
  affiliateLink,
}) => {
  let keys = [];
  let values = [];
  let hasAffiliateList = false;
  if (
    id === "househome" ||
    id === "familyAndPets" ||
    id === "settlingInAndMovingOut"
  ) {
    hasAffiliateList = true;
    keys = Object.keys(affiliateLink);
    values = Object.values(affiliateLink);
  }
  return (
    <>
      {/* <div className="col-xl-1 col-lg-1 pr-50"></div> */}
      <h2>{heading}</h2>
      <div className={`col-xl-6 col-lg-6 individual-service-row ${id}`} id={id}>
        {index % 2 === 0 ? (
          <div className=" single-service-item pr-50">
            <p>{description}</p>
            {!hasAffiliateList ? (
              <a
                href={affiliateLink}
                className="l-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Offer
              </a>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="l-btn"
                >
                  Get Offer
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {values.map((value, index) => (
                    <Dropdown.Item href={value} target="_blank">
                      {keys[index]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        ) : (
          <div className="experience-img">
            <img src={img} alt="" />
          </div>
        )}
      </div>
      <div className={`col-xl-6 col-lg-6 individual-service-row ${id}`} id={id}>
        {/* <h2>{heading}</h2> */}
        {index % 2 === 0 ? (
          <div className="experience-img">
            <img src={img} alt="" />
          </div>
        ) : (
          <div className=" single-service-item pl-50 individual-services-even">
            <p>{description}</p>
            {!hasAffiliateList ? (
              <a
                href={affiliateLink}
                className="l-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Offer
              </a>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="l-btn"
                >
                  Get Offer
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {values.map((value, index) => (
                    <Dropdown.Item href={value} target="_blank">
                      {keys[index]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        )}
      </div>
      {/* <div className="col-xl-1 col-lg-1 pr-50"></div> */}
    </>
  );
};

const ServiceDetailsContent = () => {
  return (
    <>
      <div className="experience-area pt-110 individual-service-container">
        <div className="container-fluid">
          {individualSubService.map((data, index) => (
            <div className="row pb-100 individual-service-row">
              <AboutExperienceItem
                id={data.id}
                key={data.id}
                index={index}
                heading={data.title}
                description={data.pageDescription}
                img={data.image}
                affiliateLink={data.affiliateLink}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceDetailsContent;
