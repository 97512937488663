import React from "react";
import { Card } from "react-bootstrap";

const HowIsItBetter = () => {
  return (
    <div className="row how-does-it-work pt-80">
      <h1 className="how-does-it-work-header">How We Keep Your Money Safe</h1>

      <div className="steps">
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>1</h1>
              {/* <h4>Wise is approved by the Reserve Bank of India (RBI)</h4> */}
              <p>
                Our expert fraud and security teams are committed to
                safeguarding your funds.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          {/* <Card.Img variant="top" src={partner.img} /> */}
          <Card.Body>
            <Card.Text>
              <h1>2</h1>
              {/* <h4>MFA</h4> */}
              <p>We employ 2-factor authentication to fortify your account.</p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          {/* <Card.Img variant="top" src={partner.img} /> */}
          <Card.Body>
            <Card.Text>
              <h1>3</h1>
              {/* <h4>Secure Sessions</h4> */}
              <p>
                We store your money with reputable financial institutions for
                added peace of mind.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        {/* <Card>
          <Card.Body>
            <Card.Text>
              <h1>4</h1>
              <h4>Backed by Banks</h4>
              <p>We hold your money with established financial institutions</p>
            </Card.Text>
          </Card.Body>
        </Card> */}
        {/* <Card>
          
          <Card.Body>
            <Card.Text>
              <h1>5</h1>
              <h4>Post doctorates and researchers</h4>
              <p>
                Feather helps with checking that everything is in order, and
                thus speeds up the process
              </p>
            </Card.Text>
          </Card.Body>
        </Card> */}
      </div>
    </div>
  );
};

export default HowIsItBetter;
