import React from "react";
import { Card } from "react-bootstrap";
import { DentalInsurancePartners } from "../../../../utilities/constants";

const DentalInsuranceDetails = () => {
  return (
    <>
      <h2>Should I Have Dental Insurance?</h2>
      <p>
        <b>Dental Care is Expensive:</b> Expert dental and prosthetic cleanings
        can come with a hefty price tag, typically falling between €85 and €125,
        based on your dental care provider.
        <br />
        <br />
        With dental insurance, these costs are taken care of without any annual
        restrictions, facilitating healthier and longer-lasting teeth.
        <br />
        <br />
        <b>Preventive Care: </b>Dental insurance usually covers preventive
        services like cleanings and exams, encouraging regular dental visits.
        Regular checkups can detect problems early and help prevent more serious
        and costly issues down the line.
        <br />
      </p>
      <br />
      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <a
            href={DentalInsurancePartners[0].affiliateLink}
            rel="noopener noreferrer"
            className="l-btn"
          >
            Consult Advisors
          </a>
        </div>
      </div>
      <br /><br />
      <h2>Our Partners</h2>

      <div className="health-insurance-partners current-account-partners">
        {DentalInsurancePartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div>
    </>
  );
};

export default DentalInsuranceDetails;
