import React from "react";
// import { Card } from "react-bootstrap";
// import { RentalDepositPartners } from "../../../../utilities/constants";

const RentalDepositsDetails = () => {
  return (
    <>
      <h2>Want to know more?</h2>
      {/* <p>
        Sending money shouldn’t cost the earth, so we built Wise to save you
        money when you transfer and exchange internationally. We charge as
        little as possible: right now a tiny fee, eventually free.
        <br />
        <br />
        Our dream is for people to live and work anywhere seamlessly. That means
        money without borders: moving it instantly, transparently, conveniently,
        and — eventually — for free.
        <br />
        <br />
        The Wise account is the universal way for you to manage money
        internationally. It's made for the world. And it's built to save your
        money and time, so you can do more of the things you love.
      </p> */}
      {/* <br /> */}
      {/* <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <a
            href={RentalDepositPartners[0].affiliateLink}
            target="_blank"
            rel="noopener noreferrer"
            className="l-btn"
          >
            Avail Rental Deposit
          </a>
        </div>
      </div> */}
      {/* <br /> */}
      {/* <h2>Our Partners</h2> */}
      <p>Stay tuned, we're coming with new services shortly...</p>
      {/* <div className="health-insurance-partners current-account-partners">
        {RentalDepositPartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div> */}
    </>
  );
};

export default RentalDepositsDetails;
