import React from "react";
// import HomeTwoFeature1 from "../../assets/images/icon/guarantee.png";

const OtherServicesComponent = ({ currentPage, subMenu, className }) => {
  return (
    <>
      {/* <div className="features-area bg-white pt-110 pb-110"> */}
      <div className="container">
      <div class>
        <div className={`${className}  row other-services-row`}>
          {subMenu.map((menu) => {
            if (menu.value !== currentPage) {
              return (
                <div className="single-feature text-center box-shadow-3 single-service">
                  <a href={menu.path}>
                    <div className="single-feature-icon">
                      <img src={menu.img} alt="" />
                    </div>
                    <h5>{menu.title}</h5>
                  </a>
                </div>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default OtherServicesComponent;
