import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import insuranceBackground from "../../assets/images/insuranceDetails/legalInsurance/legal-insurance.jpg";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import {
  
  LegalInsurancePartners,
  TYPES_OF_FAQS,
  insuranceSubMenu,
} from "../../utilities/constants";
import WhatsCovered from "../../components/service-details/insurance/legalInsurance/WhatsCovered";
import LegalInsuranceDetails from "../../components/service-details/insurance/legalInsurance/LegalInsuranceDetails";
import ProjectFaq from "../../components/project-details/ProjectFaq";
import OtherServices from "../../components/service-details/OtherServices";
import OurAdvisor from "../../common/ourAdvisors/OurAdvisor";

const LegalInsurance = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={insuranceBackground}
        heading1={`Legal Protection: Just One Click Away`}
        description="Our robust legal insurance delivers expert counsel and prompt conflict management. Join us, and safeguard your future!"
        currentPage="legalInsurance"
        className="health-insurance-hero"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="health-insurance-details-wrapper">
                <LegalInsuranceDetails />


                <WhatsCovered currentPage="publicHealthInsurance" />
              </div>
            </div>
            <div className="mt-5"> 
            < OurAdvisor />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <div className="cta-area theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cta-style-1-wrapper text-center">
                <p className="health-insurance-cta">
                  Legal Insurance at your service.
                </p>
                <a
                  href={LegalInsurancePartners[0].affiliateLink}
                 
                  rel="noopener noreferrer"
                  className="l-btn"
                >
                  Claim Legal Insurance
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works pt-110 pb-110 faq-section-row">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-50">
                <h2>FAQs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <ProjectFaq name={TYPES_OF_FAQS.legalInsurance} />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <OtherServices
        currentPage="legalInsurance"
        subMenu={insuranceSubMenu}
        heading={"Other Insurance Services"}
        className={"insurance-services"}
      />
      <FooterOne />
    </>
  );
};

export default LegalInsurance;
