import React from "react";
import { Link } from "react-router-dom";

const DetailedAdvisor = ({ member }) => {
  return (
    <>
      <div className="team-area white-bg pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1"></div>
            <div className="col-xl-3 col-lg-3 col-md-8 col-12">
              <div className="single-team-style-2 text-center">
                <div className="single-team-style-2-img single-team-member-2-img detail-advisor-img">
                  <img src={member.img} alt={member.imgAlt} />
                </div>
              </div>
            </div>
            <div className="col-xl-1 col-lg-1"></div>
            <div className="col-xl-6 col-lg-6 col-md-12 single-team-member-2-desc">
              <div className="detail-advisor-heading">
                <div className="single-team-content-style-2">
                  <h4>{member.heading}</h4>
                  <span>{member.position}</span>
                </div>
                {member.certificateLink && <div className="single-team-content-style-2">
                  <Link
                    style={{ width: "50px", height: "50px" }}
                    rel="noopener noreferrer"
                    to={member.certificateLink}
                  >
                    <img
                      src={member.certificate}
                      alt="WIN Versicherungen"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </Link>
                </div>}
              </div>
              <hr style={{ borderTop: "#a8864b solid 2px" }} />
              <h4>{member.descriptionHeading}</h4>
              <p>{member.descriptionPara}</p>
            </div>
            <div className="col-xl-1 col-lg-1"></div>
            <div className="col-xl-1 col-lg-1"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailedAdvisor;
