import React from "react";
import card1 from "../../../assets/images/HomeLogo/Earth.avif";
import card2 from "../../../assets/images/HomeLogo/gaurrenty.avif";
import lock from "../../../assets/images/HomeLogo/lock.avif" ;
import card3 from "../../../assets/images/HomeLogo/thumb.avif";
import { LandingPageLink } from "../../../utilities/constants";
import { Link } from "react-router-dom";
// Advantages--
const OtherServicesHome = ({ currentPage, heading, className }) => {
  const subMenu = [
    {
      id: "faithAndReligion",
      value: "faithAndReligion",
      title: "Championing Climate Protection",
      description:
        " OnBoarding-Mate contributes to climate protection with many sustainable actions.",
      pageDescription:
        "Discovering the ins and outs of a new city, whether it's for a job interview or after finding a new home, greatly enhances the comfort of relocating to an unfamiliar place. Local-Worldwide assists you by providing the best offers like pre-orientation call, Convenient pick-up, Guided orientation tour to help you.",
      img: card1,
      affiliateLink: "https://local-worldwide.com/goto/onboardingorientation/",
    },
    {
      id: "violenceHelpline",
      value: "violenceHelpline",
      title: "The OnBoarding-Mate money-back guarantee",
      path: "/council-and-faith/violence-helpline",
      description:
        "We are the health insurance service provider in Germany to return your aging reserves if you are not satisfied. ",
      pageDescription:
        "The magnitude of individuals moving to a foreign country where they are not native or do not hold citizenship, can be overwhelming for most individuals. However, with the assistance of Local-Worldwide, navigating through the extensive bureaucratic procedures becomes a seamless experience.",
      img: card2,
      affiliateLink: "https://local-worldwide.com/goto/onboardingimmigration/",
    },
    {
      id: "highestCustomerSatisfaction",
      value: "highestCustomerSatisfaction",
      title: "Highest customer satisfaction",
      path: "/council-and-faith/highest-customer-satisfaction",
      description:
        " OnBoarding-Mate boasts the highest satisfaction levels and an impressive 98% recommendation rate, solidifying its position as the top choice in the insurance sector. ",
      pageDescription:
        "The magnitude of individuals moving to a foreign country where they are not native or do not hold citizenship, can be overwhelming for most individuals. However, with the assistance of Local-Worldwide, navigating through the extensive bureaucratic procedures becomes a seamless experience.",
      img: card3,
      affiliateLink: "https://local-worldwide.com/goto/onboardingimmigration/",
    },
    {
    id: "YourDataIsSafe",
    value: "highestCustomerSatisfaction",
    title: "Your Data is Safe with Us",
    path: "/council-and-faith/highest-customer-satisfaction",
    description:
      " Experience peace of mind with our private health insurance, offering top-tier data security and comprehensive coverage. Your information is safe with us.",
    pageDescription:
      "The magnitude of individuals moving to a foreign country where they are not native or do not hold citizenship, can be overwhelming for most individuals. However, with the assistance of Local-Worldwide, navigating through the extensive bureaucratic procedures becomes a seamless experience.",
    img:lock,
    affiliateLink: "https://local-worldwide.com/goto/onboardingimmigration/",
  },
  ];

  const cardStyle = {
    width: "300px",
    height: "auto",
    margin: "10px",
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const imageContainerStyle = {
    width: "100%",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const imageStyle = {
    width: "80%",
    height: "80%",
    objectFit: "contain",
  };

  const contentStyle = {
    padding: "15px",
    textAlign: "center",
    flexGrow: 1,
  };

  return (
    <div className="pt-110 pb-110 other-srv-bg" data-overlay="black" data-opacity="7">
      <div className="container">
        <div className="row position-relative">
          <div className="col-xl-12">
            <div className="section-title text-center mb-100">
              <h2 className="text-center">Advantages of our private health insurance</h2>
            </div>
          </div>
        </div>
        <div className="row position-relative">
          <div className="container">
            <div className="row justify-content-center">
              {subMenu.map((item) => (
                <div key={item.id} className="text-center" style={cardStyle}>
                  <div>
                    <div style={imageContainerStyle}>
                      <img src={item.img} alt={item.title} style={imageStyle} />
                    </div>
                    <div style={contentStyle}>
                      <h5>{item.title}</h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

          </div>
          <div className="d-flex justify-content-center pb-40">
            <Link
                  to={LandingPageLink[0].affiliateLink}
                  className="b-a-btn"
                  rel="noopener noreferrer"
                  style={{
                    position: "absolute",
                    bottom: "-50px",
                    display: "inline-block",
                    padding: "12px 24px",
                    backgroundColor: "#014973" ,
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    textDecoration: "none",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease",
                    
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#007CC3")
                    
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#014973" )
                  }
                >
                  Book an appointment
                </Link>
                </div>
        </div>
      </div>
    </div>
  );
};

export default OtherServicesHome;
