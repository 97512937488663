import React from "react";
import FaqAccordion from "../../components/accordion/AccordionOne";

const ServiceFaq = ({ currentPage }) => {
  return (
    <>
      <h2 className={currentPage === "publicHealthInsurance" ? "pt-80" : "mt-35"}>
        Key Benefits
      </h2>
      <div className="faq insurance-faq">
        <FaqAccordion
          index={0}
          shouldOpen={false}
          question="Healthcare Expenses"
          answer="Germany boasts a world-class healthcare system, but medical expenses can be substantial. According to statistics, the average cost of a hospital stay in Germany is around €3,500 per day. Having health insurance ensures that students and working-class individuals are covered for medical treatments, doctor visits, medications, and emergencies, preventing potential financial burdens."
        />
        <FaqAccordion
          index={1}
          question="Accidents and Injuries"
          answer="Statistics reveal that the most common cause of accidents among students and workers in Germany are falls and transportation-related incidents. Adequate insurance coverage, such as personal liability and accident insurance, provides financial protection against unexpected events, covering medical costs, rehabilitation, and potential legal expenses."
        />
        <FaqAccordion
          index={2}
          question="Income Protection"
          answer="For the working class, insurance such as disability and income protection provides a safety net. Statistics indicate that around 1.7 million Germans suffer from a severe disability, making it impossible for them to work. Having the right insurance coverage ensures that individuals receive financial support in the event of a disability, safeguarding their income and providing a sense of security for themselves and their families."
        />
        <FaqAccordion
          index={3}
          question="Property Protection"
          answer="Students and working professionals often possess valuable assets such as laptops, smartphones, and other personal belongings. Statistics show that thefts and burglaries are common occurrences, with approximately 167,000 reported cases in Germany annually. Insurance coverage such as renter's insurance or personal property insurance helps replace or repair stolen or damaged items, minimizing financial losses."
        />
        <FaqAccordion
          index={4}
          question="Legal Protection"
          answer="Legal issues can arise unexpectedly, and the costs associated with legal representation and court proceedings can be exorbitant. Insurance policies such as legal protection insurance provide coverage for legal expenses, ensuring that individuals have access to professional legal assistance without worrying about financial implications."
        />
      </div>
    </>
  );
};

export default ServiceFaq;
