import React from "react";
import { Card } from "react-bootstrap";
// import Traffic from "../../../../assets/images/insuranceDetails/legalInsurance/legal-traffic.svg";
// import Personal from "../../../../assets/images/insuranceDetails/legalInsurance/legal-personal.svg";
// import Professional from "../../../../assets/images/insuranceDetails/legalInsurance/legal-professional.svg";
// import Property from "../../../../assets/images/insuranceDetails/legalInsurance/legal-property.svg";

const WhatsCovered = () => {
  return (
    <div className="row how-does-it-work pt-80 whats-covered">
      <h1 className="how-does-it-work-header">What is covered</h1>

      <div className="steps">
        <Card>
          <Card.Body>
            <Card.Text>
              {/* <img src={Traffic} alt="home"></img> */}
              <h4>1</h4>
              <h4>Get a Quote</h4>
              <p>
                Secure your personalized quote and coverage specifics online in
                a mere 60 seconds. Our experts are available for further
                guidance.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          {/* <Card.Img variant="top" src={partner.img} /> */}
          <Card.Body>
            <Card.Text>
              {/* <img src={Personal} alt="keys"></img> */}
              <h4>2</h4>
              <h4>Submit the Application Online</h4>
              <p>
                Our 20 years of expertise in the insurance market will help you
                to compare dozens of companies and find you the best rates &
                coverage.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          {/* <Card.Img variant="top" src={partner.img} /> */}
          <Card.Body>
            <Card.Text>
              {/* <img src={Professional} alt="pets"></img> */}
              <h4>3</h4>
              <h4>Await Your Confirmation</h4>
              <p>
                In case we need additional information, we'll reach out.
                Otherwise, rest easy knowing that we'll automatically confirm
                your coverage within a few days.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default WhatsCovered;
