import React from "react";

const PrivacyPolicyComponent = () => {
  return (
    <>
      <div className="about-block pt-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 pr-50">
              <p className="imprint-info copyright">
                <h2>Responsible Entity</h2>
                We are delighted by your visit to our website and would like to
                take this opportunity to introduce ourselves as the entity
                responsible for data protection, as defined by applicable law:
                <br />
                <br />
                Managing Director: Marc Bernardo Carlson
                <br />
                Sales tax identification number according to § 27 a sales tax
                law: DE 356047406
                <br />
                <br />
                <h2> General Information</h2>
                In compliance with our legal obligations, we would like to
                provide you with information regarding the collection and usage
                of your personal data. <br />
                <br />
                When you utilize our website, your personal data is collected.
                This may occur when you manually input data, such as your email
                address, or when our system automatically gathers data during
                your website visit, regardless of the device or software you
                use. <br />
                <br />
                The provision of data within our app is voluntary, and no
                disadvantages arise from not providing it. However, certain data
                may be necessary to provide services or enter into contracts,
                and we will notify you when such information is required. <br />
                <br />
                This website collects user personal data exclusively in
                accordance with applicable data protection laws, including the
                General Data Protection Regulation (GDPR). Definitions of legal
                terms used in this document are provided in Art. 4 of the GDPR.{" "}
                <br />
                <br />
                The GDPR permits data processing in three specific cases:
                <ol>
                  <li>
                    When you have given consent for us to process your data, as
                    outlined in Art. 6 para. 1 (a) and 7 GDPR. We will inform
                    you in detail, on each occasion, about the purposes and
                    circumstances under which we will process your data in this
                    Privacy Policy and with consent as per Art. 4 no. 11 GDPR.
                  </li>
                  <li>
                    When processing your personal data is necessary for
                    negotiating, concluding, or folfilling a contract, as
                    specified in Art. 6 para. 1 (b) GDPR.
                  </li>
                  <li>
                    When the balancing of interests confirms that processing is
                    necessary to protect our legitimate interests, as stated in
                    Art. 6 para. 1 (f) GDPR. This primarily includes our
                    interests in analyzing, optimizing, and securing our website
                    offerings, such as user behavior analysis, creating profiles
                    for advertising purposes, storing access data, and utilizing
                    third-party providers.
                  </li>
                </ol>
                <h2>Inventory Data</h2>
                We gather inventory data to the extent necessary for the
                establishment, negotiation, or modification of a contract
                (including those without remuneration) between us and the user.
                This may include customer data (such as name, address), contact
                data (such as email address, phone number), and service data
                (such as ordered services, duration, payment). When establishing
                the user relationship, we will request this information (such as
                name, address, and email address) and inform you which details
                are essential for establishing the user relationship.
                <br />
                <br />
                <h2>Usage Data</h2>
                In order to enable users to utilize the services offered on our
                website, we also collect usage data. This may include usage
                information (such as visited websites or sections, duration of
                visit, interest in services), content data (such as data, text,
                images, sounds, videos entered or uploaded by you), and meta
                data (such as device identity, location, IP address).
                <br />
                <br />
                We will only combine usage data if it is necessary for billing
                purposes. Otherwise, we will pseudonymously aggregate usage data
                only if you have not objected. You may submit this objection at
                any time to the address provided in the "About Us" section or
                the responsible authority specified in this Privacy Policy.
                <br />
                <br />
                The legal basis for this data processing is our legitimate
                interest in accordance with Art. 6 para. 1 (f) GDPR in analyzing
                the website and your usage, and possibly the statutory
                permission to store data as part of contract negotiations under
                Art. 6 para. 1 (b) GDPR.
                <br />
                <br />
                <h2>Hoster</h2>
                <h2>webgo GmbH</h2>
                Our website is hosted on the internet by a service provider,
                webgo GmbH, located at Heidenkampsweg 81, D-20097 Hamburg. We
                have a data processing agreement in place with our provider,
                ensuring that they process data in accordance with our
                instructions. For more information on how our provider handles
                data, please refer to their privacy policy at
                https://www.webgo.de/datenschutz/. The legal basis for this data
                processing is twofold: our legitimate interest in providing a
                technologically flawless online offering, optimizing it
                economically under Art. 6 para. 1 (f) GDPR, and our contractual
                or pre-contractual relationship under Art. 6 para. 1 (b) GDPR.
                <br />
                <br />
                Additionally, our provider stores server log files containing
                information automatically transmitted by your browser each time
                you visit our website. This information includes your IP
                address, browser type and version, host name, visit time, the
                page you came from, the page you accessed, exact usage time, and
                the amount of data transferred. These logs are used solely for
                statistical purposes and do not allow us to personally identify
                you as a user.
                <br />
                <br />
                <h2>Advertisements</h2>
                Prior to sending you any advertisements, we will seek your
                explicit consent as defined in Art. 4 no. 11 GDPR, unless it
                pertains to similar products to the one you have already
                acquired. This consent will be obtained specifically when you
                grant us permission to send our newsletter or when you complete
                a contact form. You have the right to withdraw your consent at
                any time, as outlined in the subsequent "Consent" section.
                <br />
                <br />
                <div style={{ border: "2px solid", padding: "5px" }}>
                  Furthermore, if we utilize your personal data for direct
                  marketing purposes, you have the option to object to the use
                  of your data for such purposes at any time. This objection can
                  be made through any of our contact methods, including email
                  sent to the address provided in the "Legal Notice" section,
                  without any formal requirements. Once received, we will cease
                  using your data for direct marketing purposes.
                </div>
                <br />
                <br />
                <h2>First Contact via Electronic Request</h2>
                If you reach out to us electronically (such as through email,
                fax, phone, messenger, etc.), we will store and process the
                information you provide, including your name, contact details,
                and the content of your request. This processing is based on our
                legitimate interest in maintaining effective communication with
                our customers, as outlined in Article 6 para. 1 (a) GDPR.
                Additionally, if your request pertains to entering into or
                fulfilling a contract, the processing is also based on Article 6
                para. 1 (b) GDPR.
                <br />
                <br />
                We will only share this data with third parties when necessary
                to fulfill the contract (as per Article 6 para. 1 (b) GDPR),
                when there is an overriding interest in providing efficient
                services (as per Article 6 para. 1 (f) GDPR), when you have
                given your consent (as per Article 6 para. 1 (a) GDPR), or when
                there is another legal permission or obligation.
                <br />
                <br />
                You have the right to request information about the purpose of
                the data processing, its origin, and any recipients of the data.
                Furthermore, you can request the correction, deletion, or
                restriction of processing of your personal data. You have the
                right to object to the (further) processing of your data at any
                time and the right to data portability. If necessary, you also
                have the right to lodge a complaint with the relevant
                supervisory authority.
                <br />
                <br />
                Generally, your data will only be stored for as long as
                necessary for the specific purpose of the data processing. In
                some cases, longer storage may be possible, particularly when
                required to assert our rights, for other legitimate interests,
                or due to statutory retention obligations (e.g., tax
                record-keeping, statute of limitations).
                <br />
                <br />
                <h2>Consent</h2>
                Whenever we request your consent for the processing of your
                data, we will provide clear and easily understandable
                information about the specific cases in which your consent is
                being sought. Granting consent is entirely voluntary, and any
                benefits you seek can still be obtained without providing
                consent. Simply reach out to us and let us know.
                <br />
                <br />
                You have the right to withdraw your consent for the processing
                of your personal data at any time. There are no specific formal
                requirements for this. You can contact us through our contact
                form, send an email to the address provided in the "About Us"
                section, or use an unsubscribe link if available. Please note
                that the withdrawal of consent does not affect the lawfulness of
                any data processing conducted prior to your withdrawal.
                <br />
                <br />
                <h2>Storage Period</h2>
                In general, we will retain your data for as long as it is
                necessary to fulfill the purpose of the specific data
                processing. However, there are circumstances where we may store
                your data for a longer period if it is required to protect our
                rights or serve our legitimate interests.
                <br />
                <br />
                For your inventory data, which are necessary for the performance
                of a contract (including non-remunerative contracts), we will
                retain this data until the completion or termination of the
                contractual relationship, including the applicable limitation
                period (typically 2 or 3 years), and an additional reasonable
                duration to account for any potential interruptions to the
                limitation period.
                <br />
                <br />
                Regarding your usage data collected during your website usage,
                we will retain it only for as long as it is necessary for the
                proper functioning of our website and as long as we maintain a
                legitimate interest. Statistical information will primarily be
                stored in pseudonymous form.
                <br />
                <br />
                Furthermore, we will retain your data for the duration required
                by law. This particularly applies to tax-related record-keeping
                obligations, which typically require records to be kept for 6 or
                even 10 years.
                <br />
                <br />{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyComponent;
