import React from "react";
import { Card } from "react-bootstrap";
import { ExpatInsurancePartners } from "../../../../utilities/constants";

const ExpatInsuranceDetails = () => {
  return (
    <>
      <h2>Why Choose Expat Health Insurance?</h2>
      <p>
        Expat health insurance, also known as "incoming insurance," provides an
        optimal solution for the initial one to two years of your stay in
        Germany.
        <br />
        <br />
        <b>Meets Visa Prerequisites</b>
        Expat health insurance helps in obtaining your initial visa or residence
        permit by providing reliable and cost-effective coverage. Note that
        certain visa exclusions may apply.
        <br />
        <br />
        <b>Simplified & Quick Digital Claims</b>
        Claims management has never been easier; simply upload a photo of your
        claim to your Feather account.
        <br />
      </p>
      <br />
      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <a
            href={ExpatInsurancePartners[0].affiliateLink}
            rel="noopener noreferrer"
            className="l-btn"
          >
            Consult Advisors
          </a>
        </div>
      </div>
      <br /><br />
      <h2>Our Partners</h2>

      <div className="health-insurance-partners current-account-partners">
        {ExpatInsurancePartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div>
    </>
  );
};

export default ExpatInsuranceDetails;
