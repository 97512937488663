import React from "react";
import FaqAccordion from "../accordion/AccordionOne";

const ProjectFaq = (props) => {
  return (
    <>
      <div className="faq">
        {props.name.map((item, index) => (
          <FaqAccordion
          key={index}
            shouldOpen={true}
            index={index}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </div>
    </>
  );
};

export default ProjectFaq;
