import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import oneStopShop from "../../assets/images/oneServiceStop/one-stop-shop.jpg";
// import ServiceFaq from "../../components/service-details/ServiceFaq";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import ServiceDetailsContent from "../../components/service-details/oneStopService/ServiceDetailsContent";

const OneStopShopDetails = () => {
  return (
    <div className="one-stop-service-wrapper">
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={oneStopShop}
        heading1={`One Stop Shop For You.`}
        // heading2={`transferring money`}
        // heading3={`to near and dear?`}
        description="Answer to all your queries."
        currentPage="OneStopShop"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="service-details-wrapper">
                {/* <MoneyTransferServices /> */}
                <ServiceDetailsContent />

                {/* <ServiceFaq /> */}
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <FooterOne />
    </div>
  );
};

export default OneStopShopDetails;
