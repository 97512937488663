import React from "react";
import { Card } from "react-bootstrap";
import { LandingPageLink, PublicHealthInsurancePartners } from "../../../../utilities/constants";

const PublicHealthInsuranceDetails = () => {
  return (
    <>
      <h2>Know more about Public Health Insurance</h2>
      <p>
        Step into a world of comprehensive health insurance coverage with XXXX,
        your trusted partner in Germany. We know that navigating the complex
        landscape of insurance can feel overwhelming, but worry not, as we are
        here to simplify the process for you.
        <br />
        <br />
        Our utmost priority is your health and well-being, which is why we
        strive to offer you the finest health insurance options and expert
        guidance, ensuring that you have the perfect coverage that aligns with
        your unique needs.
        <br />
        <br /> Whether you're a newcomer to Germany or seeking to enhance your
        existing plan, trust us to provide you with the ultimate protection.
        Receive a complimentary quote and embark on a journey towards
        tranquillity for yourself and your loved ones.
        <br />
      </p>
      <br />
      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <a
            href={LandingPageLink[0].affiliateLink}
            rel="noopener noreferrer"
            className="l-btn"
          >
            Buy Insurance
          </a>
        </div>
      </div>
      <br />
      <br />
      <h2>Our Partners</h2>

      <div className="health-insurance-partners">
        {PublicHealthInsurancePartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
              {/* <Card.Body>
                <Card.Text>{partner.description}</Card.Text>
              </Card.Body> */}
            </Card>
          </a>
        ))}
      </div>
    </>
  );
};

export default PublicHealthInsuranceDetails;
