import React from "react";
// import { Card } from "react-bootstrap";
// import { RentalDepositPartners } from "../../../../utilities/constants";

const ViolenceHelplineDetails = () => {
  return (
    <>
      <h2>What does the service provide?</h2>
      <p>
        The "Violence against Women" help{" "}
        <a
          href="https://www.hilfetelefon.de/en.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          hotline{" "}
        </a>
        offers anonymous counseling by phone, chat or e-mail for women who are
        affected or threatened by violence. The goal is to provide affected
        women with low-threshold access to professional help.
        <br />
        <br /> The service is also aimed at people from women's social
        environment and professionals who are confronted with the issue of
        violence against women. The help hotline is available around the clock
        and offers confidential and anonymous counseling in various languages.
        <br />
        <br /> It provides barrier-free access to information and support as
        well as referrals to local institutions. The help line provides advice
        on various forms of violence against women, including domestic violence,
        stalking, forced marriage and sexual harassment in the workplace, among
        others.
      </p>
      <br />

      <br />
    </>
  );
};

export default ViolenceHelplineDetails;
