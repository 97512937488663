import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import breadcrumbBg from "../assets/images/contact/contact-us.jpg";
import ContactForm from "../components/contact/ContactForm";
import Subscribe from "../components/subscribe/Subscribe";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import FooterOne from "../common/footer/FooterOne";
import HeroService from "../components/hero/HeroServices";
import { Helmet } from "react-helmet-async";
import TestimonialStyleFour from "../components/testimonial/TestimonialStyleFour";


const Contact = () => {
  return (
    <>

    <Helmet>
      <title>
      Buy Health Insurance in Germany 
      </title>
      <meta name="description" content="Planning to buy health insurance in Germany? Contact us today to explore comprehensive packages and get personalized support from our expert team. " />
      <meta name="keywords" content="Best Health Insurance in Germany, Private Health Insurance Germany, Medical Insurance Germany, Family Health Insurance Germany" />
    </Helmet>

      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={breadcrumbBg}
        heading1={`Contact us whenever you want!`}
        description="Always available for you."
        currentPage="dentalInsurance"
        className="health-insurance-hero"
      />
      <ContactForm />
     <TestimonialStyleFour />
      <Subscribe />
      <FooterOne />
    </>
  );
};

export default Contact;
