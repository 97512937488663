import React from "react";
import CustomForm from "../home1/CustomForm";

const FeedbackForm = () => {
  return (
    <>
      <div className="contact-area white-bg pt-110 pb-110">
        <div className="container">
          <div className="box-shadow-3 pt-60 pb-60 pl-80 pr-80 mr-60 ml-60 feedback-form">
            <div className="row" id={"feedback"}>
              <div className="col-xl-6 col-lg-6 second-col">
                <div className="feedback-box contact-box theme-bg-1 pt-60 pb-35 pl-35 pr-35 mr-40">
                  {/* <span>Guiding Excellence!</span> */}
                  <h3>Your Feedback Is Important!</h3>
                  <p>
                    Help us get better by providing your feedback.
                    <br />
                    <br />
                    <br />{" "}
                  </p>
                  {/* <ul className="mt-40">
                    <li>
                      <FaHeadphones />
                      <span>
                        Emergency Line: <Link to="/">(002) 012612457</Link>
                      </span>
                    </li>
                    <li>
                      <FaEnvelopeOpen />
                      <span>
                        Your Email: <Link to="/">onboardingmate@gmail.com</Link>
                      </span>
                    </li>
                    <li>
                      <FaMapMarkerAlt />
                      <span>Location: 6202, Germany</span>
                    </li>
                    <li>
                      <FaClock />
                      <span>Mon - Fri: 8:00 am - 7:00 pm</span>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="contact-form pr-70">
                  <div className="section-title ">
                    {/* <span>Need Our Service Or Have Queries</span> */}
                    <h2>Send Us Feedback.</h2>
                  </div>
                  <CustomForm buttonText={`Provide feedback`} currentPage="Feedback"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackForm;
