import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import HeroStyleOne from "../components/hero/HeroStyleOne";
import ServiceStyleOne from "../components/services/ServiceStyleOne";
import Band from "../components/home1/ContactUsForm";
import FAQSection from "../components/faqSection/FAQSection";
import FooterOne from "../common/footer/FooterOne";
import AcceptCookies from "./AcceptCookies";
import ServiceStyleFour from "../components/services/ServiceStyleFour";
import { Helmet } from "react-helmet-async";
import AboutBlocktwo from "../components/about/AboutBlockTwo";
import HomeHeroService from "../components/hero/HomeHero";
import Affordable from "../components/service-details/insurance/privateHealth/TakeTheStepHome";
import PersonalizedAdvice from "../components/about/PersonalizedAdvice";
import TestimonialStyleFour from "../components/testimonial/TestimonialStyleFour";
import OtherServicesHome from "../components/service-details/oneStopService/OtherServicesHome";

const HomeDefault = () => {
  return (
    <>
      <Helmet>
        <title>OnBoarding Mate - Comprehensive Insurance Solutions</title>
        <meta
          name="description"
          content="Are you looking for a health insurance company in Germany? Trust OnBoarding Mate for comprehensive insurance solutions!"
        />
        <meta
          name="keywords"
          content="Public Health Insurance Germany, Germany health Insurance, Private Health Insurance Germany"
        />
      </Helmet>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroStyleOne />
      <HomeHeroService />
      <ServiceStyleFour />
      <AboutBlocktwo />
      <TestimonialStyleFour />
      <OtherServicesHome />
      <Affordable />
      <PersonalizedAdvice />
      <FAQSection />
      <Band />
      <ServiceStyleOne />
      <FooterOne />
      <AcceptCookies />
    </>
  );
};

export default HomeDefault;
