import React from 'react'
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import breadcrumbBg from '../assets/images/breadcrumbBg.avif'
import HeroService from '../components/hero/HeroServices'
import Subscribe from '../components/subscribe/Subscribe';
import FooterOne from '../common/footer/FooterOne';
import BlogStyleOne from '../components/blog/BlogStyleOne';



const Blog = () => {
    return (
        <>
            <HeaderTop />
            <HeaderBottomOne />
            <HeroService
        heading1="Explore Insights : Our Blogs"
        breadcrumbBg={breadcrumbBg}
        description="We are available for you, 24/7"
        currentPage="Our Blog"
      />
            <BlogStyleOne />

            <div  className='ms-5 mb-5' >
            
            </div>
            <Subscribe />
            <FooterOne />
        </>
    )
}

export default Blog