import React from "react";
import { FaArrowRight } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import { servicesSubMenu } from "../../utilities/constants";

const CustomForm = ({ buttonText, currentPage }) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [service, setService] = React.useState(servicesSubMenu[0].title);

  const clearFields = () => {
    setEmail("")
    setName("")
    setError("")
    setMessage("")
    setSubject("")
  };

 
  const handleEmailChange = (e) => {
    setSuccess(false);
    setError(false);
    setEmail(e.target.value);
  };
  const handleNameChange = (e) => {
    setSuccess(false);
    setError(false);
    setName(e.target.value);
  };
  const handleSubjectChange = (e) => {
    setSuccess(false);
    setError(false);
    setSubject(e.target.value);
  };
  const handleMessageChange = (e) => {
    setSuccess(false);
    setError(false);
    setMessage(e.target.value);
  };

  const sendEmail = async (event) => {
    event.preventDefault();


    try {
      const response = await fetch(
        "https://api.hubapi.com/crm/v3/objects/contacts",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer pat-na1-c759e60e-9ba1-4995-86ee-0024f843c827",
          },
          body: JSON.stringify({
            properties: {
              email: email,
              firstname: name,
              subject : subject,
              message : message
            },
          }),
        }
      );
      const responseData = await response.json();
      console.log("Form data submitted successfully:", responseData);
      clearFields();
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };
  return (
    <form className="mc__form">
      <div className={`row ${currentPage==="Home" ? "dropdown-contact" : ""} `}>
        {/* <div className="col-xl-6 dropdown-row">
          <label>Select Service</label>
        </div> */}
        <div className="col-xl-12 dropdown-row">
          <Dropdown
            onSelect={(_, event) => {
              setService(event.target.text);
            }}
          >
            <Dropdown.Toggle
              id="dropdown-item-button"
              title={service}
              className="dropdown-button"
            >
              {service}
            </Dropdown.Toggle>
            {/* onClick={(e) => setService(e.target.value)} */}
            <Dropdown.Menu>
              {servicesSubMenu.map(
                (service) =>
                  service.subNav && (
                    <Dropdown.Item>
                      {service.title}
                      {service.subNav.map((item) => (
                        <Dropdown.Item>{item.title}</Dropdown.Item>
                      ))}
                    </Dropdown.Item>
                  )
              )}
            </Dropdown.Menu>
          </Dropdown>{" "}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <input
            type="text"
            placeholder="Name"
            onChange={handleNameChange}
            value={name}
            isrequired={name}
          />
        </div>
        <div className="col-xl-6">
          <input
            type="email"
            placeholder="Email"
            onChange={handleEmailChange}
            value={email}
            isrequired={email}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <input
            type="text"
            placeholder="Subject"
            onChange={handleSubjectChange}
            value={subject}
            isrequired={subject}
          />
          <textarea
            placeholder="Write Message Here"
            onChange={handleMessageChange}
            value={message}
            isrequired={message}
          ></textarea>
        </div>
      </div>

      <button
        label="subscribe"
        type="submit"
        className="l-btn "
        formValues={[name, email, subject, message]}
        disabled={
          name === "" || email === "" || subject === "" || message === ""
        }
        onClick={(e) => sendEmail(e)}
      >
        {buttonText ? buttonText : "Send Your Query"} <FaArrowRight />
      </button>

      {error && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{
            __html: "There was some error. Please try again.",
          }}
        />
      )}
      {success && (
        <div
          id="success-alert-home"
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{
            __html: "Thanks for writing to us. We'll get to you shortly.",
          }}
        />
      )}
    </form>
    // <iframe
    //   id="contact-form-iframe"
    //   src="https://us10.list-manage.com/contact-form?u=6653a6435bba8d5e628367c65&form_id=65dc174f31f2b27591187171da79f76f"
    //   height="820"
    //   width="100%"
    //   frameborder="0"
    //   title="Contact Us"
    //   scrolling="no"
    // ></iframe>
  );
};

export default CustomForm;
