import React from "react";
import { Card } from "react-bootstrap";
import MobileIcn from "../../../../assets/images/SVGs/WhyOne1.png";
import ShieldIcn from "../../../../assets/images/SVGs/WhyOne2.png";
import SupportIcn from "../../../../assets/images/SVGs/WhyOne3.png";
import Five from "../../../../assets/images/SVGs/WhyFive.png";

const WhyFeather = () => {
  return (
    <div className="row how-does-it-work pt-80 how-is-it-better why-feather">
      <h1 className="how-does-it-work-header pb-30">Why Onboarding mate</h1>

      <div className="steps">
        <Card>
          <Card.Body>
            <Card.Text>
              <img src={MobileIcn} alt="mobile" style={{width:"80px"}}></img>
              <h4>Seamless Digital Management</h4>
              <p>
                Handle all aspects of your health insurance policy, from
                updating details to claim submissions, through our easy online
                portal.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <img src={ShieldIcn} alt="mobile" style={{width:"80px"}}></img>
              <h4>Round-the-Clock Assistance</h4>
              <p>
                Our dedicated English-speaking team is on standby to provide
                assistance via video call or email should any challenges arise.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <img src={SupportIcn} alt="mobile" style={{width:"80px"}}></img>
              <h4>Bespoke Public Health Coverage</h4>
              <p>
                Tailor your insurance plan to your specific needs with our
                diverse selection of coverage options and add-ons.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <img src={Five} alt="mobile"  style={{width:"80px"}}></img>
              <h4>Unified Insurance Dashboard</h4>
              <p>
                Streamline your insurance management process by handling all
                your policies, including health, liability, and household, from
                a single account.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default WhyFeather;
