import React from "react";

import { LandingPageLink } from "../../../../utilities/constants";
import MobileIcn from "../../../../assets/images/SVGs/WhyOne1.avif";
import SupportIcn from "../../../../assets/images/SVGs/WhyOne3.avif";
import Four from "../../../../assets/images/SVGs/WhyOne4.avif";
import Five from "../../../../assets/images/SVGs/WhyFive.avif";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";






const TakeTheStepHome = () => {
  return (
    <>
      <div className="features-area bg-white pt-110">
        <div className="container">
          <div className="GetCovered pb-30 pt-30">
            <h1>Affordable today, affordable tomorrow.</h1>
          </div>
          <p className="text-center pb-30">
            Because reliability is just as important to us as it is to you, we
            have created all the conditions to ensure that your premiums remain
            affordable into old age.
          </p>

          <div className="how-is-it-better steps">
            <Card>
              <Card.Body>
                <Card.Text>
                  <img src={Five} alt="mobile" style={{ width: "80px" }}></img>
                  <h4>Seamless Digital Management</h4>
                  <p>
                    Handle all aspects of your health insurance policy, from
                    updating details to claim submissions, through our easy online
                    portal.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Text>
                  <img src={Four} alt="mobile" style={{ width: "80px" }}></img>
                  <h4>Round-the-Clock Assistance</h4>
                  <p>
                    Our dedicated English-speaking team is on standby to provide
                    assistance via video call or email should any challenges arise.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Text>
                  <img src={SupportIcn} alt="mobile" style={{ width: "80px" }}></img>
                  <h4>Bespoke Private Health Coverage</h4>
                  <p>
                    Tailor your insurance plan to your specific needs with our
                    diverse selection of coverage options and add-ons.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Text>
                  <img src={MobileIcn} alt="mobile" style={{ width: "80px" }}></img>
                  <h4>English-speaking support team</h4>
                  <p>
                  Our team of friendly members is here to assist you in navigating the German health insurance system and addressing all your inquiries regarding insurance and health.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="pt-10 pb-10 GetCovered text-center">
        <Link
          to={LandingPageLink[0].affiliateLink}
          className="b-a-btn"
          rel="noopener noreferrer"
          style={{
            display: "inline-block",
            padding: "12px 24px",
            backgroundColor: "#014973",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "5px",
            textDecoration: "none",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s ease",
            marginBottom: "20px",
            marginTop: 40
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = "#007CC3")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = "#014973")
          }
        >
          Book an appointment
        </Link>
      </div>
      </div>

      
    </>
  );
};

export default TakeTheStepHome;
