import React from "react";
import { AboutTeamData } from "../../utilities/constants";
const AboutTeamItem = ({ img, imgAlt, heading, position, profileLink }) => {
  return (
    <>
      <a
        href={profileLink}
        style={{ flexBasis: "30%", paddingBottom: "50px" }}
        className="about-team-members"
      >
        <div className="single-team-style-2 text-center">
          <div className="single-team-style-2-img">
            <img src={img} alt={imgAlt} />
          </div>
          <div className="single-team-content-style-2">
            <h4>{heading}</h4>
            <span>{position}</span>
          </div>
        </div>
      </a>
    </>
  );
};

const AboutTeam = () => {
  let teamData = [];
  for (let data of Object.keys(AboutTeamData)) {
    teamData.push(
      <AboutTeamItem
        key={AboutTeamData[data].id}
        img={AboutTeamData[data].img}
        imgAlt={AboutTeamData[data].imgAlt}
        fbLink={AboutTeamData[data].fbLink}
        twitterLink={AboutTeamData[data].twitterLink}
        instagramLink={AboutTeamData[data].instagramLink}
        pinterestLink={AboutTeamData[data].pinterestLink}
        heading={AboutTeamData[data].heading}
        position={AboutTeamData[data].position}
        descriptionHeading={AboutTeamData[data].descriptionHeading}
        descriptionPara={AboutTeamData[data].descriptionPara}
        profileLink={AboutTeamData[data].profileLink}
      />
    );
  }

  return (
    <>
      <div className="team-area white-bg pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-70">
                <h2>Team Members</h2>
              </div>
            </div>
          </div>
          <div className="row">{teamData.map((data) => data)}</div>
        </div>
      </div>
    </>
  );
};

export default AboutTeam;
