import React from "react";
import { v4 as uuidv4 } from "uuid";
import OurVision from "../../assets/images/about/our-vision.jpg";
import OurMission from "../../assets/images/about/our-mission.jpg";
import OurGoals from "../../assets/images/about/our-goals.jpg";

const AboutExperienceData = [
  {
    id: uuidv4(),
    heading: "Mission",
    img: OurMission,
    description:
      "Our mission is to empower individuals from diverse backgrounds and nationalities to seamlessly integrate into German society. We strive to bridge the gap between language barriers and unfamiliar systems, ensuring that every newcomer feels supported, informed, and confident in their new environment. By offering comprehensive assistance and a wide range of services.",
  },

  {
    id: uuidv4(),
    heading: "Vision",
    img: OurVision,
    description:
      "At Company’s Name, our vision is to create a vibrant and inclusive community where every individual, regardless of their language or cultural background, feels connected and supported. We aspire to be the go-to platform for newcomers, providing them with the resources, knowledge, and connections necessary for a successful start in Germany.",
  },

  {
    id: uuidv4(),
    heading: "Goal",
    img: OurGoals,
    description:
      "Our goal is to be your trusted partner throughout your journey in Germany. We understand the challenges you face, from securing the right insurance and finding suitable accommodations to setting up a bank account, transferring money, and finding employment.Our dedicated team is here to simplify these processes, offering expert guidance and tailored solutions that meet your specific needs",
  },
];

const AboutExperienceItem = ({ heading, description, img, index }) => {
  return (
    <>
      <div className="col-xl-1 col-lg-1 pr-50"></div>
      {index % 2 === 0 ? (
        <>
          <div className="col-xl-5 col-lg-5">
            <div className="single-experience">
              <h4>{heading}</h4>
              <p>{description}</p>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5">
            <div className="experience-img">
              <img src={img} alt="" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col-xl-5 col-lg-5">
            <div className="experience-img">
              <img src={img} alt="" />
            </div>
          </div>
          <div className="col-xl-5 col-lg-5">
            <div className="single-experience">
              <h4>{heading}</h4>
              <p>{description}</p>
            </div>
          </div>
        </>
      )}
      {/* </div> */}
      <div className="col-xl-1 col-lg-1 pr-50"></div>
    </>
  );
};

const AboutExperience = () => {
  return (
    <>
      <div className="experience-area pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10">
              <div className="section-title mb-70">
                {/* <span>We Have 20 Years of Experience</span> */}
                <h2>We Provide Onboarding Services</h2>
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
        <div className="container">
          {AboutExperienceData.map((data, index) => (
            <div
              className={`row pb-50 ${index % 2 === 0 ? "about-exp-flex" : ""}`}
            >
              <AboutExperienceItem
                key={data.id}
                index={index}
                heading={data.heading}
                description={data.description}
                img={data.img}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AboutExperience;
