import React from "react";
import { Card } from "react-bootstrap";

const FaithAndReligionDetails = () => {
  return (
    <>
      <h2>Why Choose Us?</h2>
      <p>
        Expats residing in Germany should encounter minimal difficulties in
        meeting their religious and fellowship needs, with the internet serving
        as the optimal resource for locating nearby churches, mosques, or
        synagogues.
        <br />
        <br />
        Given the prevalence of English as the predominant language in the
        Western world, it is only logical to expect English-speaking churches to
        be available across continental Europe, including Germany. Consequently,
        individuals, particularly those in major cities or in proximity to
        British and American military bases, should have no trouble finding a
        suitable place of worship. If one resides near a base, contacting the
        chaplain&#39;s office would likely yield information regarding religious
        activities.
        <br />
        <br />
      </p>
      <br />
      <br />
      <h2>Our Services</h2>

      <div className="health-insurance-partners current-account-partners faith-and-council-services">
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>1</h1>
              <p>
                Access a downloadable PDF listing English-speaking Catholic
                churches in Germany{" "}
                <a
                  href="https://all-saints.info/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
              <p>
                For an extensive online compilation of services in Germany, the
                International Baptist Convention offers a{" "}
                <a
                  href="https://ibc-churches.org/church-locator/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  church locator tool
                </a>
                .
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>2</h1>
              <p>
                To find all Churches of Christ in Germany and German-speaking
                countries, you can visit the{" "}
                <a
                  href="https://www.gemeinde-christi.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  website
                </a>
                .
              </p>
              <p>
                The United Methodist Church in Germany provides information
                through their
                <a
                  href="https://www.unitedmethodist.de/welcome-to-umc.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  website
                </a>
                .
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>3</h1>
              <p>
                For Lutheran and non-denominational Protestant churches,
                consider exploring{" "}
                <a
                  href="https://www.ekd.de/en/EKD-98.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  this
                </a>
                .
              </p>
              <p>
                Anglicans can find church listings in various countries,
                including Germany,{" "}
                <a
                  href="http://europe.anglican.org/where-we-are/church-locations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
              <p>
                The Convocation of Episcopal Churches in Europe offers an
                interactive map displaying church locations{" "}
                <a
                  href="http://www.tec-europe.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>4</h1>
              <p>
                Given the sizable Muslim population in Germany and the abundance
                of mosques, you can use the{" "}
                <a
                  href="https://islam.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  website
                </a>{" "}
                to locate mosques by city. Simply click on
                &quot;Moscheeadressen.&quot; Mosques often represent a specific
                country, and services are typically conducted in the
                corresponding language.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>5</h1>
              <p>
                To find the list of Hindu Temples in Germany, click{" "}
                <a
                  href="https://www.hindutsav.com/hindu-temples-in-germany/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                . Germany is predominantly a Christian country. However, there
                are about 100,000 Hindus in Germany.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>6</h1>
              <p>
                To find a synagogue, a reliable online resource is{" "}
                <a
                  href="https://www.kosherdelight.com/Germany.shtml"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  this
                </a>
                . This website serves as a weekly Jewish online magazine and
                provides comprehensive directories.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>7</h1>
              <p>
                In Hofheim-Langenhain, near Frankfurt/Main, there is a Bahá’í
                House of Worship. For additional details regarding services and
                operating hours, please visit{" "}
                <a
                  href="https://www.bahai.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>8</h1>
              <p>
                The Religious Society of Friends (Quakers) in Germany offers
                information in English through their website{" "}
                <a
                  href="https://www.quaeker.org/information-in-english/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>9</h1>
              <p>
                For a list of Unitarian Universalist fellowships across Europe,
                including Germany, you can visit{" "}
                <a
                  href="http://www.europeanuu.org/about-us/euu-fellowships/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}here
                </a>
                .
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default FaithAndReligionDetails;
