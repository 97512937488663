import React from "react";
import Tab from "react-bootstrap/Tab";
import { councilAndFaithSubMenu } from "../../../utilities/constants";
import Card from "react-bootstrap/Card";

const CouncilAndFaithServices = () => {
  return (
    <>
      <div className="row">
        <Tab.Container defaultActiveKey="councilAndFaith">
          <div className="col-xl-12">
            <h2>Council and Faith Services</h2>
            <div className="works-menu transfer-services-menu text-center mb-50">
              {councilAndFaithSubMenu.map((item) => (
                <a href={item.path}>
                  <Card>
                    <Card.Img
                      style={{ width: "auto", height: "105px" }}
                      alt="171x180"
                      src={item.img}
                      variant="top"
                    />
                    <Card.Body>{item.title}</Card.Body>
                  </Card>
                </a>
              ))}
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default CouncilAndFaithServices;
