import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import insuranceBackground from "../../assets/images/insuranceDetails/home-insurance.jpg";
import ServiceDetailsContent from "../../components/service-details/insurance/ServiceDetailsContent";
import InsuranceServices from "../../components/service-details/insurance/InsuranceServices";
import ServiceFaq from "../../components/service-details/ServiceFaq";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";

const InsuranceDetails = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={insuranceBackground}
        heading1={`Insure Your`}
        heading2={`Peace of Mind`}
        // heading3={`choose the best insurance`}
        description="Comprehensive Insurance Services Tailored for Immigrants"
        currentPage="Insurance"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="service-details-wrapper">
                <InsuranceServices />
                <ServiceDetailsContent />

                <ServiceFaq />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default InsuranceDetails;
