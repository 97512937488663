import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import insuranceBackground from "../../assets/images/insuranceDetails/publicHealthInsurance/public-health-insurance.jpg";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import PublicHealthInsuranceDetails from "../../components/service-details/insurance/publicHealthInsurance/PublicHealthInsuranceDetails";
import HowDoesItWork from "../../components/service-details/insurance/publicHealthInsurance/HowDoesItWork";
import {
  PublicHealthInsurancePartners,
  insuranceSubMenu,
} from "../../utilities/constants";
// import PublicHealthInsuranceDetailsBottom from "../../components/service-details/insurance/publicHealthInsurance/PublicHealthInsuranceDetailsBottom";
import FAQSection from "../../components/faqSection/FAQSection";
import OtherServices from "../../components/service-details/OtherServices";

const PublicHealthInsurance = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={insuranceBackground}
        heading1={`Healthcare Solutions Tailored `}
        heading2={`To You`}
        // heading3={`By choosing us`}
        description="Your health comes first."
        currentPage="publicHealthInsurance"
        className="health-insurance-hero"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="health-insurance-details-wrapper">
                <PublicHealthInsuranceDetails />

                <HowDoesItWork currentPage="publicHealthInsurance" />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <div className="cta-area theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cta-style-1-wrapper text-center">
                {/* <span>The Company</span> */}
                {/* <h2>We invested our hard work for people just like you that <br /> insist on a great user experience.</h2> */}
                <p className="health-insurance-cta">
                  Take charge of your well-being and secure your future with our
                  exceptional, all-encompassing health insurance.
                </p>
                <a
                  href={PublicHealthInsurancePartners[0].affiliateLink}
                  rel="noopener noreferrer"
                  className="l-btn"
                >
                  Claim Public Health Insurance
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="health-insurance-details-wrapper">
                {/* <PublicHealthInsuranceDetailsBottom /> */}
                <FAQSection />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>

      <OtherServices
        currentPage="publicHealthInsurance"
        subMenu={insuranceSubMenu}
        heading={"Other Insurance Services"}
        className={"insurance-services"}
      />

      <FooterOne />
    </>
  );
};

export default PublicHealthInsurance;
