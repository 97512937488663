import React from 'react'
import { PrivateHealthInsuranceAdvisors } from '../../utilities/constants'
import HubspotContactForm from '../home1/HubspotContactForm'
const PersonalizedAdvice = () => {
    // const styleObj = {
    //     margin: "auto",
    //     display: "flex",
    //     justifyContent: "center",
    //     marginTop: "20px",
    //   };
  return (
    <>
    <div className=' PAdvice pt-110 pb-110'>
    <h1 className='text-center'>Personalized advice that really is helpful.</h1>
    <br/>
    <div>
    <p className='text-center pl-10 pr-10'>We do everything we can to help you make an informed and conscious decision that you will benefit from for a lifetime. </p>
    </div>
    <div className="service-details">
      <div className="container">
        <div className="row">
          <div className="col-xl-1 col-lg-1 pr-50"></div>
          <div className="col-xl-10 col-lg-10">
            <div className="health-insurance-details-wrapper">
              <div className="health-insurance-partners current-account-partners advisor-partners justify-content-center">
                {PrivateHealthInsuranceAdvisors.map((partner, index) => (
                  <div
                    className="advisor-flex"
              
                    key={index}
                  >
                    <a
                      href={partner.affiliateLink}
                      className="about-team-members advisors"
                      style={{paddingTop: "50px" }}
                    >
                      <div className="single-team-style-2 text-center advisors-team">
                        <div className="single-team-style-2-img advisors-img">
                          <img
                            src={partner.img}
                            alt={partner.imgAlt}
                            style={{
                              objectPosition: index === 1 ? "top" : "",
                              
                              height: "",
                            }}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
              <div className='pr-50 pl-50'>
              <HubspotContactForm 
                   region="eu1"
                   portalId="144261095"
                   formId="cb9c6d11-8c47-47de-9ff1-aef70a14ca86" />
              </div>

              {/* <div className='single-team-content-style-2' style={styleObj}>
                <Link
                  rel="noopener noreferrer"
                  target='_blank'
                  to={PrivateHealthInsuranceAdvisors[0].affiliateLink}
                  className="l-btn  big-btn"
                >
                  Talk to Our advisors
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    </>

  )
}

export default PersonalizedAdvice
