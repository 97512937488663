import React from 'react';
import { Link } from 'react-router-dom';
import { PrivateHealthInsuranceAdvisors } from '../../../../utilities/constants';
import One from '../../../../assets/images/SVGs/WhyOne1.png';
import Three from '../../../../assets/images/SVGs/WhyOne3.png';
import Four from '../../../../assets/images/SVGs/WhyOne4.png';
import Five from "../../../../assets/images/SVGs/WhyFive.png";

const TakeTheStep = () => {
  return (
    <>
    <div className='pb-100'>
    <div className="features-area bg-white pt-110 pb-110">
        <div className="container">
          <div className="GetCovered pb-30 pt-30">
            <h1>
              Take the First Step to Health and Savings
            </h1>
          </div>
          <div>
            <p className="text-center pb-30">
              Your journey to better healthcare begins here. Get started with a
              free quote today, and let our experts guide you to affordable,
              high-quality health insurance. Your peace of mind and savings are
              just a click away.
            </p>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 why-choose-us-item">
              <div className="single-feature text-center box-shadow-3">
                <div className="single-feature-icon">
                <img src={One} alt="" className="svg-icon" />
                </div>
                <h5> Initial Consultation Request</h5>
                <p>
                  Embark on your journey by requesting an initial consultation
                  at no cost through our website. Answer a few simple questions
                  to help us grasp your needs and aspirations.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 why-choose-us-item">
              <div className="single-feature text-center box-shadow-3">
                <div className="single-feature-icon">
                <img src={Four} alt="Private Health Insurance In Germany For Foreigners" className="svg-icon" />
                </div>
                <h5> Preliminary Chat</h5>
                <p>
                  Expect a call from one of our welcoming team members to
                  discuss your goals and connect you with the ideal expert for
                  your specific circumstances.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 why-choose-us-item">
              <div className="single-feature text-center box-shadow-3">
                <div className="single-feature-icon">
                  <img src={Three} alt="" className="svg-icon" />
                </div>
                <h5>Virtual Advisory Session</h5>
                <p>
                  Delve into a 45-60 minute session of complimentary financial
                  guidance. We'll address the topics most important to you,
                  ensuring you're actively involved and well-informed every step
                  of the way.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 why-choose-us-item">
              <div className="single-feature text-center box-shadow-3">
                <div className="single-feature-icon">
                  <img src={Five} alt="icon"  />
                </div>
                <h5>Long-Term Partnership</h5>
                <p>
                  We're excited about the opportunity to persuade you with our
                  approach and begin a lasting journey together.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-10 pb-10 GetCovered">
        <Link
          rel="noopener noreferrer"
          to={PrivateHealthInsuranceAdvisors[0].affiliateLink}
          className="l-btn big-btn"
        >
          Talk to our Advisors
        </Link>
      </div>


    </div>

    </>
  );
};

export default TakeTheStep;
