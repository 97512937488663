import React from 'react';
import { Link } from 'react-router-dom';
import { FaLongArrowAltRight } from 'react-icons/fa';
import "./BlogStyleOne.css";

import { BlogStyleOneData } from '../../utilities/constants';

const BlogStyleOneItem = ({
    img,
    imgAlt,
    parentCat,
    subCat,
    heading,
    postPublishDate,
    postAuthor,
    postLink,
  }) => {
    const imageContainerStyle = {
      height: '200px', // Set a fixed height
      overflow: 'hidden',
      position: 'relative', 
    };
  
    const imageStyle = {
      width: '100%', // fit to container
      objectFit: 'cover',
    };
  
    const contentStyle = {
      height: '100%', 
      display: 'flex',
      flexDirection: 'column',
      padding: '15px',
    };
  
    return (
      <div className="col-xl-4 col-lg-4 col-md-4 pt-5">
        <div
          className="single-blog-wrapper mb-4"
          style={{
            border: '1px solid #ddd',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            height: '100%',
          }}
        >
          <Link to={postLink}>
            <div className="blog-img bg-hover-style-1" style={imageContainerStyle}>
              <img src={img} alt={imgAlt} style={imageStyle} />
            </div>
          </Link>
  
          <div className="blog-content" style={contentStyle}>
            <ul className="cat-list mt-2 mb-2">
              <li className="parent-cat">{parentCat}</li>
              <li>{subCat}</li>
            </ul>
            <h4>{heading}</h4>
            <ul className="post-meta mb-2">
              <li>{postPublishDate}</li>
              <li>
                <span>By</span> {postAuthor}
              </li>
            </ul>
            <Link to={postLink} className="l-read-more">
              Read More <FaLongArrowAltRight />
            </Link>
          </div>
        </div>
      </div>
    );
  };
  
const BlogStyleOne = () => {
    return (
        <>
            <div className="blog-area white-bg pt-110 pb-110">
                <div className="container">
                    <div className="row pt-2">
                        <div className="section-title text-center mb-50">
                            <span>Latest News Blog</span>
                            <h2>Articles daily updated latest articles <br /> directly from the blog</h2>
                        </div>
                    </div>
                    <div className="row">
                        {BlogStyleOneData.map((data) => (
                            <BlogStyleOneItem
                                key={data.id}
                                img={data.img}
                                imgAlt={data.imgAlt}
                                parentCat={data.parentCat}
                                subCat={data.subCat}
                                heading={data.heading}
                                postPublishDate={data.postPublishDate}
                                postAuthor={data.postAuthor}
                                postLink={'/BlogContent/' + data.id}
                                
                            />
                      
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogStyleOne;
