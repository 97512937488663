import React from "react";

const ServiceDetailsContent = () => {
  return (
    <>
      <h2>Protect What Matters Most Today!</h2>
      <p>
        At Onboarding Mate, we understand that immigrating to a new country can
        be an exciting yet challenging experience. As an immigrant in Germany,
        it's crucial to ensure that you have the right insurance coverage to
        protect yourself, your loved ones, and your assets. That's where we come
        in.
        <br />
        <br />
        With our comprehensive insurance services tailored specifically for
        immigrants, we strive to make your transition smoother and provide you
        with peace of mind.
      </p>
      <h2>98% Claim Settlement Rate!!!</h2>
      <p>
        Our statistics reinforce our position as a leading insurance service
        provider in Germany:
        <br />
        <br />
        <ol>
          <li>
            Over 98% claims settlement rate, ensuring a hassle-free experience
            when you need it the most.
          </li>
          <li>
            A network of more than 50 reputable insurance providers, offers you
            a wide range of options to choose from for customised coverage.
          </li>
          <li>
            An average customer savings rate of 15% compared to direct insurance
            purchases, demonstrating our ability to secure the best deals on
            your behalf.
          </li>
          <li>
            24/7 customer support with an average response time of less than 30
            minutes, providing prompt assistance and guidance whenever you need
            it.
          </li>
        </ol>
      </p>
      <h2>Why Choose Us?</h2>
      <p>
        <h4>20 Years of Expertise:</h4>
        We have extensive experience in providing insurance services to
        immigrants in Germany, understanding the complexities and specific needs
        of different groups. Tailored Solutions: Our insurance services are
        designed to meet the unique requirements of students, workers, and
        moving business professionals, offering personalised coverage options.
        <br />
        <br />
        <h4>Tailored Solutions:</h4>
        Our insurance services are designed to meet the unique requirements of
        students, workers, and moving business professionals, offering
        personalised coverage options.
        <br />
        <br />
        <h4>Reputable Partners:</h4>
        We collaborate with trusted insurance providers in Germany, ensuring
        access to reliable and comprehensive coverage.
        <br />
        <br />
        <h4>Simplified Process:</h4>
        Our team will guide you through the insurance process, explaining the
        details in a clear and understandable manner, so you can make informed
        decisions.
        <br />
        <br />
        <h4>Customer Satisfaction:</h4>
        We prioritise your satisfaction, providing excellent service and
        reliable insurance solutions that cater to your needs.
      </p>
    </>
  );
};

export default ServiceDetailsContent;
