import React from "react";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";

const TestimonialData = [
  {
    id: uuidv4(),
    comment:
      "Switching to Onboarding Mate for my private health insurance was one of the best decisions I made for my family. The process was incredibly smooth, and their customer service team was extremely helpful every step of the way. With their comprehensive coverage options and competitive rates, I now have peace of mind knowing that my loved ones are well taken care of. Thank you, Onboarding Mate, for making health insurance hassle-free!",
    heading: "Quick And Uncomplicated Service",
    name: "Rohit Bansal",
    position: "IT professional",
    date: "May 20",
  },
  {
    id: uuidv4(),
    comment:
      "As a small business owner, providing quality health insurance for my employees has always been a priority. Onboarding Mate made the process seamless and straightforward. Their platform is user-friendly, and their team guided us through every detail, ensuring we chose the best plan for our team's needs. I highly recommend Onboarding Mate to any business looking for reliable health insurance solutions.",
    heading: "Seamless Business Solutions",
    name: "Sakshi Jain",
    position: "Business owner",
    date: "May 18",
  },
  {
    id: uuidv4(),
    comment:
      "I've been a customer of Onboarding Mate for several years now, and I couldn't be happier with their service. From the ease of signing up to the prompt responses to any inquiries, their dedication to customer satisfaction is evident. Plus, their range of coverage options allowed me to tailor a plan that suits my individual needs perfectly. Onboarding Mate has truly exceeded my expectations, and I wouldn't hesitate to recommend them to anyone in search of top-notch health insurance.",
    heading: "Exceeding Expectations",
    name: "Shikhar Prajapati",
    position: "University Head",
    date: "May 16",
  },
  {
    id: uuidv4(),
    comment:
      "Onboarding Mate's customer service is outstanding. They guided me through every step of selecting and enrolling in a health insurance plan. Their team is knowledgeable and always ready to help. I feel confident knowing I made the right choice for my health insurance needs.",
    heading: "Outstanding Customer Service",
    name: "Anjali Mehta",
    position: "Freelancer",
    date: "May 14",
  },
  {
    id: uuidv4(),
    comment:
      "Choosing Onboarding Mate for our company's health insurance was a game-changer. Their platform is intuitive, and their team is incredibly responsive. We've had an excellent experience so far, and our employees are happy with the coverage options available.",
    heading: "Game-Changer for Business",
    name: "Amit Sharma",
    position: "HR Manager",
    date: "May 12",
  },
  {
    id: uuidv4(),
    comment:
      "Onboarding Mate made it easy for me to find the perfect health insurance plan. The website is easy to navigate, and the support team is always available to answer any questions. I highly recommend their services to anyone looking for hassle-free health insurance.",
    heading: "Hassle-Free Experience",
    name: "Priya Singh",
    position: "Graphic Designer",
    date: "May 10",
  },
  {
    id: uuidv4(),
    comment:
      "The process of switching to Onboarding Mate was smooth and efficient. Their team took care of all the details, and I appreciated the personalized service. The coverage options are excellent, and I feel secure knowing I have the right plan for my needs.",
    heading: "Smooth and Efficient",
    name: "Rahul Verma",
    position: "Marketing Executive",
    date: "May 08",
  },
];

const TestimonialStyleFour = () => {
  const TestimonialStyleOne1 = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: false,
          speed: 2000,
          rows: 1,
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: false,
          speed: 2000,
          rows: 1,
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: false,
          speed: 2000,
          rows: 1,
          pauseOnHover: true,
        },
      },
    ],
  };

  const cardStyle = {
    height: "300px", 
    overflow: "hidden",
  };

  const truncateText = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <>
      <div className="testimonial-area theme-bg-4 pt-100 pb-110">
        <div className="container">
          <div className="row position-relative">
            <div className="col-xl-12">
              <div className="section-title text-center mb-70">
              <h2 className="testimonial-subheader">Our Happy Expats!</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <Slider
              {...TestimonialStyleOne1}
              className="testimonial-style-1"
              infinite={true}
            >
              {TestimonialData.map((data) => (
                <Link to={data.link} style={{ width: "18rem" }} key={data.id}>
                  <Card className="sell-band-card" style={cardStyle}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div style={{ color: "#4CAF50", fontSize: "24px" }} className="d-flex mt-30 ml-30">
                        {[...Array(5)].map((_, i) => (
                          <FaStar key={i} />
                        ))}
                      </div>
                    </div>
                    <Card.Body>
                      <h5 className="text-start pl-10" >{data.heading}</h5>
                      <Card.Text className="text-start pl-10" style={truncateText}>{data.comment}</Card.Text>
                      <p className = "text-start pl-10" style={{ fontWeight: "bold", fontSize:"16px"}}>{data.name}</p>
                    </Card.Body>
                  </Card>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialStyleFour;
