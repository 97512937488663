import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import faithAndReligionImg from "../../assets/images/councilAndFaith/faithAndReligion/faith-and-religion.jpg";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import {  councilAndFaithSubMenu } from "../../utilities/constants";
import OtherServices from "../../components/service-details/OtherServices";
import FaithAndReligionDetails from "../../components/service-details/councilAndFaith/faithAndReligion/FaithAndReligionDetails";

const FaithAndReligion = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={faithAndReligionImg}
        heading1={`People of all religions are welcomed here.`}
        // heading2={`Creating a bank account`}
        // heading3={`Was this easy!`}
        description="Welcome to our world where you follow your beliefs and religion irrespective of the country you live in."
        currentPage="faithAndReligion"
        className="health-insurance-hero"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="health-insurance-details-wrapper current-account-details">
                <FaithAndReligionDetails />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
 
      {/* <div className="how-it-works pt-110 pb-110 faq-section-row">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-50">
                <h2>FAQs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <ProjectFaq name={TYPES_OF_FAQS.faithAndCommunity} />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div> */}
      <OtherServices
        currentPage="faithAndReligion"
        subMenu={councilAndFaithSubMenu}
        heading={"Other Council and Faith Services"}
        className="money-transfer faith-council"
      />
      <FooterOne />
    </>
  );
};

export default FaithAndReligion;
