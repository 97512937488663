import React from "react";
import {  Link } from "react-router-dom";
import LogoImg from "../../assets/images/logo.avif";

const styles = {
  width: "60%",
  height: "auto",
  paddingBottom : "2px"
};
const Logo = () => {
  return (
    <div className="logo header-logo">
      <Link to="/" exact={true}>
        <img src={LogoImg} alt="Logo" style={styles} />
      </Link>
    </div>
  );
};

export default Logo;
