import React from "react";
import { PrivateHealthInsuranceAdvisors } from "../../../../utilities/constants";
import { Link } from "react-router-dom";

const PHIAdvantages = () => {
  const styles = {

    header: {
      textAlign: "center",
      marginBottom: "35px",
    },
    section: {
      marginBottom: "40px",
    },
    card: {
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
    },
    number: {
      fontSize: "2em",
      fontWeight: "bold",
      color: "#007bff",
    },
    title: {
      fontSize: "1.5em",
      fontWeight: "bold",
      margin: "10px 0",
    },
    subtitle: {
      fontSize: "1.2em",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    text: {
      fontSize: "1em",
      lineHeight: "1.6",
    },
    highlight: {
      color: "#007bff",
      fontWeight: "bold",
    },
  };
  return (
    <>
      <div className="container pt-110 ">
        <h1 className="Hwrapper text-center pt-40 pb-60">
        What are the benefits of a private health insurance?
        </h1>
        <div className="px-lg-5 px-md-5 px-0">
          <p className="text-center">
            With private health insurance, you secure comprehensive and
            personalized coverage. The benefits you agree upon with your insurer
            are guaranteed by contract, ensuring that your coverage remains
            intact. You pay for the services you need and enjoy greater
            flexibility compared to public health insurance. Privately insured
            individuals can tailor their premiums and coverage more effectively
            than those in the public system.
          </p>
        </div>

        <div className="features-area bg-white pt-30">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 mb-4">
              <div style={styles.card}>
                <div style={styles.number}>1.</div>
                <div style={styles.title}>More Extensive Benefits in the Event of Illness</div>
                <p style={styles.text}>
                  Private health insurance offers a broader range of benefits
                  compared to public insurance. This includes access to
                  specialists, coverage for alternative treatments, vision care,
                  treatment by chief physicians, and private hospital
                  rooms.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div style={styles.card}>
                <div style={styles.number}>2.</div>
                <div style={styles.title}>Flexibility in Choosing Tariffs and Premiums</div>
                <p style={styles.text}>
                  Private health insurance allows you to select coverage that
                  meets your specific needs. You can choose from a variety of
                  tariffs and customize your insurance plan to include the
                  benefits that matter most to you and take best care of your health.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div style={styles.card}>
                <div style={styles.number}>3.</div>
                <div style={styles.title}>Lifetime Insurance Benefits With Secure Plans</div>
                <p style={styles.text}>
                  Your private health benefits are contractually
                  guaranteed for life and cannot be reduced. While, public
                  health insurance has seen a reduction in benefits and rising
                  premiums due to an aging and increased demand for
                  healthcare.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-50 pb-100 GetCovered">
        <Link
          rel="noopener noreferrer"
          to={PrivateHealthInsuranceAdvisors[0].affiliateLink}
          className="l-btn big-btn"
        >
          Talk to our Advisors
        </Link>
      </div>
    </>
  );
};

export default PHIAdvantages;
