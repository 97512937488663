import React from "react";
import AboutBlockImg1 from "../../assets/images/about/about-large.jpeg";
import { FaCheckCircle } from "react-icons/fa";
import AboutTwoOne from "../../assets/images/about/AboutTwo1.avif";
import AboutTwo from "../../assets/images/about/AboutTwo2.avif";
import AboutThree from "../../assets/images/about/AboutThree.webp";
import Logo4 from "../../assets/images/Logo4.avif";
import Logo5 from "../../assets/images/Logo5.avif";
import Logo6 from "../../assets/images/Logo6.avif";
import { LandingPageLink } from "../../utilities/constants";
import { Link } from "react-router-dom";

const AboutBlocktwo = () => {
  const imageRowStyle = {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "40px",
  };
  const imageRowStyletwo = {
    ...imageRowStyle,
    marginTop: "10px",
  };

  const imageContainerStyle = {
    flex: 1,
    margin: "0 10px",
    maxWidth: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #070707",
    borderRadius: "4px",
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
    maxHeight: "100px",
    objectFit: "contain",
  };

  const icnStyle = {
    color: "#004B76",
    marginRight: "10px",
    fontSize: "1.2em",
  };

  return (
    <>
      <div className="about-area gray-bg-2 pb-110">
        <h1 className="text-center">Get insurance from leading companies</h1>
        <h1 className="text-center">rated by a top German agency.</h1>
        <div className="container mt-110">
          <div className="row d-flex align-items-start">
            <div className="col-xl-6 col-lg-6 col-md-6 pl-50 pr-50">
              <div className="about-img-style-2">
                <img
                  src={AboutBlockImg1}
                  alt="About Large"
                  className="img-large"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="about-text-wrapper">
                <div className="container">
                  <div className="svg-container">

                  </div>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    <li
                      className="point mb-2"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: "20px",
                      }}
                    >
                      <FaCheckCircle style={icnStyle} />
                      <div className="pb-10">
                        <strong>Top tariff quality</strong>
                      </div>
                    </li>
                    <li
                      className="point mb-2"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: "20px",
                      }}
                    >
                      <FaCheckCircle style={icnStyle} />
                      <div className="pb-10">
                        <strong>Extensive benefits</strong>
                      </div>
                    </li>
                    <li
                      className="point mb-2"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: "20px",
                      }}
                    >
                      <FaCheckCircle style={icnStyle} />
                      <div className="pb-10">
                        <strong>Very good rate stability</strong>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="row mt-50" style={imageRowStyle}>
                  <div style={imageContainerStyle}>
                    <img
                      className="aboutBlock-img"
                      src={AboutTwoOne}
                      alt="Logo 1"
                      style={imageStyle}
                    />
                  </div>
                  <div style={imageContainerStyle}>
                    <img
                      className="aboutBlock-img"
                      src={AboutTwo}
                      alt="Logo 1"
                      style={imageStyle}
                    />
                  </div>
                  <div style={imageContainerStyle}>
                    <img
                      className="aboutBlock-img"
                      src={AboutThree}
                      alt="Logo 1"
                      style={imageStyle}
                    />
                  </div>
                </div>
                <div className="row mt-10" style={imageRowStyletwo}>
                  <div style={imageContainerStyle}>
                    <img
                      className="aboutBlock-img"
                      src={Logo4}
                      alt="Logo 1"
                      style={imageStyle}
                    />
                  </div>
                  <div style={imageContainerStyle}>
                    <img
                      className="aboutBlock-img"
                      src={Logo5}
                      alt="Logo 1"
                      style={imageStyle}
                    />
                  </div>
                  <div style={imageContainerStyle}>
                    <img
                      className="aboutBlock-img"
                      src={Logo6}
                      alt="Logo 1"
                      style={imageStyle}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex justify-content-center">
                    <Link
                      to={LandingPageLink[0].affiliateLink}
                      className="b-a-btn"
                      rel="noopener noreferrer"
                      style={{
                        display: "inline-block",
                        padding: "12px 24px",
                        backgroundColor: "#014973",
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        textDecoration: "none",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        transition: "background-color 0.3s ease",
                        marginTop: 50
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#007CC3")

                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "#014973")
                      }
                    >
                      Book an appointment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutBlocktwo;
