import React from "react";
import { Card } from "react-bootstrap";
import { LegalInsurancePartners } from "../../../../utilities/constants";

const LegalInsuranceDetails = () => {
  return (
    <>
      <h2>Why should I opt for legal insurance?</h2>
      <p>
        Unexpected disagreements can crop up at any time, and dealing with them
        can often be an expensive affair. Legal insurance shields you from
        financial strain in most aspects of your personal and professional life.
        <br />
        <br />
        <b>Peace of mind in dealing with legal situations: </b> Gain the
        assurance and the confidence to seek legal recourse, secure in the
        knowledge that you're protected in case of a lawsuit.
        <br />
        <br />
        <b>Access to Legal Advice:</b> With legal insurance, you gain the
        privilege of free consultations with legal experts. 
        <br />
      </p>
      <br />
      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <a
            href={LegalInsurancePartners[0].affiliateLink}
            rel="noopener noreferrer"
            className="l-btn"
          >
            Consult Advisors
          </a>
        </div>
      </div>
      <br />
      <br />
      <h2>Our Partners</h2>

      <div className="health-insurance-partners current-account-partners">
        {LegalInsurancePartners.map((partner) => (
          <a
            href={LegalInsurancePartners[0].affiliateLink}
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div>
    </>
  );
};

export default LegalInsuranceDetails;
