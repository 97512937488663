import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import FooterOne from "../common/footer/FooterOne";
import AboutUsWhoAreWe from "../components/about/AboutUsWhoAreWe";
import AboutExperience from "../components/about/AboutExperience";
import CtaTwo from "../components/cta/CtaTwo";
import AboutTeam from "../components/about/AboutTeam";
import Subscribe from "../components/subscribe/Subscribe";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import HeroService from "../components/hero/HeroServices";
import AboutUsImg from "../assets/images/about/about-us.jpg";
const About = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        heading1="About Onboarding Mate"
        breadcrumbBg={AboutUsImg}
        description="We are available for you, 24/7"
        currentPage="About Us"
      />
      <AboutUsWhoAreWe />
      <AboutExperience />
      {/* <AboutBlock /> */}
      <CtaTwo />
      <AboutTeam />
      {/* <TestimonialStyleTwo /> */}
      <Subscribe />
      <FooterOne />
    </>
  );
};

export default About;
