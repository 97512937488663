import React from "react";

const ImprintComponent = () => {
  return (
    <>
      <div className="about-block pt-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 pr-50">
              <p className="imprint-info ">
                <a href="/">Onboarding Mate</a> is a product of Zillion Pathways
                GmbH
                {/* |<a href="/">Onboarding Mate</a> ist ein Angebot der
                Zillion Pathways GmbH */}
                <br />
                <br />
                This website consists of partial offers, which are the
                responsibility of different providers.
                {/* | Diese Website besteht
                aus Teilangeboten, die von unterschiedlichen Anbietern
                verantwortet werden. */}
                <br />
                <br />
                <ol>
                  <li>
                    Responsible according to §5 TMG for all contents, if no
                    other responsible is indicated in the following:
                    {/* |
                    Verantwortlicher gemäß §5 TMG für alle Inhalte, sofern im
                    Folgenden kein abweichender Verantwortlicher angegeben ist: */}
                    <ul>
                      <li>Zillion Pathways GmbH</li>
                      <li>Bahnhofstr.</li>
                      <li>13 27419 Sittensen</li>
                      <li> Phone +49 (0) 4282 8016 320</li>
                      <li>
                        {" "}
                        <a
                          href="http://zillionpathways.de/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          contact@zillionpathways.de
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a
                          href="http://zillionpathways.de/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          http://zillionpathways.de
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        Registration court: Amtsgericht Tostedt Register number:
                        HRB 209654
                      </li>
                      <li> Managing Director: Marc Bernardo Carlson</li>

                      <li>
                        {" "}
                        Sales tax identification number according to § 27 a
                        sales tax law: DE 356047406
                      </li>
                      <li>
                        {" "}
                        Platform of the EU Commission for online dispute
                        resolution
                      </li>
                    </ul>
                  </li>
                  <li>
                    Disclaimer: The information on this website does not provide
                    a complete market overview and is based on our own
                    experience. We are not certified brokers or consultants.
                  </li>
                  <li>
                    Despite careful checking of the content, we accept no liability for the content of external links. The operators of the linked pages are solely responsible for their content.
                  {/* Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.*/}
                  </li>
                  <li>
                  Our partner is responsible for information and content on insurance: JF-JointFinance GmbH (registration number D-FZP2-AMSEN-69), Bussardstraße 26, 25462 Rellingen. Permission according to § 34d Abs. 1 GewO (insurance broker), supervisory authority: IHK Kiel (Bergstr. 2, 24103 Kiel)
                  </li>
                  <li>
                    Picture credits | Bildnachweise à Please specify the used
                    sources
                    <ul>
                      <li>
                        <a
                          href="https://www.pexels.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.pexels.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://pixabay.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.pixabay.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.splitshire.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.splitshire.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://stock.adobe.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          stock.adobe.com:
                        </a>{" "}
                        ©JohnKwan #20560702, ©Rostislav Sedlacek #98157861,
                        ©Karen Roach #142241874 © manfredxy 143850687 |{" "}
                        <a
                          href="https://www.123rf.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          123RF.com
                        </a>
                      </li>
                    </ul>
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImprintComponent;
