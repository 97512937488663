import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import breadcrumbBg from "../assets/images/feedback/feedback.jpg";
// import ContactForm from "../components/contact/ContactForm";
// import TestimonialStyleThree from "../components/testimonial/TestimonialStyleThree";
// import ContactMap from '../components/contact/ContactMap';
// import Subscribe from "../components/subscribe/Subscribe";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import FooterOne from "../common/footer/FooterOne";
import HeroService from "../components/hero/HeroServices";
import FeedbackForm from "../components/contact/FeedbackForm";

const Feedback = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={breadcrumbBg}
        heading1={`Your feedback is of utmost importance`}
        // heading2={`And preventive treatments?`}
        description="Help us get better."
        currentPage="feedback"
        className="health-insurance-hero"
      />
      {/* <ContactForm /> */}
      {/* <TestimonialStyleThree /> */}
      <FeedbackForm />
      {/* <ContactMap /> */}
      {/* <Subscribe /> */}
      <FooterOne />
    </>
  );
};

export default Feedback;
