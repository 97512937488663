import React from "react";
import { Card } from "react-bootstrap";

const HowDoesItWork = () => {
  return (
    <div className="row how-does-it-work pt-80">
      <h1 className="how-does-it-work-header">How does it work</h1>

      <div className="steps">
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>1</h1>
              <h4>Request a Quote</h4>
              <p>
                1.Just Dial +49 (0) 4282 8016 320 to get a quote absolutely free OR
                Talk to our expert.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          {/* <Card.Img variant="top" src={partner.img} /> */}
          <Card.Body>
            <Card.Text>
              <h1>2</h1>
              <h4>We’ll do it for you</h4>
              <p>
                Our 20 years of expertise in the insurance market will help you
                to compare dozens of companies and find you the best rates &
                coverage.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          {/* <Card.Img variant="top" src={partner.img} /> */}
          <Card.Body>
            <Card.Text>
              <h1>3</h1>
              <h4>Get the Coverage</h4>
              <p>
                3.Feel relaxed! You’ve got the best coverage price in the
                market.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>

        {/* </a>
        ))} */}
      </div>
    </div>
  );
};

export default HowDoesItWork;
