import React from "react";
import CustomForm from "../../common/footer/CustomForm";
const Subscribe = () => {
  return (
    <>
      <div
        className="newsletter-area pt-110 pb-110 bg-property"
        data-overlay="black"
        data-opacity="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="newsletter-content-wrapper text-center">
                <h2>
                  Get News Updates Special Event Notices And <br /> More When
                  You Join Our Email List
                </h2>
                {/* <form action="#">
                                    <input type="text" placeholder="Enter Your Email" />
                                    <button className="l-btn pl-60 pr-60 fw-light">Subscribe</button>
                                </form> */}
                <CustomForm buttonClass={"l-btn pl-60 pr-60 fw-light"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
