import React, { useEffect, useState } from 'react';
import HeaderTop from '../common/header/HeaderTop';
import FooterOne from '../common/footer/FooterOne';
import DetailedTeam from '../components/team/DetailledTeam';
import { useLocation } from "react-router-dom";
import { AboutTeamData } from '../utilities/constants';
import HeaderBottomOne from '../common/header/HeaderBottomOne';

const Team = (props) => {
    const { pathname } = useLocation();
    const [member, setMember] = useState({});
    useEffect(() => {
        const teamMember = pathname.split("/")[2];
        const teamMemberSelected = AboutTeamData[teamMember];
        setMember(teamMemberSelected)
      }, [pathname]);
    
    return (
        <>
            <HeaderTop />
            <HeaderBottomOne />
            <DetailedTeam member={member}/>
            <FooterOne />
        </>
    )
}

export default Team;