import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import { FaLevelUpAlt } from 'react-icons/fa';

//Pages
import PrivateHealthPage from './pages/PrivateHealthPage';
import HomeDefault from './pages/HomeDefault';
import About from './pages/About';
import Contact from './pages/Contact';
import Error from './pages/Error';
import ScrollToTopRoute from './components/scroll-to-top-route/ScrollToTopRoute';
import InsuranceDetails from './pages/insurance/InsuranceDetails';
import PublicHealthInsurance from './pages/insurance/PublicHealthInsurance';
import BankAccountDetails from './pages/bankAccounts/BankAccountDetails';
import MoneyTransferDetails from './pages/moneyTransfer/MoneyTransferDetails';
import TransferWise from './pages/moneyTransfer/TransferWise';
import CurrentAccount from './pages/moneyTransfer/CurrentAccount';
import RentalDeposit from './pages/moneyTransfer/RentalDeposit';
import LiabilityInsurance from './pages/insurance/LiabilityInsurance';
import HouseholdInsurance from './pages/insurance/HouseholdInsurance';
import LegalInsurance from './pages/insurance/LegalInsurance';
import ExpatInsurance from './pages/insurance/ExpatInsurance';
import DentalInsurance from './pages/insurance/DentalInsurance';
import LifeInsurance from './pages/insurance/LifeInsurance';
import DisabilityInsurance from './pages/insurance/DisabilityInsurance';
import BikeInsurance from './pages/insurance/BikeInsurance';
import OneStopShopDetails from './pages/oneStopService/OneServiceDetails';
import Imprint from './pages/Imprint';
import Feedback from './pages/Feedback';
import Blog from './pages/Blog';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import Team from './pages/Team';
import CouncilAndFaithDetails from './pages/councilAndFaith/CouncilAndFaithDetails';
import FaithAndReligion from './pages/councilAndFaith/FaithAndReligion';
import ViolenceHelpline from './pages/councilAndFaith/ViolenceHelpline';
import Advisor from './pages/Advisor';
import BlogContent from './pages/BlogContent/BlogContent';
import Counsellor from './pages/Counsellor/Counsellor';
import PrivateHealthPageExpat from './pages/PrivateHealthForExpats';

//CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/vendor/slick/slick.css';
import './assets/distdir/main.min.css';
// import './assets/css/main.css'

// Helmet
import { Helmet } from 'react-helmet-async';
import LogoImg from "./assets/images/logo.avif";
import ContactForm from './components/contact/ContactForm';

function App() {
  return (
    <>
    <Helmet>
             <meta property="og:image" content={LogoImg}/>
              <meta property="og:image:width" content="60px" />
    </Helmet>

      <Router>
        <ScrollToTopRoute />
        <Routes>
          <Route path="/appointment/book" element={<ContactForm />} />
          <Route path= "/insurance/private-health" element = {<PrivateHealthPage />} />
          <Route path= "/insurance/private-health-expats" element = {<PrivateHealthPageExpat />} />
          <Route exact path="/" element={<HomeDefault />} />
          <Route path="/team/:id" exact element={<Team />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/imprint" exact element={<Imprint />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" exact element={<CookiePolicy />} />
          <Route path="insurance" exact>
            <Route index={true} element={<InsuranceDetails />}></Route>
            <Route
              path="public-health-insurance"
              exact
              element={<PublicHealthInsurance />}
            />
            <Route path="private-health" exact>
              {/* <Route index={true} element={<PrivateHealthPage />}></Route> */}
              <Route path="advisors/:id" exact element={<Advisor />} />
            </Route>

            <Route
              path="liability-insurance"
              exact
              element={<LiabilityInsurance />}
            />
            <Route
              path="household-insurance"
              exact
              element={<HouseholdInsurance />}
            />
            <Route path="legal-insurance" exact element={<LegalInsurance />} />
            <Route path="expat-insurance" exact element={<ExpatInsurance />} />
            <Route
              path="dental-insurance"
              exact
              element={<DentalInsurance />}
            />
            <Route path="life-insurance" exact element={<LifeInsurance />} />
            <Route
              path="disability-insurance"
              exact
              element={<DisabilityInsurance />}
            />
            <Route path="bike-insurance" exact element={<BikeInsurance />} />
          </Route>
          <Route path="/bank-account" element={<BankAccountDetails />} />
          <Route path="money-transfer" exact>
            <Route index={true} element={<MoneyTransferDetails />}></Route>
            <Route path="transfer-wise" exact element={<TransferWise />} />
            <Route path="current-account" exact element={<CurrentAccount />} />
            <Route path="rental-deposit" exact element={<RentalDeposit />} />
          </Route>
          <Route path="council-and-faith" exact>
            <Route index={true} element={<CouncilAndFaithDetails />}></Route>
            <Route
              path="faith-and-religion"
              exact
              element={<FaithAndReligion />}
            />
            <Route
              path="violence-helpline"
              exact
              element={<ViolenceHelpline />}
            />
          </Route>
          <Route
            path="/individual-service"
            exact
            element={<OneStopShopDetails />}
          />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/counsellor" exact element={<Counsellor />} />
          <Route path="/blogContent/:id" exact element={<BlogContent />} />
          <Route path="/feedback" exact element={<Feedback />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <ScrollToTop
          className="scrollUp"
          smooth
          top="1500"
          component={<FaLevelUpAlt />}
        />
      </Router>
    </>
  );
}

export default App;
