import React from 'react';
import { PrivateHealthInsuranceAdvisors } from '../../utilities/constants';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import HeaderTop from '../../common/header/HeaderTop';
import HeaderBottomOne from '../../common/header/HeaderBottomOne';
import FooterOne from '../../common/footer/FooterOne';
import HeroService from '../../components/hero/HeroServices';
import counsellorCrumb from '../../assets/images/CounsellorCrumb.avif'

const Counsellor = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        heading1="Meet Our Counsellors"
        breadcrumbBg={counsellorCrumb}
        description="We are available for you, 24/7"
        currentPage="Our Blog"
      />
      <div className="service-details pb-11 pt-11 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10 mt-5">
              <div className="health-insurance-details-wrapper mt-5">
                <h2 id="our-advisors" className='mb-4 mx-5'>Our Advisors</h2>
                <div className="health-insurance-partners current-account-partners advisor-partners mb-5">
                  {PrivateHealthInsuranceAdvisors.map((partner, index) => (
                    <Card className="advisor-flex" style={{ margin: "5px", width: "calc(20% - 10px)" }}>
                      <a
                        href={partner.affiliateLink}
                        className="about-team-members advisors"
                      >
                        <div className="single-team-style-2 text-center advisors-team">
                          <div className="single-team-style-2-img advisors-img">
                            <img
                              src={partner.img}
                              alt={partner.imgAlt}
                              style={{
                                objectPosition: index === 1 ? "top" : "",
                                maxWidth: "100%",  
                                height: "",    
                              }}
                            />
                          </div>
                          <div className="single-team-content-style-2">
                            <h4 style={{fontSize : "18px"}}>{partner.name}</h4>
                            <span style={{fontSize : "12px"}}>{partner.description}</span>
                          </div>
                          <div
                            className="single-team-content-style-2"
                            style={{ color: "#efb44b", marginBottom: "10px" }}
                          >
                            5 <FaStar /> | 1000+ People advised
                          </div>
                          <div className="single-team-content-style-2">
                            <Link
                              to={partner.affiliateLink}
                              className="l-btn advisor-btn"
                              style={{ padding: "8px 20px" }}
                            >
                              Book an appointment
                            </Link>
                          </div>
                        </div>
                      </a>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  )
}

export default Counsellor;
