import React from "react";

const CtaTwo = () => {
  return (
    <>
      <div className="cta-area-two theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cta-style-2-wrapper text-center">
                <h2>
                  Get a Professional Help, <span > <a href="tel:+49 (0) 4282 8016 320"  >Call us now +49 (0) 4282 8016 320 </a></span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CtaTwo;
