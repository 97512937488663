import React from "react";
import { Link } from "react-router-dom";
import BackgroundPhoto from "../../assets/images/MAIN_BANNER.jpg";
import { LandingPageLink } from "../../utilities/constants";
import "./HeroStyleOne.css";

const HeroStyleOne = () => {
  return (
    <div className="hero-container">
      <img src={BackgroundPhoto} alt="background" className="hero-background" />
      <div className="hero-content">
        <h1>Save €3,500 yearly! Affordable, superior private health insurance</h1>
        <p>Best insurance provider in Germany with full support in English.</p>
        <p>Unlock exclusive benefits today! Book an appointment with Onboarding-Mate professional advisors.</p>
        <Link to={LandingPageLink[0].affiliateLink} className="l-btn mt-10 mb-10">
          Get Covered
        </Link>
      </div>
    </div>
  );
};

export default HeroStyleOne;
