import React from "react";
import { Card } from "react-bootstrap";
import {  DisabilityInsurancePartners, LandingPageLink } from "../../../../utilities/constants";
import { Link } from "react-router-dom";

const DisabilityInsuranceDetails = () => {
  return (
    <>
      <h2>Why Opt for Disability Insurance?</h2>
      <p>
        <b>Provides Financial Safety:</b> Disability insurance serves as a
        financial safety net in case you experience a disability that prevents
        you from working unexpectedly. This type of insurance is designed to
        provide income support if you are unable to work due to a disability
        related to your occupation.
        <br />
        <br />
        <b>How does it Work?</b> To be eligible for disability insurance
        benefits, the disability you experience must result in a significant
        reduction, at least 50%, in your ability to work. Additionally, the
        disability must persist for a duration of more than six months.
        <br />
        <br />
        <b>Covers Occupational Disability:</b> In the event of an occupational
        disability, public health insurance typically covers the first six
        months. However, having disability insurance can help bridge the
        financial gap until retirement or until you are able to return to work.
        <br />
      </p>
      <br />
      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <Link
            href={LandingPageLink[0].affiliateLink}
            rel="noopener noreferrer"
            className="l-btn"
          >
            Consult Advisors
          </Link>
        </div>
      </div>
      <br /><br />
      <h2>Our Partners</h2>

      <div className="health-insurance-partners current-account-partners">
        {DisabilityInsurancePartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div>
    </>
  );
};

export default DisabilityInsuranceDetails;
