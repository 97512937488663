import React from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import { servicesSubMenuWithOneStopService } from "../../utilities/constants";
import { Link } from "react-router-dom";

const ServiceOneItem = ({
  img,
  heading,
  description,
  serviceLink,
  id,
}) => {
  return (
    <>
      <div className="col-xl-3 col-lg-4 col-md-6 service-card" id={id}>
        <div
          className={
            id === "phoneAndInternet"
              ? "single-service disabled-services"
              : id === "taxReturnOnline"
              ? "single-service tax-return"
              : "single-service"
          }
        >
          <div className="service-icon">
            <img src={img} alt="Service Icon" />
          </div>
          <h3>{heading}</h3>
          <p>{description}</p>
          <a
            href={serviceLink}
            target={id === "taxReturnOnline" ? "_blank" : "_self"}
            rel="noopener noreferrer"
            className={id === "phoneAndInternet" ? "" : "service-read-more"}
          >
            Read More <FaChevronCircleRight />
          </a>
        </div>
        <div
          className={
            id === "phoneAndInternet" ? "disabled-badge" : "display-badge"
          }
        >
          Coming Soon
        </div>
      </div>
    </>
  );
};

const ServiceStyleOne = () => {
  return (
    <>
      <div className="service-area theme-bg-4 pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-70">
                <h2 id="our-services">Our Other Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {servicesSubMenuWithOneStopService.map((data, index) => (
              <ServiceOneItem
                key={data.id}
                img={data.img}
                heading={data.title}
                description={data.description}
                serviceLink={data.path}
                index={index}
                id={data.value}
              />
            ))}
            <div className="col-xl-6 col-lg-12 col-md-12">
              <div className="single-service-large">
                <h3>
                  We are professional <span>Onboarding providing service</span>
                </h3>
                <p>
                  We love to provide you our help and
                  <br />
                  we look forward to listening to your feedbacks and help us get
                  better for the future.{" "}
                </p>
                <Link to="/feedback" className="l-btn">
                  Provide Feedback
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceStyleOne;
