import React from "react";
import { Card } from "react-bootstrap";
import { CurrentAccountPartners } from "../../../../utilities/constants";

const CurrentAccountDetails = () => {
  return (
    <>
      <h2>Why Choose Us?</h2>
      <p>
        As experts in German banking regulations, we offer personalized,
        transparent, and reliable services to help you open your bank account in
        Germany. Say goodbye to language barriers and complicated bureaucracy,
        and leave the heavy lifting to us!
        <br />
        <br />
        <h4>Benefits:</h4>
        <ol>
          <li>
            <b>Linguistic Assistance:</b>
            Struggling with the German language? Don't worry, we offer
            comprehensive support in English and various other languages.
          </li>
          <li>
            <b>Expert Guidance:</b>
            Unfamiliar with German banking rules? Our team of experts will walk
            you through the process, explaining each step in easy-to-understand
            terms.
          </li>
          <li>
            <b>Time-Efficient:</b>
            We value your time and ensure a swift, smooth, and hassle-free
            account opening process.
          </li>
          <li>
            <b>Client-Focused:</b>
            Our services are tailored to your individual needs and
            circumstances. We offer support that extends beyond account opening,
            including ongoing banking assistance and financial advice.
          </li>
        </ol>
      </p>
      <br />
      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <a
            href={CurrentAccountPartners[0].affiliateLink}
            rel="noopener noreferrer"
            className="l-btn"
          >
            Create Current Account
          </a>
        </div>
      </div>
      <br />
      <br />
      <h2>Our Partners</h2>

      <div className="health-insurance-partners current-account-partners">
        {CurrentAccountPartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div>
    </>
  );
};

export default CurrentAccountDetails;
