import React from "react";

const HeaderTop = () => {

  return (
    <div className="header-top pb-10 theme-bg-1">
      <div className="container">
        <div className="row">
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
