import React from "react";
import insuranceBackground from "../../assets/images/insuranceDetails/privateHealth/imageBackground.avif";
import { LandingPageLink } from "../../utilities/constants";
import { Link } from "react-router-dom";

const PrivateHeroExpatService = () => {
  return (
    <div className="gray-bg-2 pt-70 hero-service">
      <div className="container">
        <div className="row">
          <div className="col-xl-1 col-lg-1 pr-50"></div>
          <div className="col-xl-6 col-lg-6 col-md-6 hero-service-first-section">
            <div className="hero-text-wrapper">
              <div className="section-title mb-40 hero-section-header">
                <h2 className="heading1">Hassle-Free Health Insurance Solution for Expats in Germany!</h2>
                <h3 className="mt-0" >Experience the peace of mind that comes with our private health insurance tailored for expats like you.</h3>
                <h4 className="mt-20" >Benefits provided by our company.</h4>
                <div className="points-container">
                  <ul style={{ listStyleType: "disc" }}>
                    <li style={{listStyleType: "disc" }} className="point mb-1"><strong>&nbsp; Faster Appointment Scheduling: &nbsp;</strong> Say goodbye to long waiting times. Get prompt access to top-notch medical professionals.</li>
                    <li style={{listStyleType: "disc" }}  className="point mb-1"><strong> &nbsp;Best Medical Care:</strong> &nbsp;Receive the highest standard of healthcare services, ensuring your well-being is our top priority</li>
                    <li style={{listStyleType: "disc" }}  className="point mb-1"><strong> &nbsp;24/7 Support:</strong> &nbsp;We're here for you round the clock. Count on us for assistance whenever you need it, especially in times of illness.</li>
                    <li style={{listStyleType: "disc" }}  className="point mb-1"><strong>&nbsp;Save on Annual Taxes:</strong> &nbsp;Enjoy the added benefit of tax savings while ensuring your health coverage remains robust and reliable.</li>
                    <li style={{listStyleType: "disc" }}  className="point mb-1"><strong>&nbsp;No Language Barriers,  No German Bureaucracy:</strong>&nbsp; We understand the challenges expats face. That's why we provide seamless service in your language, sparing you the headaches of navigating complex bureaucracy.</li>
                  </ul>
                </div>
                <Link to={LandingPageLink[0].affiliateLink} className="l-btn p-btn" rel="noopener noreferrer" >
                  Book Appointment
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 mt-3">
            <div className="hero-img-style-1">
              <img src={insuranceBackground} alt="Placeholder" />
            </div>
          </div>
          <div className="col-xl-1 col-lg-1 pr-50"></div>
        </div>
      </div>
    </div>
  );
};

export default PrivateHeroExpatService;
