import React from "react";
import { PrivateHealthInsurancePartners } from "../../utilities/constants";
import { Link } from "react-router-dom";
const ContactUsForm = () => {
  return (
    <>
      <div className="cta-area-two theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-9">
              <h1 style={{color:'#ffff'}}>Your health deserves better, don’t you think?</h1>
              <p style={{color:'#ffff'}} className="pt-20">Get a free, no-obligation consultation to find the best plan for you and sign up directly online. Without a broker.</p>
            </div>
           
            <div className="col-xl-3 d-flex align-items-center justify-content-center">

          <div className="band-btn-wrapper">
            <Link
                  to={PrivateHealthInsurancePartners[0].affiliateLink}
                  className="b-a-btn"
                  rel="noopener noreferrer"
                  style={{
                    display: "inline-block",
                    padding: "12px 24px",
                    backgroundColor: "#007CC3",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    textDecoration: "none",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#007CC3")}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#007CC3")}
                >
                  Book an appointment
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsForm;
