import React from "react";
import { TYPES_OF_FAQS } from "../../utilities/constants";

// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
import ProjectFaq from "../project-details/ProjectFaq";

const FAQSection = () => {
  return (
    <>
      <div className="how-it-works theme-bg-4 pt-110 pb-110 faq-section-row">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-50">
                <h2>FAQs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <Tabs
              defaultActiveKey="insurance"
              id="uncontrolled-tab-example"
              className="mb-3"
              fill
            >
              {servicesSubMenu.map((menu) => (
                <Tab
                  eventKey={menu.value}
                  title={menu.title}
                  className="tab-color"
                >
                  {menu.faqComponent}
                </Tab>
              ))}
              
            </Tabs> */}
            <ProjectFaq name={TYPES_OF_FAQS.insurance} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQSection;
