import React from "react";
import OurAdvisor from "../../common/ourAdvisors/OurAdvisor";
import HeaderTop from "../../common/header/HeaderTop";
import insuranceBackground from "../../assets/images/insuranceDetails/bikeInsurance/bike-insurance.jpg";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import {
  BikeInsurancePartners,
  TYPES_OF_FAQS,
  insuranceSubMenu,
} from "../../utilities/constants";

import BikeInsuranceDetails from "../../components/service-details/insurance/bikeInsurance/BikeInsuranceDetails";
import WhatsCovered from "../../components/service-details/insurance/expatInsurance/WhatsCovered";
import ProjectFaq from "../../components/project-details/ProjectFaq";
import OtherServices from "../../components/service-details/OtherServices";

const BikeInsurance = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={insuranceBackground}
        heading1={`Bike Insurance: Ride Worry-Free`}
        description="Don't let unexpected events put a brake on your biking adventures. Get bike insurance for complete protection and worry-free rides"
        currentPage="bikeInsurance"
        className="health-insurance-hero"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="health-insurance-details-wrapper">
                <BikeInsuranceDetails />
                <WhatsCovered currentPage="publicHealthInsurance" />
              </div>
            </div>
            <div className="mt-5"> 
            < OurAdvisor />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <div className="cta-area theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cta-style-1-wrapper text-center">
                <p className="health-insurance-cta">
                  Best Bike Insurance Service at your doorstep.
                </p>
                <a
                  href={BikeInsurancePartners[0].affiliateLink}
                  rel="noopener noreferrer"
                  className="l-btn"
                >
                  Claim Bike Insurance
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works pt-110 pb-110 faq-section-row">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-50">
                <h2>FAQs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <ProjectFaq name={TYPES_OF_FAQS.bikeInsurance} />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <OtherServices
        currentPage="bikeInsurance"
        subMenu={insuranceSubMenu}
        heading={"Other Insurance Services"}
        className={"insurance-services"}
      />
      <FooterOne />
    </>
  );
};

export default BikeInsurance;
