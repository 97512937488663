import React from "react";
import { Card } from "react-bootstrap";
import { TransferWisePartners } from "../../../../utilities/constants";

const TransferWiseDetails = () => {
  return (
    <>
      <h2>Big Fat Savings on International Transfers</h2>
      <p>
        Sending money globally shouldn't break the bank. Onboarding Mate was
        created to help you save on international transfers and exchanges. We
        strive to charge minimal fees, with the goal of being fee-free in the
        future.
      </p>
      <br />
      <br />

      <h2>Embrace a World Without Financial Barriers</h2>
      <p>
        <ol>
          <li>
            Our vision is to enable seamless living and working anywhere by
            offering instant, transparent, and convenient money transfers across
            borders—ultimately, at no cost to you.
          </li>
          <li>
            The Wise account is your global solution for managing money across
            borders. Designed for the world, it helps you save both money and
            time, allowing you to prioritize the activities you cherish.
          </li>
        </ol>
      </p>
      <br />
      <br />

      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <a
            href={TransferWisePartners[0].affiliateLink}
            target="_blank"
            rel="noopener noreferrer"
            className="l-btn"
          >
            Transfer Your Money
          </a>
        </div>
      </div>
      <br /><br />
      <h2>Our Partners</h2>

      <div className="health-insurance-partners">
        {TransferWisePartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div>
    </>
  );
};

export default TransferWiseDetails;
