import React from "react";
import Logo from "../../components/logo/Logo";
import NavMenu from "./Nav";
import MobileMenu from "./MobileMenu";

const HeaderBottomOne = () => {
  const serviceItem = document.querySelector(
    ".main-menu ul li #menu-item-services"
  );

  if (serviceItem) {
    serviceItem.addEventListener("mouseout", () => {
      serviceItem.classList.remove("submenu");
    });
  }
  return (
    <>
      <div className="header-bottom">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 logo-row">
              <Logo />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 sign-in-row">
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 navmenu-row">
              <NavMenu />
            </div>
            
            <div className="col-10 mobile-menu-container">
              <div className="mobile-menu">
                <MobileMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderBottomOne;
