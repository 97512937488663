import React from "react";
import { Card } from "react-bootstrap";
import { LifeInsurancePartners } from "../../../../utilities/constants";

const LifeInsuranceDetails = () => {
  return (
    <>
      <h2>Why opt for Life Insurance?</h2>
      <p>
        <b>Future Financial Security:</b> A life insurance policy provides a
        significant financial safety net for your dependents. It ensures that
        their lifestyle, education, and other essential needs are covered in
        case of your untimely demise.
        <br />
        <br />
        <b>Debt and Expense Coverage: </b>Life insurance helps manage any
        outstanding debts, mortgages, or loans that you might leave behind, thus
        preventing your family from facing these potentially overwhelming
        financial burdens.
        <br />
        <br />
        <b>Retirement Plan Enhancement:</b> With certain types of life
        insurance, you can accumulate a cash value over time, which can serve as
        an additional resource during your retirement years.
        <br />
      </p>
      <br />
      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <a
            href={LifeInsurancePartners[0].affiliateLink}
            rel="noopener noreferrer"
            className="l-btn"
          >
            Consult Advisors
          </a>
        </div>
      </div>
      <br /><br />
      <h2>Our Partners</h2>

      <div className="health-insurance-partners current-account-partners">
        {LifeInsurancePartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div>
    </>
  );
};

export default LifeInsuranceDetails;
