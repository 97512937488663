import React from 'react';
import { PrivateHealthInsuranceAdvisors } from '../../utilities/constants';
import HubspotContactForm from '../../components/home1/HubspotContactForm';
const OurAdvisor = () => {
  // const styleObj = {
  //   margin: "auto",
  //   display: "flex",
  //   justifyContent: "center",
  //   marginTop: "20px",
  // };
  return (
    <div className="service-details pb-11 pt-30">
      <div className="container">
        <div className="row">
          <div className="col-xl-1 col-lg-1 pr-50"></div>
          <div className="col-xl-10 col-lg-10">
            <div className="health-insurance-details-wrapper">
              <h2 id="our-advisors" className='mb-4 mx-5'>Some of our Advisors</h2>
              <div className="health-insurance-partners current-account-partners advisor-partners" style={{border:'none'}}>
                {PrivateHealthInsuranceAdvisors.map((partner, index) => (
                  <div
                    className="advisor-flex"
                    style={{
                      margin: "5px",
                      width: "calc(25% - 10px)",
                      height: "auto", 
                    }}
                    key={index}
                  >
                    <a
                      href={partner.affiliateLink}
                      className="about-team-members advisors"
                      style={{ paddingTop: "50px" }}
                    >
                      <div className="single-team-style-2 text-center advisors-team">
                        <div className="single-team-style-2-img ">
                          <img
                            src={partner.img}
                            alt={partner.imgAlt}
                            style={{
                              objectPosition: index === 1 ? "top" : "",
                              height:'250px'
                            }}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
              <div className='pr-50 pl-50'>
              <HubspotContactForm 
                   region="eu1"
                   portalId="144261095"
              formId="cb9c6d11-8c47-47de-9ff1-aef70a14ca86" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurAdvisor;
