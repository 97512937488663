import React from "react";
import {
  CurrentAccountPartners,
  TransferWisePartners,
  LandingPageLink,
} from "../../utilities/constants";
import { Link } from "react-router-dom";

const HeroService = ({
  breadcrumbBg,
  heading1,
  heading2,
  heading3,
  description,
  currentPage,
  className,
  points
}) => {

  const shouldRenderPoints = currentPage === "privateHealthInsurance" || currentPage === "landingPage";
  return (
    <>
      <div className="gray-bg-2 pt-70 hero-service">
        <div className="container">
          <div
            className="row"
            style={
              currentPage === "publicHealthInsurance" ||
              currentPage === "transferWise" ||
              currentPage === "privateHealthInsurance" ||
              currentPage === "householdInsurance" ||
              currentPage === "expatInsurance" ||
              currentPage === "lifeInsurance" ||
              currentPage === "bikeInsurance" ||
              currentPage === "OneStopShop" ||
              currentPage === "feedback" ||
              currentPage === "violenceHelpline"
                ? { flexDirection: "row-reverse" }
                : { flexDirection: "row" }
            }
          >
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-6 col-lg-6 col-md-6 hero-service-first-section">
              <div className="hero-text-wrapper">
                <div className="section-title mb-40 hero-section-header">
                  <h2 className={` ${className}`}>
                    {heading1}
                  </h2>
                  {heading2 && <br />}
                  {heading2 && <h4 className="mt-1">{heading2}</h4> }
                  {heading3 && <h4 className="mt-0">{heading3}</h4>}

                  <p
                    className={
                      currentPage === "publicHealthInsurance" ||
                      currentPage === "transferWise" ||
                      currentPage === "privateHealthInsurance" ||
                      currentPage === "lifeInsurance"
                        ? "your-health-comes-first"
                        : ""
                    }
                  >
                    {description}
                  </p>
                  {shouldRenderPoints && (
                    <div className="points-container">
                      <ul>
                        {points?.map((point, index) => (
                          <li style={{listStyleType: "disc" , paddingBottom:"8px" ,  fontSize:"17px"}} key={index} className="point" >
                            {point}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {currentPage === "landingPage" ||
                  currentPage === "publicHealthInsurance" ||
                  currentPage === "transferWise" ||
                  currentPage === "currentAccount" ||
                  currentPage === "privateHealthInsurance" ||
                  currentPage === "liabilityInsurance" ||
                  currentPage === "householdInsurance" ||
                  currentPage === "legalInsurance" ||
                  currentPage === "expatInsurance" ||
                  currentPage === "lifeInsurance" ||
                  currentPage === "disabilityInsurance" ||
                  currentPage === "bikeInsurance" ||
                  currentPage === "violenceHelpline" ? (
                    <Link
                      to={
                        currentPage === "transferWise"
                          ? TransferWisePartners[0].affiliateLink
                          : currentPage === "currentAccount"
                          ? CurrentAccountPartners[0].affiliateLink
                          : LandingPageLink[0].affiliateLink
                      }
                     
                      rel="noopener noreferrer"
                      className="l-btn"
                    >
                      {
                      currentPage === "transferWise"
                        ? "Transfer money"
                        : currentPage === "currentAccount"
                        ? "Create Account"
                        : "Book Appointment"}
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-5 mt-3">
              <div className="hero-img-style-1"  >
                <img src={breadcrumbBg} alt="" />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroService;
