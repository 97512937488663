import React from "react";
import Tab from "react-bootstrap/Tab";
import { moneyTransferSubMenu } from "../../../utilities/constants";
import Card from "react-bootstrap/Card";

const MoneyTransferServices = () => {
  return (
    <>
      <div className="row">
        <Tab.Container defaultActiveKey="publicHealthInsurance">
          <div className="col-xl-12">
            <h2>Money Transfer Services</h2>
            <div className="works-menu transfer-services-menu text-center mb-50">
              {moneyTransferSubMenu.map((item) => (
                <a href={item.path}>
                  <Card>
                    <Card.Img
                      style={{ width: "auto", height: "105px" }}
                      alt="171x180"
                      src={item.img}
                      variant="top"
                    />
                    <Card.Body>{item.title}</Card.Body>
                  </Card>
                </a>
              ))}
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default MoneyTransferServices;
