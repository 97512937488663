import React from "react";
import { Card } from "react-bootstrap";
import { LandingPageLink, LiabilityInsurancePartners } from "../../../../utilities/constants";
import { Link } from "react-router-dom";

const LiabilityInsuranceDetails = () => {
  return (
    <>
      <h2>Accidentally damaged someone's Property?</h2>
      <p>
        We've got you covered. Our insurance offers protection for any slips,
        trips or mishaps you might encounter.
        <br />
        <br />
        <b>Offering robust coverage up: </b>we've got you insured for major
        incidents, including property damage, personal injuries, and significant
        asset losses.
        <br />
        <br />
        <b>The Best coverage guarantee:</b> you can relax knowing that we've got
        you covered. Any claim that is covered by other personal liability
        insurances in Germany is covered by us too (terms and conditions apply).
        <br />
        <br />
        <b>Straightforward & Transparent Policy: </b>the entire sign-up process
        is fully digital with no paperwork involved. Should you have any
        queries, our team is on standby to provide you with English language
        support.
        <br />
      </p>
      <br />
      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <Link
            to={LandingPageLink[0].affiliateLink}
            rel="noopener noreferrer"
            className="l-btn"
          >
            Consult Advisors
          </Link>
        </div>
      </div>
      <br /><br />
      <h2>Our Partners</h2>

      <div className="health-insurance-partners current-account-partners">
        {LiabilityInsurancePartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div>
    </>
  );
};

export default LiabilityInsuranceDetails;
