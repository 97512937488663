import React from "react";
import { Card } from "react-bootstrap";

const HowToRedeem = () => {
  return (
    <div className="row how-does-it-work pt-80">
      <h1 className="how-does-it-work-header">How to redeem your offer</h1>

      <div className="steps">
        <Card>
          <Card.Body>
            <Card.Text>
              <h1>1</h1>
              <h4>Start by filling in your phone number</h4>
              <p>Get your unique link to download the Revolut app.</p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          {/* <Card.Img variant="top" src={partner.img} /> */}
          <Card.Body>
            <Card.Text>
              <h1>2</h1>
              <h4>Sign up to Revolut</h4>
              <p>Create your account in a few clicks</p>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          {/* <Card.Img variant="top" src={partner.img} /> */}
          <Card.Body>
            <Card.Text>
              <h1>3</h1>
              <h4>Get your Premium upgrade</h4>
              <p>
                Upgrade your account when promoted & receive your free trial of
                Premium
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default HowToRedeem;
