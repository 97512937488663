import CookieConsent, { resetCookieConsentValue } from "react-cookie-consent";
import React, { useEffect } from "react";
import { FaCaretDown, FaCaretRight, FaCaretUp } from "react-icons/fa";

const AcceptCookies = () => {
  const [settingOpen, setSettingOpen] = React.useState(false);
  const onSettingsClick = () => {
    settingOpen ? setSettingOpen(false) : setSettingOpen(true);
  };
  //   const [checked, setChecked] = React.useState(false);
  //   const handleChange = (e, checkBoxName) => {
  //     setChecked(e.target);
  //   };

  useEffect(() => {
    resetCookieConsentValue();
  }, []);
  return (
    <CookieConsent
      location="bottom"
      declineButtonClasses="cookie-decline-btn"
      buttonText="Agree"
      buttonClasses="l-btn cookies-accept-btn"
      declineButtonText="I don't agree"
      cookieName="myAwesomeCookieName2"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
      onAccept={(acceptedByScrolling) => {
        if (acceptedByScrolling) {
          // triggered if user scrolls past threshold
          console.log("Accept was triggered by user scrolling");
        } else {
          console.log("Accept was triggered by clicking the Accept button");
        }
      }}
      enableDeclineButton
      onDecline={() => {}}
    >
      <div
        className="row cookie-consent"
        style={{ fontSize: "12px", padding: "10px 40px" }}
      >
        <h2>Privacy settings </h2>
        <div className="col-xl-10">
          We use cookies and similar technologies that are necessary to run the
          app and the website. Additional cookies are only used with your
          consent. We use them to access, analyse and store information such as
          the characteristics of your device as well as certain personal data
          (IP addresses, navigation usage, geolocation data or unique
          identifiers). The processing of your data serves various purposes:
          Analytics cookies allow us to analyse our performance to offer you a
          better online experience and evaluate the efficiency of our campaigns.
          Personalisation cookies give you access to a customised experience of
          our website with usage-based offers and support. Finally, Advertising
          cookies are placed by third-party companies processing your data to
          create audiences lists to deliver targeted ads on social media and the
          internet. You may freely give, refuse or withdraw your consent at any
          time using the link provided at the bottom of each page. You can
          consent to our use of cookies by clicking on Agree. For more
          information on which data is collected and how it is shared with our
          partners please read our privacy and cookie policy :{" "}
        </div>
      </div>
      <div className="cookie-consent" style={{ padding: "10px 40px" }}>
        <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">
          Cookie Policy <FaCaretRight />
        </a>{" "}
        <br />
        <br />
      </div>

      <div className="row cookie-bottom-row" style={{ padding: "10px 40px" }}>
        <div className="col-xl-6 cookie-bottom-row-first">
          <a href="imprint" target="_blank" rel="noopener noreferrer">
            Imprint <FaCaretRight />
          </a>
        </div>
        <div
          className="col-xl-6 cookie-bottom-row-second"
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={onSettingsClick}
        >
          My settings {settingOpen ? <FaCaretUp /> : <FaCaretDown />}
        </div>
      </div>
      <div
        className="row  cookie-settings"
        style={
          settingOpen
            ? { display: "block", padding: "10px 40px" }
            : { display: "none" }
        }
      >
        <div className="col-xl-6">
          <form className="accept-cookies-form">
            <div key={`analytics-key`} className="mb-3">
              <div>
                <input
                  value="Analytics"
                  type="checkbox"
                  //   onChange={(e) => handleChange(e, "Analytics")}
                  id={`Analytics`}
                  label={"Analytics"}
                />
                <span style={{ paddingLeft: "10px" }}>Analytics</span>
              </div>

              <ol style={{ fontSize: "12px" }}>
                <li>
                  Monitor website traffic and optimize your user experience
                </li>
                <li>Evaluate which marketing channels are performing better</li>
                <li>
                  Analyse aggregated data about usage of the website to
                  understand our customers. Randomized identifiers shared with
                  partners.
                </li>
              </ol>
            </div>
            <div key={`Personalization-key`} className="mb-3">
              <div>
                <input
                  value="Personalization"
                  type="checkbox"
                  //   onChange={(e) => handleChange(e, "Personalization")}
                  id={`Personalization`}
                  label={"Personalization"}
                />
                <span style={{ paddingLeft: "10px" }}>Personalization</span>
              </div>
              <ol style={{ fontSize: "12px" }}>
                <li>Store your preferences from previous visits</li>
                <li>Collect user feedback to improve our website</li>
                <li>
                  Evaluate your interests to provide you unique customised
                  content and offers
                </li>
              </ol>
            </div>
            <div key={`Advertising-key`} className="mb-3">
              <div>
                <input
                  value="test"
                  type="checkbox"
                  //   onChange={(e) => handleChange(e, "Advertising")}
                  id={`Advertising`}
                  label={"Advertising"}
                />
                <span style={{ paddingLeft: "10px" }}>Advertising</span>
              </div>

              <ol style={{ fontSize: "12px" }}>
                <li>
                  Make online and social advertising more relevant for you
                </li>
                <li>
                  Invite specific customer groups to reconnect with our products
                  later
                </li>
                <li>
                  Share data with our advertising and social media partners via
                  their third-party cookies to match your interests
                </li>
              </ol>
            </div>
          </form>
        </div>
      </div>
    </CookieConsent>
  );
};
export default AcceptCookies;
