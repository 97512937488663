import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import violenceHelplineImg from "../../assets/images/councilAndFaith/violenceHelpline/violence-helpline.jpg";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import OtherServices from "../../components/service-details/OtherServices";
import { TYPES_OF_FAQS, ViolenceHelplinePartners, councilAndFaithSubMenu } from "../../utilities/constants";
import ViolenceHelplineDetails from "../../components/service-details/councilAndFaith/violenceHelpline/ViolenceHelplineDetails";
import ProjectFaq from "../../components/project-details/ProjectFaq";

const ViolenceHelpline = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={violenceHelplineImg}
        heading1={`We say no to Violence!!!`}
        description="Our hotline offers anonymous counseling by phone, chat or e-mail for women who are affected or threatened by violence."
        currentPage="violenceHelpline"
        className="health-insurance-hero"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="health-insurance-details-wrapper violence-helpline">
                <ViolenceHelplineDetails />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <div className="cta-area transfer-wise-cta theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cta-style-1-wrapper text-center">
                <p>Use our hotline offers anonymous counseling by phone, chat or e-mail for women who are affected or threatened by violence.</p>
                <a
                  href={ViolenceHelplinePartners[0].affiliateLink}
                  rel="noopener noreferrer"
                  className="l-btn"
                >
                  Call our hotline
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works pt-110 pb-110 faq-section-row">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-50">
                <h2>FAQs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <ProjectFaq name={TYPES_OF_FAQS.violenceHelpline} />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <OtherServices
        currentPage="violenceHelpline"
        subMenu={councilAndFaithSubMenu}
        heading={"Other Council and Faith Services"}
        className="money-transfer faith-council"
      />
      <FooterOne />
    </>
  );
};

export default ViolenceHelpline;
