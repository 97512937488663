import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import FooterOne from "../common/footer/FooterOne";
import { LandingPageLink, TYPES_OF_FAQS } from "../utilities/constants";
import WhyFeather from "../components/service-details/insurance/privateHealth/WhyOnBoardingMate";
import ProjectFaq from "../components/project-details/ProjectFaq";
import OurAdvisor from "../common/ourAdvisors/OurAdvisor";
import TestimonialStyleFour from "../components/testimonial/TestimonialStyleFour";
import TakeTheStep from "../components/service-details/insurance/privateHealth/TakeTheStep";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PHIAdvantages from "../components/service-details/insurance/privateHealth/PHIAdvantages";
import PrivateHealthInsuranceBenefits from "../components/service-details/insurance/privateHealth/PrivateHealthInsuranceBenefits";
import PrivateHeroExpatService from "../components/hero/PrivateHealthHeroExpats";

const PrivateHealthPageExpat = () => {
  return (
    <>
      <Helmet>
        <title>Best Private Health Insurance In Germany for Expats</title>
        <meta
          name="description"
          content="Discover the best private health insurance in Germany. Onboarding Mate offers tailored policies and exceptional customer service to meet your unique needs."
        />
         <meta name="keywords" content="Best Private Health Insurance In Germany, Private Health Insurance In Germany For Foreigners, Expat Health Insurance Germany" />
      </Helmet>

      <HeaderTop />
      <HeaderBottomOne />
      <PrivateHeroExpatService />
      <TestimonialStyleFour />
      <div className="mt-70"></div>
      {/* <div className="col-xl-10 col-lg-10 pd180 mt-60">
        <WhyPrivateHealth /> in the replacement of PHI-Benefits
        <div className="col-xl-1 col-lg-1 pr-50"></div>
      </div> */}
      <PHIAdvantages />
      <PrivateHealthInsuranceBenefits />
        <TakeTheStep /> 
        <OurAdvisor />
      <div className="col-xl-10 col-lg-10 pd180 mt-60">
        <WhyFeather currentPage="publicHealthInsurance" />

        <div className="GetCovered pt-50">
          <Link
            rel="noopener noreferrer"
            to={LandingPageLink[0].affiliateLink}
            className="l-btn "
          >
            Check your benefits
          </Link>
        </div>
      </div>

     
      <div className="col-xl-1 col-lg-1 pt-50"></div>
      <div className="how-it-works pt-110 pb-110 faq-section-row">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-50">
                <h2>FAQs</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <ProjectFaq name={TYPES_OF_FAQS.privateHealthInsurance} />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <div className="cta-area theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cta-style-1-wrapper text-center">
                <p className="health-insurance-cta">
                  Make your health your utmost priority.
                </p>
                <a
                  href={LandingPageLink[0].affiliateLink}
                  rel="noopener noreferrer"
                  className="l-btn"
                >
                  Claim Private Health Insurance
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </>
  );
};

export default PrivateHealthPageExpat;
