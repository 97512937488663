import React from "react";
import OtherServicesComponent from "../home2/OtherServicesComponent";

const OtherServices = ({currentPage, subMenu, heading, className}) => {
    return (
        <div
        className=" bg-property pt-110 pb-110 other-srv-bg"
        data-overlay="black"
        data-opacity="7"
      >
        <div className="container">
           <div className="row position-relative">
            <div className="col-xl-12">
              <div className="section-title text-center mb-70">
                <h2 className="other-srv-h2">{heading}</h2>
              </div>
            </div>
          </div> 
          <div className="row position-relative">
            <OtherServicesComponent 
            currentPage={
                currentPage
            }
            className={className}
            subMenu={subMenu}
            />
          </div>
        </div>
      </div>
    )
}
export default OtherServices;