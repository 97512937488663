import React, { useEffect, useState } from "react";
import HeaderTop from "../common/header/HeaderTop";
import FooterOne from "../common/footer/FooterOne";
import { useLocation } from "react-router-dom";
import { AdvisorData } from "../utilities/constants";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import DetailedAdvisor from "../components/service-details/insurance/privateHealth/DetailledAdvisor";

const Advisor = (props) => {
  const { pathname } = useLocation();
  const [member, setMember] = useState({});
  useEffect(() => {
    const teamMember = pathname.split("/")[4];
    console.log({teamMember});
    const teamMemberSelected = AdvisorData[teamMember];
    setMember(teamMemberSelected);
  }, [pathname]);

  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <DetailedAdvisor member={member} />
      <FooterOne />
    </>
  );
};

export default Advisor;
