import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import OurAdvisor from "../../common/ourAdvisors/OurAdvisor";
import insuranceBackground from "../../assets/images/insuranceDetails/dentalInsurance/dental-insurance.jpg";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import {
  LandingPageLink,
  TYPES_OF_FAQS,
  insuranceSubMenu,
} from "../../utilities/constants";
// import WhatsCovered from "../../components/service-details/insurance/dentalInsurance/WhatsCovered";
import DentalInsuranceDetails from "../../components/service-details/insurance/dentalInsurance/DentalInsuranceDetails";
import WhatsCovered from "../../components/service-details/insurance/expatInsurance/WhatsCovered";
import ProjectFaq from "../../components/project-details/ProjectFaq";
import OtherServices from "../../components/service-details/OtherServices";

const DentalInsurance = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={insuranceBackground}
        heading1={`Your Smile's Best Financial Shield`}
        // heading2={`And preventive treatments?`}
        description="Don't dread the dentist's bill! Our Dental Insurance has you covered, offering complete protection for all dental procedures."
        currentPage="dentalInsurance"
        className="health-insurance-hero"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="health-insurance-details-wrapper">
                <DentalInsuranceDetails />

                <WhatsCovered currentPage="publicHealthInsurance" />
              </div>
            </div>
            <div className="mt-5"> 
            < OurAdvisor />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <div className="cta-area theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cta-style-1-wrapper text-center">
                <p className="health-insurance-cta">
                  Dental Insurance at your service.
                </p>
                <a
                  href={LandingPageLink[0].affiliateLink}
                  rel="noopener noreferrer"
                  className="l-btn"
                >
                  Claim Dental Insurance
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works pt-110 pb-110 faq-section-row">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-50">
                <h2>FAQs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <ProjectFaq name={TYPES_OF_FAQS.dentalInsurance} />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <OtherServices
        currentPage="dentalInsurance"
        subMenu={insuranceSubMenu}
        heading={"Other Insurance Services"}
        className={"insurance-services"}
      />
      <FooterOne />
    </>
  );
};

export default DentalInsurance;
