import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import currentAccountImg from "../../assets/images/moneyTransferDetails/currentAccount/current-account.jpg";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import {
  CurrentAccountPartners,
  TYPES_OF_FAQS,
  moneyTransferSubMenu,
} from "../../utilities/constants";
import CurrentAccountDetails from "../../components/service-details/moneyTransfer/currentAccount/CurrentAccountDetails";
import HowToRedeem from "../../components/service-details/moneyTransfer/currentAccount/HowToRedeem";
import ProjectFaq from "../../components/project-details/ProjectFaq";
import OtherServices from "../../components/service-details/OtherServices";

const CurrentAccount = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={currentAccountImg}
        heading1={`Your Trusted Partner for Hassle-Free Banking in Germany`}
        // heading2={`Creating a bank account`}
        // heading3={`Was this easy!`}
        description="Welcome to our world where banking in Germany becomes an effortless experience."
        currentPage="currentAccount"
        className="health-insurance-hero"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="health-insurance-details-wrapper current-account-details">
                <CurrentAccountDetails />

                <HowToRedeem currentPage="currentAccount" />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <div className="cta-area transfer-wise-cta theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cta-style-1-wrapper text-center">
                <p>Create a bank account and avail many new benefits.</p>
                <a
                  href={CurrentAccountPartners[0].affiliateLink}
                  rel="noopener noreferrer"
                  className="l-btn"
                >
                  Create Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works pt-110 pb-110 faq-section-row">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-50">
                <h2>FAQs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <ProjectFaq name={TYPES_OF_FAQS.currentAccount} />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <OtherServices
        currentPage="currentAccount"
        subMenu={moneyTransferSubMenu}
        heading={"Other Money Transfer Services"}
        className="money-transfer"
      />
      <FooterOne />
    </>
  );
};

export default CurrentAccount;
