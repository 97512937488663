import React, { useState } from "react";
import { FaAngleDoubleRight } from "react-icons/fa";

const CustomForm = ({ status, message, onValidated, buttonClass }) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const clearFields = () => {
    setEmail("");
  };

  const handleChange = (e) => {
    setSuccess(false);
    setError(false);
    setEmail(e.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      window.Email.send({
        Host: "smtp.elasticemail.com",
        To: `${process.env.REACT_APP_SMTP_EMAIL}`,
        From: `${process.env.REACT_APP_SMTP_EMAIL}`,
        Username: `${process.env.REACT_APP_SMTP_USERNAME}`,
        Password: `${process.env.REACT_APP_SMTP_PASSWORD}`,
        Subject: `Subscription Email`,
        Body: `This is the subscription email message sent by ${email}.`,
      })
        .then((message) => {
          if (message === "OK") {
            setSuccess(true);
            setError(false);
            clearFields();
          } else {
            setError(true);
            setSuccess(false);
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
    } catch (err) {
      setError(true);
      setSuccess(false);
    }
  };
  return (
    <form className="mc__form" onSubmit={(e) => handleSubmit(e)}>
      <div className={buttonClass ? "mt-40" : "footer-newsletter mt-40"}>
        <input
          type="email"
          placeholder="Email Address"
          onChange={handleChange}
          value={email}
          isrequired={email}
        />
        <button
          label="subscribe"
          type="submit"
          formValues={[email]}
          className={buttonClass ? buttonClass : ""}
        >
          {buttonClass ? "Subscribe" : <FaAngleDoubleRight />}
        </button>
      </div>
      {error && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{
            __html: "There was some error. Please try again.",
          }}
        />
      )}
      {success && (
        <div
          id="success-alert"
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: "Thanks for subscribing." }}
        />
      )}
    </form>
  );
};

export default CustomForm;
