import React from 'react'
import { AppointmentLink, BlogContent } from '../../utilities/constants';
import { useParams } from 'react-router-dom';

const Blog = () => {

    const { id } = useParams();
    const jsonData = BlogContent.find((blog) => blog.id === parseInt(id,10));
  return (
    <div className="BlogContent pb-30 ">
    <div className="BlogHeader">
      <h1 className="Title">{jsonData?.title}</h1>
    </div>
    <div className='hr'></div>
    <div className="BlogBody">
      {jsonData?.content.map((item, index) => {
        
        if (item.type === "heading") {
          return <h2 key={index} className="BlogHeading mt-5 mb-3">{item?.text}</h2>;
        } else if (item.type === "subheading") {
          return <span key={index} className="BlogSubheading">{item?.text} </span>;
        }
        else if (item.type === "subheadingdiv") {
          return <div key={index} className="BlogSubheading mt-20">{item?.text} </div>;
        }
        else if (item.type === "subheadingdiv2") {
          return <div key={index} className="Subheading mt-20">{item?.text} </div>;
        }

        else if (item.type === "subheading-text"){
          return <span className="BlogParagraph">{item?.text} </span>
        } else if (item.type === "paragraph") {
          return <p key={index} className="BlogParagraph">{item?.text}</p>;
          
        } else if (item.type ==="image"){
          return <img src={item?.url} alt='' key={index} className='BlogImage'   />
        }
        else if (item.type === "italic-paragraph") {
          return <i key={index} className="BlogParagraph">{item?.text}</i>;
        }
        else if(item.type ==="link"){
          return <p> <a href={item?.text} className='link' >{item?.text}</a> </p>
        }
        else if(item.type ==="Bloglink"){
          return <span> <a href="https://onboarding-mate.com/BlogContent/7" className='link'  style={{ display: 'inline' }}>{item?.text}</a> </span>
        }
        else if(item.type ==="Appointmentlink"){
          return <span> <a href={AppointmentLink.live} style={{ color: '#004b76', textDecoration: 'underline', cursor: 'pointer' }}>{item?.text}</a> </span>
        }
        else if( item.type === "br"){
          return <br></br>
        }
        return null;
      })}
    </div>
  </div>
  )
}

export default Blog