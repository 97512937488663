import React from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import {  servicesSubMenuWithOneStopServiceTwo } from "../../utilities/constants";
import { Link } from "react-router-dom";
import { PrivateHealthInsurancePartners } from "../../utilities/constants";




const ServiceFourItem = ({
  img,
  heading,
  description,
  serviceLink,
  id,
}) => {
  return (
    <>
      <div className="col-xl-3 col-lg-4 col-md-6 service-card mr-10" id={id}>
        <div
          className={
            id === "phoneAndInternet"
              ? "single-service disabled-services"
              : id === "taxReturnOnline"
              ? "single-service tax-return"
              : "single-service"
          }
        >
          <div className="service-icon">
            <img src={img} alt="Service Icon" />
          </div>
          {heading}
          <p>{description}</p>
          <Link
            to={serviceLink}
            target={id === "taxReturnOnline" ? "_blank" : "_self"}
            rel="noopener noreferrer"
            className={id === "phoneAndInternet" ? "" : "service-read-more"}
          >
            Read More <FaChevronCircleRight />
          </Link>
        </div>
        <div
          className={
            id === "phoneAndInternet" ? "disabled-badge" : "display-badge"
          }
        >
          Coming Soon
        </div>
      </div>
    </>
  );
};

const ServiceStyleFour = () => {
  return (
    <>
      <div className="service-area theme-bg-4 pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-100">
                <h2 id="our-services">Discover the most popular insurances</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {servicesSubMenuWithOneStopServiceTwo.map((data, index) => (
              <ServiceFourItem
                key={data.id}
                img={data.img}
                heading={data.title}
                description={data.description}
                serviceLink={data.path}
                index={index}
                id={data.value}
              />
            ))}
            
            <div className="col-xl-5 col-lg-5 col-md-5">
              <div className="single-service-large">
                <h3>
                Unlock exclusive benefits today! Book an appointment with Onboarding-Mate professional advisors.
                </h3>
                <p>
                  We love to provide you our help and
                  <br />
                  Don't miss out, sign up now!{" "}
                </p>
                <Link to={PrivateHealthInsurancePartners[0].affiliateLink} className="l-btn m-btn">
                  Book an appointment
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceStyleFour;
