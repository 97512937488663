import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

function FaqAccordion({ question, answer, index, shouldOpen }) {
  // useEffect(() => {
  //   const element = document
  //     .getElementById("0-accordion-panel")
  //     .getElementsByClassName("accordion__panel")[0];

  //   if (element && shouldOpen) {
  //     element.removeAttribute("hidden");
  //   }
  // }, [shouldOpen]);
  return (
    <>
      <Accordion allowZeroExpanded id={`${index}-accordion-panel`}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>{question}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>{answer}</AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}

export default FaqAccordion;
