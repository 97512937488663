import React from "react";

const ServiceDetailsContent = () => {
  return (
    <>
      <h2>Embrace your faith without Geographical Barriers</h2>
      <p>
        <ol>
          <li>
            Our vision is to seamlessly enable living with your community and
            people who follow the same beliefs as yours irrespective of the
            longitudes and latitudes you are living on.
            <br />
            <br />
          </li>
          <li>
            Help us find your communities here in Germany so that you can
            blissfully live on with your life with all the added benefits you
            get once you move to Germany.
          </li>
        </ol>
      </p>
      <h2>We value you</h2>
      <p>
        With the increasing rate in the Violence activities, we have taken steps
        upfront to help you protect your family and yourselves with our
        helplines.
        <br />
        <br />
        <ol>
          <li>We provide 24*7 Violence helplines.</li>
          <li>Your safety is our utmost priority.</li>
        </ol>
      </p>
    </>
  );
};

export default ServiceDetailsContent;
