import React from "react";

const DetailedTeam = ({ member }) => {
  return (
    <>
      <div className="team-area white-bg pt-110 pb-110">
        <div className="container">
          <div className="row">
          <div className="col-xl-1 col-lg-1"></div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-12">
              <div className="single-team-style-2 text-center">
                <div className="single-team-style-2-img single-team-member-2-img">
                  <img src={member.img} alt={member.imgAlt} />
                </div>
                <div className="single-team-content-style-2">
                  <h4>{member.heading}</h4>
                  <span>{member.position}</span>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 single-team-member-2-desc">
              <h4>{member.descriptionHeading}</h4>
              <p>{member.descriptionPara}</p>
            </div>
            <div className="col-xl-1 col-lg-1"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailedTeam;
