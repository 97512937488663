import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import rentalDepositImg from "../../assets/images/moneyTransferDetails/rentalDeposit/rental-deposit.jpg";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
// import { RentalDepositPartners } from "../../utilities/constants";
// import HowToRedeem from "../../components/service-details/moneyTransfer/currentAccount/HowToRedeem";
import RentalDepositsDetails from "../../components/service-details/moneyTransfer/rentalDeposit.js/RentalDepositsDetails";
import OtherServices from "../../components/service-details/OtherServices";
import { moneyTransferSubMenu } from "../../utilities/constants";

const RentalDeposit = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={rentalDepositImg}
        heading1={`No Need to Pay a Hefty Rental Deposit!!!`}
        // heading2={`Deposit, and`}
        // heading3={`Unlock new benefits!`}
        description="Navigating the housing market in Germany as a newcomer certainly poses difficulties."
        currentPage="rentalDeposit"
        className="health-insurance-hero"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="health-insurance-details-wrapper">
                <RentalDepositsDetails />

                {/* <HowToRedeem currentPage="rentalDeposit" /> */}
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      {/* <div className="cta-area transfer-wise-cta theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cta-style-1-wrapper text-center">
                <p>Create a bank account and avail many new benefits.</p>
                <a
                  href={RentalDepositPartners[0].affiliateLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="l-btn"
                >
                  Create Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <OtherServices
        currentPage="rentalDeposit"
        subMenu={moneyTransferSubMenu}
        heading={"Other Money Transfer Services"}
        className="money-transfer"
      />
      <FooterOne />
    </>
  );
};

export default RentalDeposit;
