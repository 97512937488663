import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaEnvelope,
  FaMedium,
  FaInstagram,
  FaLinkedin
} from "react-icons/fa";
import { servicesSubMenu } from "../../utilities/constants";
import CustomForm from "./CustomForm";

const FooterOne = () => {
const [currentYear , setCurrentYear] =  useState(getPresentYear())

function getPresentYear() {
  const currentDate = new Date();
  return currentDate.getFullYear();
}
React.useEffect(()=>{
  setCurrentYear(getPresentYear());
}, [])



  return (
    <>
      <footer>
        <div
          className="footer-top bg-property theme-bg-2 pt-110 pb-80"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget-1">
                  <div className="footer-title">
                    <h4>Contact Us</h4>
                  </div>
                  <div className="footer-text">
                    <p>
                      If you have any questions or need help, feel free to
                      contact with our team.
                    </p>
                  </div>
                  <div className="footer-info">
                    {/* <Link > */}
                     <FaEnvelope />hello@onboarding-mate.com
                    {/* </Link> */}
                  </div>
                  <div className="footer-text">
                    <p>Bahnhofstr. 13 27419 Sittensen Germany</p>
                  </div>
                  {/* <div className="footer-info">
                    <Link to="/">
                      <FaPhoneAlt /> Get Directions
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <div className="footer-widget-2">
                  <div className="footer-title">
                    <h4>Quick Links</h4>
                  </div>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                      <li>
                        <Link to="/#our-services">Our Services</Link>
                      </li>
                      <li>
                        <Link to="/about">About Company</Link>
                      </li>
                      <li>
                        <Link to="/Blog">Blogs</Link>
                      </li>
                      <li>
                        <Link to="/imprint">Imprint</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/Cookie-policy">Cookie Policy</Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <div className="footer-widget-3">
                  <div className="footer-title">
                    <h4>Our Services</h4>
                  </div>
                  <div className="footer-menu">
                    <ul>
                      {servicesSubMenu.map(
                        (service,index) =>
                          service.subNav && (
                            <li key={index}>
                                {service.path === '/insurance' ? (
                                  <Link to="/insurance/private-health">Health Insurance</Link>
                                ) : (
                                  <Link to={service.path}>{service.title}</Link>
                                )}
                                      </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget-4">
                  <div className="footer-title">
                    <h4>Newsletter</h4>
                  </div>
                  <div className="footer-text">
                    <p>Best Insurance Service Provider in Germany.</p>
                  </div>
                  <div className="footer-social mt-35">
                    <h5>Follow Us On:</h5>
                    <ul>
                      <li>
                        <Link  to="https://www.facebook.com/profile.php?id=100094217796517" target="_blank">
                          <FaFacebookF className="fIcon" />
                        </Link>
                      </li>
                    
                      <li>
                        <Link  to="https://www.instagram.com/onboarding.mate/">
                          <FaInstagram className="fIcon"  />
                        </Link>
                      </li>
                      <li>
                        <Link   to="https://www.linkedin.com/company/onboarding-mate/">
                        < FaLinkedin  className="fIcon" />
                        </Link>
                      </li>
                      <li>
                        <Link  to="https://medium.com/@onboardingmategermany">
                          <FaMedium className="fIcon"  />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="footer-newsletter mt-40">
                    <input type="text" placeholder="Email Address" />
                    <button>
                      <FaAngleDoubleRight />
                    </button>
                  </div> */}

                  <CustomForm />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom theme-bg-1 pb-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 footer-bottom-row-first">
                <div className="copyright">
                  <p>© {currentYear} Onboarding-Mate. All right reserved.</p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-xl-end text-lg-end text-md-end text-center footer-bottom-row-second">
                <div className="copyright">
                  <p>
                    Designed and maintained by{" "}
                    <a
                      href="https://aqlix.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Aqlix IT Solutions Pvt Ltd.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
