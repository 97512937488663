import React from "react";
import { FaCaretRight } from "react-icons/fa";

const CookiePolicyComponent = () => {
  return (
    <>
      <div className="about-block pt-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 pr-50">
              <h2>Cookie Policy</h2>
              <p className="imprint-info ">
                <a href="/">Onboarding Mate</a> uses browser cookies, scripts,
                web beacons, tracking URLs, pixel tags and similar technologies
                (hereinafter referred to as "cookies") to provide, protect and
                improve our website and applications. Below we explain to you
                how our cookies work, the purpose they have, the type of data
                each of them collects and how you can manage your cookie
                settings.
                <br />
                <br />
                <h3>What cookies are and how they work</h3>
                Cookies are small files that are downloaded onto the browser
                directory or the hard drive of your computer, tablet or
                smartphone (hereafter "device") when you visit our website or
                application (hereafter "platform"). Every time you visit our
                platform, cookie and platform will "communicate" in order for
                your device to be recognised. This is useful for both you and
                us. For example, a cookie will allow us to save the text entries
                you make in form fields on the website so you will not have to
                enter the same information again the next time you visit the
                website, therefore improving your experience on our platforms.
                We manage cookies through a little program called tool or tag.
                <br />
                <br />
                We use first party and third-party cookies. First party cookies
                come from our platform and send information to us only;
                third-party cookies are placed by third parties on our site and
                send information about your device to other companies that
                recognise that cookie. In most cases the information in a cookie
                is pseudonymised or anonymised because cookies generally do not
                identify you as a person, they rather identify your device. In a
                few cases, it may be possible to link certain cookies to
                personal data. We will only process such information if you give
                us your consent or if the processing is necessary for you to be
                able to use a certain service, like your onboarding-mate.com
                Profile.
                <br />
                <br />
                You can find more information about cookies at:
                <a
                  href="https://youronlinechoices.eu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.youronlinechoices.eu
                </a>
                <br />
                <br />
                <h3>Cookie settings</h3>
                Most browsers automatically allow all cookies, but you can
                decide for yourself whether we or third parties are allowed to
                save cookies on your device or not.
                <br />
                <br />
                <b>a) Consent Manager</b>
                <br />
                <br />
                Onboarding-Mate offers you a Consent Manager in the form of a
                pop-up window when you first visit our platform. In this Consent
                Manager, you may select the category of cookies that you want to
                accept. You can come back on your choices at any time by
                clicking on the link "Change privacy settings" at the bottom of
                the platform. A change in your settings will not automatically
                delete cookies. To do that, follow the instructions outlined
                below.
                <br />
                <br />
                <b>b) Browser settings</b>
                <br />
                <br />
                You can change your browser settings so that they do not accept
                or save cookies at any time. Alternatively, you can opt for a
                warning message to be shown every time before accepting cookies
                or you can set your browser up in such a way that it only
                accepts cookies from certain websites. You can also delete
                cookies that have already been saved. Deleting all cookies means
                your device will also delete any opt-out cookie and our Consent
                Manager will pop-up again next time you visit our platform. You
                may therefore have to renew your choice to opt-out, too. Below
                you find general instructions to manage cookies for the most
                popular browsers:
                <br />
                <br />
                <div>
                  <a
                    href="https://support.google.com/chrome/answer/95647?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Chrome browser
                  </a>
                  <FaCaretRight />
                </div>
                <div>
                  <a
                    href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Microsoft Internet Explorer browser
                  </a>
                  <FaCaretRight />
                </div>
                <div>
                  <a
                    href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mozilla Firefox browser
                  </a>
                  <FaCaretRight />
                </div>
                <div>
                  <a
                    href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apple Safari browser
                  </a>
                  <FaCaretRight />
                </div>
                <div>
                  <a
                    href="https://help.opera.com/en/latest/web-preferences/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Opera Web browser
                  </a>
                  <FaCaretRight />
                </div>
                <br />
                <br />
                <b>c) Cookie blockers</b>
                <br />
                <br />
                You may also employ cookies blockers such as "DoNotTrackMe".
                <br />
                <br />
                Please note that, if you decline cookies on the website, some
                functions may be limited or unavailable. As a result, we
                recommend that your browser settings accept our and our
                partners’ cookies.
                <br />
                <br />
                <h3>Cookie categories</h3>
                <b>1. Necessary cookies</b>
                <br />
                <br />
                Necessary cookies are used to manage the platform. They enable a
                smooth operation, e.g. while booking your flight, delivering the
                correct answers to your requests and ensuring security features,
                such as avoiding any malicious use of the website. Necessary
                cookies cannot be disabled but you can delete them or opt-out
                when they are signalled. The legal basis for the processing of
                these cookies is our legitimate interests pursuant to Art. 6(1),
                Subparagraph 1(f) GDPR. The base of our legitimate interest is
                to ensure the security and functionalities of this website.
                <br />
                <br />
                <b>2. Analytics cookies</b>
                <br />
                <br />
                We use these cookies to:
                <br />
                <br />
                <ol>
                  <li>
                    Monitor website traffic and optimize your user experience
                  </li>
                  <li>
                    Evaluate which marketing channels are performing better
                  </li>
                  <li>
                    Analyse aggregated data about usage of the website to
                    understand our customers. Randomized identifiers shared with
                    partners.
                  </li>
                </ol>
                <b>3. Personalisation cookies</b>
                <br />
                <br />
                We use these cookies to:
                <br />
                <br />
                <ol>
                  <li>Store your preferences from previous visits</li>
                  <li>Collect user feedback to improve our website</li>
                  <li>
                    Evaluate your interests to provide you unique customised
                    content and offers
                  </li>
                </ol>
                <b>4. Advertising cookies</b>
                <br />
                <br />
                We use these cookies to:
                <br />
                <br />
                <ol>
                  <li>
                    Make online and social advertising more relevant for you
                  </li>
                  <li>
                    Invite specific customer groups to reconnect with our
                    products later
                  </li>
                  <li>
                    Share data with our advertising and social media partners
                    via their third-party cookies to match your interests
                  </li>
                </ol>
                <h3>Changes to the Cookie policy</h3>
                We update our Cookie Policy from time to time. Please do check
                it regularly in order to be aware of the newest version.
                <br />
                <br />
                <h3>Questions?</h3>
                For more information on cookies, please contact the Data
                Protection Officer at contact@zillionpathways.de. For a more
                detailed understanding of how we use personal data in general,
                please refer to our Privacy Statement.
                <br />
                <br /> <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiePolicyComponent;
