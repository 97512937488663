import React from 'react';
import BlogStyleOne from '../../components/blog/BlogStyleOne';
import HeaderTop from '../../common/header/HeaderTop';
import HeaderBottomTwo from '../../common/header/HeaderBottomOne';
import Blog from  '../../components/blogContent/Blog';
import FooterOne from '../../common/footer/FooterOne';
const BlogContent = () => {
   
  


  return (
    <>
    
     <HeaderTop />
       <HeaderBottomTwo />
       <div className='mt-100'>
       <Blog />
       </div>
       <BlogStyleOne />
       <FooterOne />
       
       

   
    </>
  );
};


export default BlogContent;
