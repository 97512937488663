import React from "react";
import Tab from "react-bootstrap/Tab";
import { insuranceSubMenu } from "../../../utilities/constants";
import Card from "react-bootstrap/Card";

const InsuranceServices = () => {
  return (
    <>
      {/* <div className="row"> */}
      <Tab.Container defaultActiveKey="publicHealthInsurance">
        <div className="row">
          <div className="col-xl-12">
            <h2>Insurance Services</h2>
            <div className="works-menu text-center mb-50">
              {insuranceSubMenu.map((item, index) => (
                <a href={item.path} id={item.value}>
                  <Card>
                    <Card.Img
                      style={{ width: "auto", height: "105px" }}
                      alt="171x180"
                      src={item.img}
                      variant="top"
                    />
                    <Card.Body
                      className={
                        index === 1 || index === 0
                          ? "private-health-padding"
                          : ""
                      }
                      style={
                        index === 1 || index === 0
                          ? { padding: "0px 0px 8px 0px" }
                          : {}
                      }
                    >
                      {item.title}
                    </Card.Body>
                  </Card>
                </a>
              ))}
            </div>
          </div>
        </div>
      </Tab.Container>
      {/* </div> */}
    </>
  );
};

export default InsuranceServices;
