import React from 'react';

import './breadcrumb.css';

const Breadcrumb = ({ breadcrumbBg, heading, description, currentPage }) => {
    const breadcrumbStyle = {
        backgroundImage: `url(${breadcrumbBg})`,
    };

    return (
        <div className="custom-breadcrumb">
            <div className="custom-breadcrumb-container">
                <div className="custom-breadcrumb-row">
                    <div className="col-xl-12 custom-breadcrumb-content">
                        <div className="custom-breadcrumb-wrapper">
                            <h1 className="custom-breadcrumb-heading">{heading}</h1>
                            <p className="custom-breadcrumb-description">{description}</p>
                        </div>
                    </div>
                </div>
                <div className="custom-breadcrumb-row">
                    <div className="col-xl-12">
                        <nav aria-label="breadcrumb" className="custom-breadcrumb-navigation">
                            
                        </nav>
                    </div>
                </div>
            </div>
            <div
                className="custom-breadcrumb-bg"
                style={breadcrumbStyle}
            />
        </div>
    );
};

export default Breadcrumb;
