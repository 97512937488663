import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import moneyTransferBackground from "../../assets/images/moneyTransferDetails/money-transfer.jpg";
import ServiceDetailsContent from "../../components/service-details/bankAccounts/ServiceDetailsContent";
// import ServiceFaq from "../../components/service-details/ServiceFaq";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import MoneyTransferServices from "../../components/service-details/moneyTransfer/MoneyTransferServices";


const MoneyTransferDetails = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={moneyTransferBackground}
        heading1={`Money Transfer: Safe, fast, and reliable.`}
        // heading2={`transferring money`}
        // heading3={`to near and dear?`}
        description="Send money to your home country with our top-class money transfer service. Stay close, despite the distance."
        currentPage="MoneyTransfer"
      />
      <div className="service-details pt-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="service-details-wrapper">
                <MoneyTransferServices />
                <ServiceDetailsContent />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default MoneyTransferDetails;
