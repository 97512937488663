import React from "react";
import { Link } from "react-router-dom";
import {
  FaHeadphones,
  FaEnvelopeOpen,
  FaMapMarkerAlt,
  FaClock,
  FaFacebookF,
} from "react-icons/fa";
import HubspotContactForm from "../home1/HubspotContactForm";
import HeaderTop from "../../common/header/HeaderTop";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import FooterOne from "../../common/footer/FooterOne";
const ContactForm = () => {
  return (
    <>
         <HeaderTop />
         <HeaderBottomOne />
      <div className="contact-area white-bg pt-110 pb-110">
        <div className="container">
          <div className="box-shadow-3 pt-60 pb-60 pl-80 pr-80 mr-60 ml-60 contact-us-form" id="ContactUsForm">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="contact-form pr-70">
                  <div className="section-title ">
                    <span>Need Our Service Or Have Queries</span>
                    <h2>Send Us Message.</h2>
                  </div>
                 <HubspotContactForm 
                   region="eu1"
                   portalId="144261095"
                   formId="cb9c6d11-8c47-47de-9ff1-aef70a14ca86" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="contact-box theme-bg-1 pt-60 pb-35 pl-35 pr-35 mr-40">
                  <span>Guiding Excellence!</span>
                  <h3>Our Services Fits All needs!</h3>
                  <p>
                    We wish to solve all your queries as soon as possible.
                    <br />{" "}
                  </p>
                  <ul className="mt-40">
                    <li>
                      <FaHeadphones />
                      <span>
                        Emergency Line: <Link to="tel: +49 178 8355014.">+49 (178) 83550147</Link>
                      </span>
                    </li>
                    <li>
                      <FaEnvelopeOpen />
                      <span>
                        Your Email: <Link to="mailto: hello@onboarding-mate.com">hello@onboarding-mate.com</Link>
                      </span>
                    </li>
                    <li>
                      <FaMapMarkerAlt />
                      <span>Zillion Pathways GmbH, Bahnhofstr. 13, 27419 Sittensen</span>
                    </li>
                    <li>
                      <FaClock />
                      <span>Mon - Fri: 8:30a.m.  - 7:00 pm</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="social-btn-area pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 find-us-on-facebook">
              <p className="text-center">Find us on Facebook</p>
              <Link to="https://www.facebook.com/profile.php?id=100094217796517" className="l-btn facebook-button" >
               <FaFacebookF  /> facebook
              </Link>
            </div>
            {/* <div className="col-xl-3 col-lg-3 col-md-6">
                            <Link to="/" className="l-btn">Twitter</Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <Link to="/" className="l-btn">Google Plus</Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <Link to="/" className="l-btn">Pinterest</Link>
                        </div> */}
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default ContactForm;
