import React from "react";
import { Card } from "react-bootstrap";
import { BikeInsurancePartners } from "../../../../utilities/constants";

const BikeInsuranceDetails = () => {
  return (
    <>
      <h2>Why Opt for Bike Insurance?</h2>
      <p>
        <b>Statistics:</b> Every year in Germany, around 600,000 bikes are
        stolen, and sadly, only 1 out of 10 is ever recovered. With bike
        insurance, you can have peace of mind knowing you'll be covered to
        replace your stolen bike.
        <br />
        <br />
        <b>Peace of mind:</b> Bike insurance offers financial protection against
        theft, damage, and accidents, ensuring you can enjoy worry-free rides.
        <br />
        <br />
        <b>Affordable Premiums:</b>Bike insurance provides cost-effective
        coverage, offering value for money and protection for your valuable
        two-wheeler investment.
        <br />
      </p>
      <br />
      <div className="row buy-insurance-button">
        <div className="col-xl-5 col-lg-12">
          <a
            href={BikeInsurancePartners[0].affiliateLink}
            rel="noopener noreferrer"
            className="l-btn"
          >
           Consult Advisors
          </a>
        </div>
      </div>
      <br /><br />
      <h2>Our Partners</h2>
      <div className="health-insurance-partners current-account-partners">
        {BikeInsurancePartners.map((partner) => (
          <a
            href={partner.affiliateLink}
            rel="noopener noreferrer"
          >
            <Card>
              <Card.Img variant="top" src={partner.img} />
            </Card>
          </a>
        ))}
      </div>{" "}
    </>
  );
};

export default BikeInsuranceDetails;
