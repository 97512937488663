import React from "react";
import HeaderTop from "../../common/header/HeaderTop";
import transferWiseImg from "../../assets/images/moneyTransferDetails/transferWise/transfer-wise.jpg";
import HeaderBottomOne from "../../common/header/HeaderBottomOne";
import HeroService from "../../components/hero/HeroServices";
import FooterOne from "../../common/footer/FooterOne";
import {
  TYPES_OF_FAQS,
  TransferWisePartners,
  moneyTransferSubMenu,
} from "../../utilities/constants";
import TransferWiseDetails from "../../components/service-details/moneyTransfer/transferWise/TransferWiseDetails";
import HowIsItBetter from "../../components/service-details/moneyTransfer/transferWise/HowIsItBetter";
import OtherServices from "../../components/service-details/OtherServices";
import ProjectFaq from "../../components/project-details/ProjectFaq";

const TransferWise = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      <HeroService
        breadcrumbBg={transferWiseImg}
        heading1={`Save on International Money Transfers`}
        // heading2={`Worldwide`}
        // heading3={`On a button click`}
        description="Save when you send worldwide."
        currentPage="transferWise"
        className="health-insurance-hero"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <div className="health-insurance-details-wrapper">
                <TransferWiseDetails />

                <HowIsItBetter currentPage="publicHealthInsurance" />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <div className="cta-area transfer-wise-cta theme-bg-1 pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="cta-style-1-wrapper text-center">
                {/* <span>The Company</span> */}
                {/* <h2>We invested our hard work for people just like you that <br /> insist on a great user experience.</h2> */}
                <p>
                  Transferring money to your loved ones is just a click away
                </p>
                <a
                  href={TransferWisePartners[0].affiliateLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="l-btn"
                >
                  Send money
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works pt-110 pb-110 faq-section-row">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-50">
                <h2>FAQs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-1 col-lg-1 pr-50"></div>
            <div className="col-xl-10 col-lg-10">
              <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />
            </div>
            <div className="col-xl-1 col-lg-1 pr-50"></div>
          </div>
        </div>
      </div>
      <OtherServices
        currentPage="transferWise"
        subMenu={moneyTransferSubMenu}
        heading={"Other Money Transfer Services"}
        className="money-transfer"
      />
      <FooterOne />
    </>
  );
};

export default TransferWise;
