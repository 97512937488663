import { v4 as uuidv4 } from 'uuid';
import JointFinanceImg from '../assets/images/insuranceDetails/jointFinanceImg.png';
import Insurance from '../assets/images/service/insurance.png';
import CurrentAccount from '../assets/images/moneyTransferDetails/current-account.png';
import TransferWise from '../assets/images/moneyTransferDetails/money-transfer-icon.png';
import RentalDeposit from '../assets/images/moneyTransferDetails/rental.png';
import PublicHealthInsurance from '../assets/images/insuranceDetails/public-health-insurance.png';
import PrivateHealth from '../assets/images/insuranceDetails/private-health-logo.png';
import LiabilityInsurance from '../assets/images/insuranceDetails/liability-insurance.png';
import HouseholdInsurance from '../assets/images/insuranceDetails/household-insurance.png';
import LegalInsurance from '../assets/images/insuranceDetails/legal-insurance.png';
import ExpatInsurance from '../assets/images/insuranceDetails/expat-insurance.png';
import DentalInsurance from '../assets/images/insuranceDetails/dental-insurance.png';
import LifeInsurance from '../assets/images/insuranceDetails/life-insurance.png';
import DisabilityInsurance from '../assets/images/insuranceDetails/disability-insurance.png';
import BikeInsurance from '../assets/images/insuranceDetails/bike-insurance.png';
import Transfer from '../assets/images/service/transfer.png';
import PhoneInternet from '../assets/images/service/phoneinternet.png';
import TaxReturn from '../assets/images/service/tax-return.png';
import LearnGerman from '../assets/images/service/learn-german.png';
import Orientation from '../assets/images/service/orientation.png';
import ViolenceHelpline from '../assets/images/service/violence-helpline-logo.png';
import FaithAndReligion from '../assets/images/service/faith-and-religion-logo.png';
import Immigration from '../assets/images/service/immigration.png';
import HouseHome from '../assets/images/service/house-home.png';
import FamilyPets from '../assets/images/service/family-and-pets.png';
import SettlingIn from '../assets/images/service/settling-in-moving-out.png';
// import InterculturalTraining from "../assets/images/service/intercultural-training.png";
import ExpenseManagement from '../assets/images/service/expense-management.png';
import Marc from '../assets/images/about/marc.jpeg';
import Alex from '../assets/images/about/alex.jpeg';
import Pankaj from "../assets/images/about/pankaj.jpg";

// import Pascal from "../assets/images/insuranceDetails/privateHealth/pascal.jpg";
import Pascal from '../assets/images/insuranceDetails/privateHealth/pascel-new-bg.avif';
import Marvin from '../assets/images/insuranceDetails/privateHealth/MarvinPFP.avif';
import ProfilePascal from '../assets/images/insuranceDetails/privateHealth/ProfilePascal-2.avif';
import ProfileAlicia from '../assets/images/insuranceDetails/privateHealth/ProfileAlicia.avif';
import PascalCertificate from '../assets/images/insuranceDetails/privateHealth/pascal-certificate.avif';
import AliciaCertificate from '../assets/images/insuranceDetails/privateHealth/alicia-certificate.avif';
import AliciaCertificate1 from '../assets/images/insuranceDetails/privateHealth/alicia-certificate-2.avif';
import FaithReligionImg from '../assets/images/councilAndFaith/faithAndReligion/faith-and-religion.jpg';
import ViolenceHelplineImg from '../assets/images/councilAndFaith/violenceHelpline/violence-helpline.jpg';
import OrientationImg from '../assets/images/oneServiceStop/orientation.jpg';
import ImmigrationImg from '../assets/images/oneServiceStop/immigration.jpg';
import ExpenseManagementImg from '../assets/images/oneServiceStop/expense-management.jpg';
import FamilyPetsImg from '../assets/images/oneServiceStop/family-and-pets.jpg';
import HouseHomeImg from '../assets/images/oneServiceStop/house-and-home.jpg';
import SettlingInImg from '../assets/images/oneServiceStop/settling-in-moving-out.jpg';
import newAlica from "../assets/images/insuranceDetails/privateHealth/newAlica.avif"
import Tobias from "../assets/images/insuranceDetails/privateHealth/TobiasAVIF.avif"
import ProjectFaq from '../components/project-details/ProjectFaq';
import { CgChevronDown, CgChevronRight } from 'react-icons/cg';
import BarmerImg from '../assets/images/insuranceDetails/publicHealthInsurance/barmer.svg';
import WiseImg from '../assets/images/moneyTransferDetails/transferWise/wise.svg';
import RevolutImg from '../assets/images/moneyTransferDetails/currentAccount/revolut.svg';
import PHI1 from '../assets/images/PHI1.avif';
import PHI2 from '../assets/images/PHI2.webp';
import PHI3 from '../assets/images/PHI3.avif';
import PHI4 from '../assets/images/PHI4.avif';
import PHI5 from '../assets/images/PHI5.avif';
import PHI6 from "../assets/images/PHI6.webp";
import PHI7 from "../assets/images/PHI7.jpg";
import PHI8 from "../assets/images/PHI8.png";
import PHI9 from "../assets/images/PHI9.jpg";


export const AppointmentLink = {
  live : "https://onboarding-mate.com/appointment/book",
  local : "http://localhost:3000/appointment/book"
}
export const BlogContent = [
  {
    id: 1,
    title: 'Secure Your Health, Your Way ',
    content: [
      {
        type: 'heading',
        text: 'Why Choose Private Health Insurance?',
      },
      {
        type: 'subheading',
        text: '1. Tailored Coverage:',
      },
      {
        type: 'paragraph',
        text: ' Say goodbye to one-size-fits-all policies. Private health insurance allows you to customize your coverage to meet your unique needs and priorities. You decide what’s important for your health and get the coverage you deserve.',
      },
      {
        type: 'subheading',
        text: '2. Fast-Track Access:',
      },
      {
        type: 'paragraph',
        text: 'Tired of long wait times? With private insurance, you’ll enjoy faster access to medical specialists, cutting-edge treatments, and state-of-the-art facilities. Your health is your top priority, and we make sure you get the care you need when you need it. ',
      },
      {
        type: 'subheading',
        text: '3. World-Class Care:',
      },
      {
        type: 'paragraph',
        text: 'Germany is renowned for its healthcare excellence. Private insurance lets you tap into a network of top-tier hospitals and physicians, ensuring you receive the highest standard of medical care available. ',
      },
      {
        type: 'subheading',
        text: '4. Peace of Mind: ',
      },
      {
        type: 'paragraph',
        text: 'Unexpected health issues can happen at any time. Private health insurance provides the peace of mind knowing that you and your loved ones are financially protected in case of illness or injury. Focus on recovery, not on medical bills. ',
      },
      {
        type: 'heading',
        text: 'Who Can Benefit?',
      },
      {
        type: 'paragraph',
        text: 'Walkways, Patios, and Driveways: Your garden design is incomplete without well-planned pathways, patios, and driveways. These hardscape elements not only enhance the functionality of your outdoor space but also contribute to its overall aesthetics. Whether you prefer a rustic stone pathway or a modern concrete patio, careful design and selection of materials are crucial for achieving the desired look and durability- Individuals and families looking for personalized healthcare solutions. ',
      },

      {
        type: 'paragraph',
        text: '- Freelancers and self-employed professionals seeking coverage tailored to their needs.',
      },
      {
        type: 'paragraph',
        text: '- Expats in Germany wanting access to premium healthcare services.',
      },
      {
        type: 'paragraph',
        text: '- Anyone tired of long wait times and seeking quicker medical attention.',
      },
      {
        type: 'heading',
        text: 'We Make It Simple',
      },
      {
        type: 'paragraph',
        text: 'Navigating the world of insurance can be complex, but we’re here to simplify the process for you. Our experienced team is dedicated to helping you find the perfect private health insurance plan that fits your lifestyle and budget.',
      },
      {
        type: 'heading',
        text: 'Your Health, Our Priority',
      },
      {
        type: 'paragraph',
        text: 'At OnBoarding Mate, we prioritize your health and well-being above all else. We are committed to providing you with the best private health insurance options, outstanding customer service, and peace of mind.',
      },
      {
        type: 'paragraph',
        text: 'Contact Us Today!',
      },
      {
        type: 'paragraph',
        text: 'Ready to take the next step towards a healthier, worry-free future? Contact us today to discuss your private health insurance options. Your health deserves the best, and we’re here to make it happen!',
      },

      {
        type: 'link',
        text: 'https://onboarding-mate.com/',
      },
      {
        type: 'paragraph',
        text: 'Invest in your health, invest in your future. Choose Private Health Insurance with OnBoarding Mate today!',
      },
    ],
  },
  {
    id: 2,
    title: 'Reliable Private Health Coverage in Germany',
    content: [
      {
        type: 'image',
        url: PHI2,
      },
      {
        type: 'paragraph',
        text: 'In Germany, private health insurance (Private Krankenversicherung or PKV) is an option available alongside the public health insurance system (Gesetzliche Krankenversicherung or GKV). Here are some reasons why individuals might choose to buy private health insurance in Germany: ',
      },
      {
        type: 'subheading',
        text: '1. Income and Occupation',
      },
      {
        type: 'paragraph',
        text: 'Private health insurance is available to individuals with higher incomes (above a certain threshold) and self-employed individuals. If your income exceeds this threshold, you can opt for private insurance, which may offer more comprehensive coverage.',
      },
      {
        type: 'subheading',
        text: '2. Customized Coverage',
      },
      {
        type: 'paragraph',
        text: 'Private health insurance plans in Germany allow for more customization. You can choose your level of coverage, including specific treatments, doctors, and hospitals. This can be appealing if you want a tailored healthcare experience.',
      },
      {
        type: 'subheading',
        text: '3. Faster Access to Care',
      },
      {
        type: 'paragraph',
        text: 'Private insurance often provides faster access to medical services, specialists, and surgeries. You can skip the waiting lists that can be a part of the public health system.',
      },
      {
        type: 'subheading',
        text: '4. Choice of Doctors and Hospitals',
      },
      {
        type: 'paragraph',
        text: 'With private insurance, you can choose your preferred doctors and hospitals without restrictions. This can be especially important if you have specific healthcare preferences or if you want to see renowned specialists.',
      },
      {
        type: 'subheading',
        text: '5. Additional Benefits',
      },
      {
        type: 'paragraph',
        text: 'Private health insurance plans may offer additional benefits, such as coverage for alternative therapies, dental care, and vision care, which may not be as comprehensive in the public system.',
      },
      {
        type: 'subheading',
        text: '6. Long-Term Premium Stability',
      },
      {
        type: 'paragraph',
        text: ' Premiums for private health insurance are typically based on your age when you first sign up. If you join at a younger age, your premiums may remain stable for a longer period, making it a potentially cost-effective option over time.',
      },
      {
        type: 'subheading',
        text: '7. Family Coverage',
      },
      {
        type: 'paragraph',
        text: ' Private health insurance can sometimes be more cost-effective for families, especially if one family member has a higher income and can cover the entire family under one policy.',
      },
      {
        type: 'subheading',
        text: '8. Expat or Non-German Residents',
      },
      {
        type: 'paragraph',
        text: ' Expatriates and non-German residents may find private health insurance more suitable because they may not qualify for the public health insurance system.',
      },
      {
        type: 'subheading',
        text: '9. Choice of Deductibles',
      },
      {
        type: 'paragraph',
        text: ' Private health insurance allows you to choose deductibles and co-payments, which can affect your monthly premium. This flexibility can help you manage your healthcare costs.',
      },
      {
        type: 'subheading',
        text: '10. Continuation of Coverage',
      },
      {
        type: 'paragraph',
        text: ' If you switch from private to public health insurance and then want to return to private insurance, it can be challenging. Staying with private insurance can provide continuity in coverage.',
      },
      {
        type: 'paragraph',
        text: ' Before deciding whether to buy private health insurance in Germany, it’s advisable to carefully assess your personal circumstances, healthcare needs, and financial situation. Consulting with an insurance advisor or broker can also be helpful in making an informed decision. For more information you can visit on this page',
      },
      {
        type: 'link',
        text: 'https://onboarding-mate.com/insurance/private-health',
      },
    ],
  },
  {
    id: 3,
    title: 'Learning Health Options: Highlighting the Beneficial',
    content: [
      {
        type: 'heading',
        text: 'Features of Private Health Insurance',
      },
      {
        type: 'image',
        url: PHI3,
      },
      {
        type: 'paragraph',
        text: "Among the many options for heathcare, Private Healthcare Insurance stands outas an appealing alternative. Let's examine why it makes sense for your overall health :-",
      },
      {
        type: 'subheading',
        text: 'Your Health, Your Priority',
      },
      {
        type: 'paragraph',
        text: "You are the VIP when it comes to private health insurance. It all comes down to providing individualized treatment that is specific to your requirements. You no longer have to wait for your turn to receive excellent medical care; quick access is the standard. It's that simple: your health is your top priority.",
      },
      {
        type: 'subheading',
        text: 'The One-Stop Health Protection',
      },
      {
        type: 'paragraph',
        text: 'Consider this: a safety net for health care that includes everything from basic examinations to advanced medical care. No coverage is compromised by private health insurance. It serves as your all-in-one wellness barrier, keeping you safe from all directions. Why accept less when it comes to your health?',
      },
      {
        type: 'subheading',
        text: 'You Call, Doctor, Hospital',
      },
      {
        type: 'paragraph',
        text: "Say goodbye for receiving direction offered to you. You get to make the decisions when you have private health insurance. It's your health, so you have the power to select the doctors and hospitals you want to use. The ability to make your own decisions changes everything.",
      },
      {
        type: 'subheading',
        text: 'Just Healing—No Waiting',
      },
      {
        type: 'paragraph',
        text: 'Particularly when it comes to health, time is of the essence. This is recognized by private health insurance. Are there any waiting periods for tests, operations, or appointments? Slim to none. The main goal is to provide you with the service you require without making you wait needlessly. Since every moment  matters when it comes to health.',
      },
      {
        type: 'subheading',
        text: 'Above & Beyond: Additional Benefits',
      },
      {
        type: 'paragraph',
        text: 'Beyond the basics, private health insurance is available. Consider coverage for alternative therapies, dental care, and vision services. Even wellness programs are included in some plans. Maintaining your best health is more important than just solving difficulties. Added advantages for being in better health.',
      },
      {
        type: 'subheading',
        text: 'Cost-Effective Calmness',
      },
      {
        type: 'paragraph',
        text: "Fearing the price? You can trust private health insurance. It serves as a safety net for finances, lessening the effects of unforeseen expenses. The best part? There are numerous ways to pay. Affordable, high-quality healthcare is important because being well shouldn't be exceedingly costly.",
      },
      {
        type: 'subheading',
        text: 'In a Summary,',
      },
      {
        type: 'paragraph',
        text: "The wise decision is to sign up for private health insurance, not just the option. It checks every box with its emphasis on individualized care, all-inclusive coverage, freedom of choice, speedy access, extra benefits, and affordable solutions. Private health insurance makes the process easier in a world where choosing health care can be difficult. It's more than just insurance, it's your pass to a safer and healthier future. Pick wisely and get private health insurance. You should prioritize your health.",
      },
      {
        type: 'subheading',
        text: 'Ready to Transform Your Health Journey?',
      },
      {
        type: 'paragraph',
        text: "You should provide your health the best possible care and attention. At OnBoarding Mate, we provide more than simply private health insurance—we also offer a collaborative approach to your medical path. Get in touch with us now, and together, let's set out on a vital journey. We our here to help you achieve the healthier, worry-free future you deserve.",
      },
    ],
  },
  {
    id: 4,
    title: 'Learning Health Options: Highlighting the Beneficial',
    content: [
      {
        type: 'image',
        url: PHI4,
      },
      {
        type: 'paragraph',
        text: 'Private health insurance is a bright thread in Germany’s healthcare system, weaving a tale of ease, all-inclusive coverage, and individualized attention. Let us explore the reasons why opting for private health insurance in Germany could be the way to a happier, healthier life.',
      },
      {
        type: 'subheading',
        text: '1. All-inclusive Protection Above and Beyond Public Insurance',
      },
      {
        type: 'paragraph',
        text: 'Imagine receiving a wide range of benefits personalized to your specific lifestyle from a health insurance plan that offers more than just the essentials. In Germany, private health insurance includes benefits like dental and vision treatment that are not available through public insurance. It’s similar to upgrading to a premium plan in that you can be sure that all of your needs are met.',
      },
      {
        type: 'subheading',
        text: '2. Faster Healthcare Access',
      },
      {
        type: 'paragraph',
        text: 'With its guarantee of quicker appointment times, private health insurance in Germany stands out in a world where time is of the importance. Imagine having private appointments with both general practitioners and specialists all to yourself. Long wait times and crowded waiting areas are over. Your health is never jeopardized because you receive the priority care you are entitled to with private insurance.',
      },
      {
        type: 'subheading',
        text: '3. Professional Counsellor’s at Your Assistance',
      },
      {
        type: 'paragraph',
        text: 'It can be very difficult to navigate the complexities of health insurance, particularly for foreign nationals. This is the role that our group of knowledgeable consultants plays. Let me introduce you to Pascal Winter, an experienced insurance broker with 15 years of experience who specializes in offering Indian expats specialized services. As part of her commitment to help foreigners living in Germany, Alicia Aswani concentrates on insurance and financial planning. They work as an integrated team to ensure a simple and hassle-free insurance experience for you.',
      },
      {
        type: 'subheading',
        text: '4. Onboarding Mate: Your Digital Companion',
      },
      {
        type: 'paragraph',
        text: 'It has never been easier to start your health insurance journey than it is now. From the beginning to the end, a hassle-free journey is guaranteed with our digital companion, Onboarding Mate. With only a few clicks on our user-friendly web site, you can easily manage your policy by updating details and submitting claims. Do you require help? Our team of English-speaking support agents is ready to answer your questions via email or video call at any time of the day.',
      },
      {
        type: 'subheading',
        text: '5. Personalized Coverage for Your Specific Requirements',
      },
      {
        type: 'paragraph',
        text: 'We are aware that when it comes to health insurance, one size does not fit all. Our plans are made with flexibility in mind because of this. With our wide range of options and add-ons, you may customize your coverage to meet your unique needs. You are in charge with this customized approach to insurance — your health, your rules.',
      },
      {
        type: 'subheading',
        text: '6. Simplified Management with a Single Insurance Dashboard',
      },
      {
        type: 'paragraph',
        text: 'With the simpler insurance dashboard, you always have your insurance policies with you, anytime and anywhere on your smartphone. You have security at your fingertips by keeping your contracts and documents with you at all times. With just a click, you can manage, compare, conclude, and terminate your contracts on your own, and if needed, involve your trusted insurance broker.',
      },
      {
        type: 'paragraph',
        text: 'Make the next wise choice by contacting-',
      },
      {
        type: 'link',
        text: 'OnBoarding Mate Right Now!',
      },
      // {
      //   "type" :""
      //   "text" :
      // }
    ],
  },
  {
    id: 5,
    title:
      'Comprehensive Guide for Indian Expats Moving to Germany: Prioritizing Health',
    content: [
      {
        type: 'image',
        url: PHI5,
      },
      {
        type: 'paragraph',
        text: 'Lately, Indian skilled professionals are increasingly exploring global opportunities thanks to globalization in services. While there are numerous countries with ample opportunities,Germany stands out as a prime destination for career growth as it offers unparalleled opportunities for prosperity',
      },
      {
        type: 'paragraph',
        text: 'However, the excitement of relocating to a new country also requires you to prioritize health and well-being for numerous reasons. For Indian expats moving to Germany for work, securing private health insurance becomes not only mandatory as per the compliance requirements but also for ensuring stress-free growth through comprehensive healthcare coverage',
      },
      {
        type: 'heading',
        text: 'About German Healthcare System:',
      },
      {
        type: 'paragraph',
        text: "Germany being a developed economy offers one of the world's most renowned healthcare systems, characterized by high-quality medical care and universal coverage. While in India, where healthcare insurance is covered prominently by public institutions, Germany ensures wide coverage through both public and private healthcare. One on side there is a system of statutory health insurance (Gesetzliche Krankenversicherung, or GKV), and on the other side private health insurance (Private Krankenversicherung, or PKV) based on the income structure. While those earning below a certain income threshold are required to enroll in the statutory health insurance scheme, high-earning professionals including many Indian professionals, have the option to choose private health insurance which offers its own advantages.",
      },
      {
        type: 'heading',
        text: 'Why go for Private Health Insurance?',
      },
      {
        type: 'paragraph',
        text: 'For Indian expats relocating to Germany for work, private health insurance offers several advantages which makes it a necessity more than the choice. It provides access to a wider range of healthcare services, including private hospitals, specialist treatments, and elective procedures. Moreover, private health insurance often offers shorter appointment waiting times and greater flexibility in choosing healthcare providers which becomes a boon for professionals engaged in work. Such advantages prove to be beneficial for expats who prefer English-speaking doctors for better communication or require specialized medical care tailored to their needs.',
      },
      {
        type: 'heading',
        text: 'Enrollment Process:',
      },
      {
        type: 'paragraph',
        text: 'While navigating through the complexities of the German healthcare system can be daunting for newcomers but getting the right guidance makes the process easy to understand and proceed with. Indian expats who are and those planning to move to Germany should look for tailored private health insurance options well in advance of their arrival. In such scenarios, consulting an experienced insurance provider that understands the onboarding requirements and needs of the expats ensures the best coverage options and selection of a policy that suits their needs and budget.',
      },
      {
        type: 'heading',
        text: ' How to Choose the Right Coverage?',
      },
      {
        type: 'paragraph',
        text: 'When you are searching and selecting private health insurance coverage, you should consider various factors including comprehensive medical benefits, coverage for pre-existing conditions, international travel assistance, and add-on services offered. It is also essential to review policy exclusions, deductibles, and co-payments to ensure a clear understanding of the terms and conditions to avoid any hassle. ',
      },
      {
        type: 'heading',
        text: 'Looking for Onboarding Experts:',
      },
      {
        type: 'paragraph',
        text: 'To navigate the complexities of relocating to Germany, partnering with onboarding experts can help you streamline the process and eliminate potential challenges. Organizations that specialize in expatriate relocation services, such as Onboarding Mate might make a big difference. By leveraging the expertise of such onboarding professionals, you can embark on your German adventure with confidence and peace of mind and achieve your desired professional growth.',
      },
      {
        type: 'heading',
        text: 'Conclusion:',
      },
      {
        type: 'paragraph',
        text: 'While relocating to Germany for work presents exciting opportunities for Indian professionals, it is of utmost importance to prioritize health and well-being for the numerous reasons discussed above. Private health insurance will not only fulfill your legal requirements but also ensure access to high-quality healthcare services tailored to your specific needs.',
      },
      {
        type: 'paragraph',
        text: 'By understanding the basics of the German healthcare system, researching the best insurance options, and seeking guidance from onboarding experts like Onboarding Mate, you can navigate the transition of moving to Germany with ease. If this article has intrigued you to know better about private healthcare in Germany and choose the best plan, click ',
      },
      {
        type: 'link',
        text: 'https://onboarding-mate.com/insurance/private-health',
      },
    ],
  },
    {
      id : 6,
      title: "How to obtain private health insurance in Germany?",
      content: [
        {
          type: "image",
          url: PHI6,
        },
        {
          type: "paragraph",
          text: "Since the advent of the German Healthcare system, there have been numerous changes till now but principles of solidarity, self-governance have always been an integral part of the system. As per Encyclopedia Britannica, the world’s first social insurance was established in Germany in 1883 by Otto Von through the Bismarch’s Health Insurance Act. During its establishment, the imperative goal of insurance was confined majorly to the workers involved in industrial and non-industrial workers.",
        },
        {
          type: "paragraph",
          text: "Fast forward to today, the scope of health insurance has penetrated every sector becoming an essential part of life. In Germany, the plans to cover each individual have led to a comprehensive approach by the government by allowing the options of both Public and Private Health Insurance solutions.",
        },
        {
          type: "paragraph",
          text: "In this blog, we will help you with a guide to obtaining various parameters or checks required for getting the best Private Healthcare Solution.",
        },
        {
          type: "heading",
          text: "Private Health Insurance in Germany",
        },
        {
          type: "paragraph",
          text: "Private Healthcare insurance differs from Public Healthcare in several ways. Subscribing to Private Healthcare may offer additional benefits of coverage, flexibility, and access to a broader services network of services and healthcare providers. Such benefits can be crucial, especially for the people moving to Germany to explore and tap opportunities in their professional careers. So irrespective of your working sector, one must explore the perks of private Health Insurance in Germany.",
        },
        {
          type: "heading",
          text: "Eligibility Criteria for Private Health Insurance",
        },
  
        {
          type: "paragraph",
          text: "To avail of the benefits of Private Health Insurance in Germany one must belong to the following mentioned groups:",
        },
        {
          type: "heading",
          text: "Self-Employed Individuals:",
        },
        {
          type: "paragraph",
          text: "This section includes all the self-employed categories including freelancers and business owners.",
        },
        {
          type: "heading",
          text: "Students:",
        },
        {
          type: "paragraph",
          text: "Any student above the age of 30 who has enrolled in any preparatory and language courses has to acquire private health insurance.",
        },
        {
          type: "heading",
          text: "Civil Servants:",
        },
        {
          type: "paragraph",
          text: "Specific Civil Servants in Germany are covered by a special type of Healthcare plan known as a Beihilfe which covers 50% of their healthcare expenses while the remaining is covered by private health insurance.",
        },
        {
          type: "heading",
          text: "High-income earners:",
        },
        {
          type: "paragraph",
          text: "Individuals above the threshold income defined by the concerned authorities have to opt for private healthcare insurance. The current year threshold limit is set to 69,300 Euros.",
        },
        {
          type: "heading",
          text: "Signing Up for a Private Health Insurance in Germany:",
        },
        {
          type: "paragraph",
          text: "To sign up for German Private Healthcare Insurance you need to follow several steps. You should keep in mind that Private health insurance can be availed by certain groups of people as discussed above.",
        },
        {
          type: "paragraph",
          text: "Here are the steps you need to follow to avail Private Healthcare Insurance and the numerous benefits associated with it:",
        },
        {
          type: "heading",
          text: "1. Eligibility Check:",
        },
        {
          type: "paragraph",
          text: "The primate task is to ensure that the eligibility criteria as private insurance is often available to self-employed individuals, high-income earners, and specific professional groups.",
        },
        {
          type: "heading",
          text: "2. Income Verification:",
        },
        {
          type: "paragraph",
          text: "Most private Healthcare Insurance plans are income-dependent. You might need to provide income proof by submitting documents like tax returns and payslips to demonstrate your financial eligibility.",
        },
        {
          type: "heading",
          text: "3. Choose an Insurance Provider:",
        },
        {
          type: "paragraph",
          text: "One of the crucial tasks that can make a big difference is to look for experienced private health insurance providers who have immense knowledge and offer tailored plans according to your needs. Compare different plans, coverage options, and premiums to find the one that best suits your needs.",
        },
        {
          type: "heading",
          text: "4. Application Process:",
        },
        {
          type: "paragraph",
          text: "To get into the application process you must be ready with your personal information, medical history, and income details not just to check the validity but also to get a better knowledge of what plans might suit you best based on your provided details.",
        },
        {
          type: "heading",
          text: "5. Medical Examination:",
        },
        {
          type: "paragraph",
          text: "You might also need to go medical examination to check your latest health status as private insurers often require this assessment. You may be allotted a medical professional or asked to visit a designated healthcare facility to conduct a medical examination.",
        },
        {
          type: "heading",
          text: "6. Wait for Approval:",
        },
        {
          type: "paragraph",
          text: " Once you have submitted your application and completed all the necessary medical examinations, insurance will review your detailed information and take approval action accordingly.",
        },
        {
          type: "heading",
          text: "7. Review Policy Details:",
        },
        {
          type: "paragraph",
          text: " Once approved and selected by you among the various plans as provided by the private healthcare insurance provider, you will receive detailed information about your private health insurance policy including coverage, premiums, and any related terms and conditions.",
        },
        {
          type: "paragraph",
          text: " With these easy steps, you can remain assured of your health which is of utmost importance when you looking to excel in your professional growth. This becomes even more important for expats such as Indian expats moving to Germany for professional growth as you can simply focus on your career growth when you know your health will be taken care of in the best way possible.",
        },
        {
          type: "heading",
          text: "Concluding:",
        },
        {
          type: "paragraph",
          text: "Choosing the right insurance provider demands careful consideration and research. It is crucial to weigh various plans, coverage options, and premiums, ensuring that the policy you chose aligns seamlessly with your requirements.",
        },
        {
          type: "paragraph",
          text: "The pursuit of private health insurance in Germany is not just a simple transaction but it has a far-reaching impact as it commits to your well-being through tailored healthcare. By following the above-mentioned steps and connecting with the right private health insurance providers such as https://onboarding-mate.com/insurance/private-health you can navigate the process with confidence and secure a tailored health private health insurance plan aligning with your specific needs which results in your peace of mind and further professional growth.",
        },
      ]
    },
    {
      id : 7,
      title : "Private Health Insurance for Indian Expats- Benefits & Tips to Find Best Service Providers",
      content : [
        {
          type: "image",
          url: PHI7,
        },
        {
          type: "paragraph",
          text: "Germany is a dynamic nation abundant with opportunities to excel. Its socio-economic structure attracts people from several other countries and cultures. The varied needs of German citizens- local as well as Indian expats are served well through the excellent medical healthcare system that exists in the nation.",
        },
        {
          type: "paragraph",
          text: "In case you are an Indian expat in Germany, you must equip yourself with the structure of its healthcare system and medical insurance.",
        },
        {
          type: "heading",
          text: "Health Insurance in Germany- An Overview",
        },
        {
          type: "paragraph",
          text: "Health insurance has been a legal requirement for all German citizens since 2009. All residents are required to obtain health insurance, whether it be private or public, under the Kaiserversicherungspflichtgesetz, which is also referred to as the Health Insurance Compulsory Law.",
        },
        {
          type: "paragraph",
          text: "As a German citizen or even one who has moved to Germany recently, you can choose between commercial and public health insurance companies. The healthcare insurance system in Germany, which is founded on the idea of solidarity, aspires to provide access to healthcare for all to ensure their well-being all the time. While Public Health insurance covers 90% of the medical needs of the population and is primarily income-based, Private Health Insurance facilitates unparalleled benefits.",
        },
        {
          type: "paragraph",
          text: "The Private Health Insurance sector is opted for by individuals with higher income and offers more comprehensive coverage than the public health insurance sector.",
        },
        {
          type: "heading",
          text: "Private Health Insurance System in Germany",
        },
        {
          type: "paragraph",
          text: "In Germany, private health insurance is available in addition to Germany’s public healthcare system. In Germany, medical needs for self-employed people, high earners, foreign nationals, and professionals from particular groups are covered by private health insurance. In Germany, private health insurance is superior to state health insurance in terms of plan customization, provider network expansion, and waiting times which are significantly less.",
        },
        {
          type: "heading",
          text: "Benefits of Private Health Insurance in Germany",
        },
        {
          type: "paragraph",
          text: "Individuals and Indian expats can avail the benefits of Private Health Insurance that the medical system in Germany has to offer:",
        },
        {
          type: "heading",
          text: "● Inpatient and outpatient care:",
        },
        {
          type: "paragraph",
          text: "Private health insurance covers expenses concerning hospital stays including accommodation, surgeries, and consultation with experts. It also covers outpatient treatments such as X-rays and procedures outside of the hospital.",
        },
        {
          type: "heading",
          text: "● Specific medications and prescription: ",
        },
        {
          type: "paragraph",
          text: "Private Health insurance in Germany also covers the costs of specific medications and prescriptions that have particular policy limits and conditions.",
        },
  
        {
          type: "heading",
          text: "● Customized care:",
        },
        {
          type: "paragraph",
          text: "The plans offered by Private Health Insurance can be personalized and customized to meet the specific medical needs of individuals. Tailored programs and preventive screenings can be included in the customized plans.",
        },
        {
          type: "heading",
          text: "● Improved services:",
        },
        {
          type: "paragraph",
          text: "Private Health Insurance in Germany provides the benefit of more comfortable and convenient healthcare access. Expenditure of private hospital rooms, appointments, and concierge services are covered.",
        },
        {
          type: "heading",
          text: "● Coverage for Expats and High-Income Earners:",
        },
        {
          type: "paragraph",
          text: "Indian expats who have income above the statutory health insurance income threshold can benefit from a private health insurance structure.",
        },
        {
          type: "heading",
          text: "● Long-Term Stability:",
        },
        {
          type: "paragraph",
          text: "Private health insurance offers financial stability and continuity of coverage for a longer period. This provides peace of mind to policyholders and their families.",
        },
        {
          type: "paragraph",
          text: "Private Health Insurance in Germany offers a greater spectrum of medical services to choose from to suit specific needs and preferences.",
        },
        {
          type: "heading",
          text: "Finding the best providers of Private Health Insurance in Germany",
        },
        {
          type: "paragraph",
          text: "Picking a Private Health insurance provider in Germany can seem overwhelming. Here are a few tips to help you navigate your choices better:",
        },
        {
          type: "heading",
          text: "● Assess your needs:",
        },
        {
          type: "paragraph",
          text: "The first step before looking for private healthcare insurance is to understand your medical needs. Several considerations such as budget, coverage requirements, or the need for preferred specialists for specific medical conditions have to be considered.",
        },
        {
          type: "heading",
          text: "● Conduct Research:",
        },
        {
          type: "paragraph",
          text: "Conduct thorough research on private health insurance providers in Germany. Consider their reputation, financial stability, and customer ratings.",
        },
        {
          type: "heading",
          text: "● Compare your options:",
        },
        {
          type: "paragraph",
          text: "Post research compares coverage options offered by several insurance companies. Compare the spectrum of services offered by them on the grounds of outpatient and inpatient care, alternative medicine, dental care, etc.",
        },
        {
          type: "heading",
          text: "● Consider customer services:",
        },
        {
          type: "paragraph",
          text: "Assess the quality of customer service provided by these companies. Analyze their ease of communication, degree of responsiveness to inquiries, and level of assistance.",
        },
        {
          type: "heading",
          text: "● Seek advice and recommendation:",
        },
        {
          type: "paragraph",
          text: "Consult with friends, relatives, or coworkers who have firsthand knowledge of German private health insurance. Their observations can offer insightful direction for your decision-making.",
        },
        {
          type: "heading",
          text: "Bottom Line",
        },
        {
          type: "paragraph",
          text: "The healthcare insurance system in Germany caters to the varied needs of its citizens. No matter the income level and societal status, the Health Insurance Compulsory Law makes sure that medical requirements are fulfilled as per their insurance plan.",
        },
        {
          type: "paragraph",
          text: "Private health insurance in Germany has benefits that attract Indian expats and high-income earners towards it. The premiums might be higher than those of public health insurance, but its flexibility and customization options make it a suitable option for Indian Expats who are looking to make the best out of the opportunities in Germany to grow and prosper without any health stress.",
        },
      ]
  },
  {
    id: 8,
    title : "Cost Of Health Insurance in Germany", 
    content : [
      {
        type: "image",
        url: PHI8,
      },
      {
        type:"paragraph",
        text : "In Germany, you must have health insurance - either public or private, otherwise, penalties may be imposed for lacking adequate coverage."
      },
      {
        type:"subheading",
        text : "Health Insurance"
      },
      {
        type:"subheading-text",
        text : " in Germany is mandatory by law. So, if you’re relocating to Germany, don’t forget to consider how much it will cost as you plan your move. Most people, including international students, must register for public health insurance, however, some individuals are eligible to choose private insurance. "
      },
      {
        type:"paragraph",
        text : "The German Health Insurance cost may vary depending on the age, income, employment status, and chosen insurance plan. Usually, it costs you between €80 to €1,500 per month. "
      },
      {
        type: "heading",
        text:"Table of Content"
      },
      {
        type: "subheadingdiv2",
        text:"●	Public Health Insurance in Germany"
      },
      {
        type: "subheadingdiv2",
        text:"●	Cost of Public Health Insurance in Germany"
      },
      {
        type: "subheadingdiv2",
        text:"●	Public Health Insurance and Children"
      },
      {
        type: "subheadingdiv2",
        text:"●	Private Health Insurance"
      },
      {
        type: "subheadingdiv2",
        text:"●	Cost of Private Health Insurance"
      },
      {
        type: "subheadingdiv2",
        text:"●	Health Insurance For Freelancers and International Students"
      },
      {
        type:"br",
        text: ""
      },
      {
        type:"br",
        text: ""
      },
      {
        type:"paragraph",
        text : "Now, let’s talk about each health insurance plan in detail!  "
      },
      {
        type: "subheading",
        text:"Public Health Insurance In Germany"
      },
      {
        type:"paragraph",
        text : "90% of the German population is enrolled in the public healthcare insurance system or Gesetzliche Krankenversicherung (GKV), with the other 10% choosing/having access to private healthcare insurance or Private Krankenversicherung (PKV). "
      },
      {
        type:"paragraph",
        text : "Germany's state-run public healthcare system is funded by employers and employees through social security insurance contributions."
      },
      {
        type:"paragraph",
        text : "You can opt for the public healthcare system if you meet one of these criteria self-employed, earning over €69,300 per year, student over 30, or working as a civil servant. However, you can always opt out of public health insurance in Germany. However, once you opt out of the public healthcare system, it gets difficult to get back into it, although it is possible."
      },
      {
        type: "heading",
        text:"Public Health Insurance In Germany"
      },
      {
        type:"paragraph",
        text : " Public health insurance (GKV) is calculated as a percentage of your gross salary, set at 14.6%. The contributions are split evenly between the employer and the employee (7.3% each). Moreover, public healthcare providers can add up to 1.7% as a supplementary contribution, paid by the employer. The cost is capped at a salary of €4,538 per month, limiting the maximum contribution to €360 each from both employer and employee. A compulsory nursing care contribution of 3.05% is also applied, with those without children paying slightly more at 3.3%, again split between the employer and employee."
      },
      {
        type: "heading",
        text:"Public Health Insurance and Children"
      },
      {
        type:"paragraph",
        text : "Children are generally covered under a parent's public health insurance, but private insurance may require an additional payment or a separate family plan to cover children."
      },
      {
        type: "heading",
        text: "Private Health Insurance"
      },
      {
        type:"paragraph",
        text : "Private Health Insurance in Germany is the best option for self-employed, high-earning professionals who earn more than the annual income threshold i.e.,  €69,300 per year, those earning under €450, freelancers, and students over 30.  In this, you’re responsible for paying all the contributions. "
      },
      {
        type:"paragraph",
        text : "Now, you must be wondering what expats should choose. "
      },
      {
        type:"subheading-text",
        text : "We recommend"
      },
      {
        type:"Bloglink",
        text : "expats opting for Private Health Insurance"
      },
      {
        type:"subheading-text",
        text : "to get immediate access to a wider range of healthcare services and specialists, along with other benefits like faster appointment scheduling with doctors, 24*7 illness support, dental and vision treatment, and more."
      },
      {
        type: "heading",
        text: "Private Health Insurance Costs in Germany"
      },
      {
        type:"paragraph",
        text : "Private insurance premiums are set based on the individual’s health risks, which can lead to significant price variations. Healthy, young people might pay as little as €175 per month, while older individuals or those with chronic conditions might pay up to €1,500 per month. Also, there are many private plans available that offer lower monthly premiums in exchange for higher deductibles. This arrangement might be suitable if you are in good health and have savings to cover initial medical costs. "
      },
      {
        type: "heading",
        text: "Special Considerations for Freelancers and International Students"
      },
      {
        type:"paragraph",
        text : "Freelancers in creative fields may receive discounted private insurance through the Künstlersozialkasse, with the state paying half the premium. Students under 30 benefit from reduced rates for public health insurance, around €120 to €125 per month. International students over 30 can choose between public and private plans, with private options starting as low as €70-€80."
      },
      {
        type: "heading",
        text: "Choose On-Boarding Mate For Your Health Insurance Needs"
      },
      {
        type:"paragraph",
        text : "Onboarding Mate is your common platform and trusted companion for all your insurance needs. The individuals who are new to Germany and seeking assistance with various aspects of settling down in a foreign land, don’t be afraid of choosing us as your trusted healthcare provider. We understand that the challenges of navigating a new country, especially without fluency in the local language, can be overwhelming. That's why we are here to simplify your transition, provide guidance, and offer solutions to help you thrive in your new German life. "
      },
      {
        type: "Appointmentlink",
        text : "Schedule your appointment today! "
      }
    ]
  },
  {
    "id": 9,
    "title": "Understanding International Health Insurance In Germany",
    "content": [
      {
        "type": "image",
        "url": PHI9
      },
      {
        "type": "paragraph",
        "text": "Germany boasts a healthcare system renowned for its universality, accessibility, and high-quality care. For foreigners relocating to Germany, securing health insurance is not just a legal requirement but also essential for accessing healthcare services. However, international health insurance is for the foreigners who’re traveling to Germany. It serves as a safety net for travelers, providing coverage for medical expenses and ensuring peace of mind during their stay in Germany. So, if you’re traveling to Germany for a few days, you need to opt for international health insurance before flying out to Germany."
      },
      {
        "type": "paragraph",
        "text": "However, if you’re moving to Germany as an expat, you must need to know about the two types of health insurance policies available in Germany. Let’s find out about them in detail!"
      },
      {
        "type": "heading",
        "text": "Types of Health Insurance in Germany"
      },
      {
        "type": "paragraph",
        "text": "Foreigners in Germany have two primary options for health insurance - public health insurance (Gesetzliche Krankenversicherung or GKV) and private health insurance (Private Krankenversicherung or PKV). Let’s get to them know in detail!"
      },
      {
        "type": "subheading",
        "text": "Public Health Insurance (GKV)"
      },
      {
        "type": "paragraph",
        "text": "If your gross salary is less than 69,300 euro a year (as of 2024), you must be insured by a statutory health insurance company. The general statutory health insurance rate is set at a standardized 14.6% of the gross salary. Both the employer and employee pay 7.3% of the insurance rate."
      },
      {
        "type": "subheading",
        "text": "Private Health Insurance (PKV)"
      },
      {
        "type": "paragraph",
        "text": "Private health insurance is available for individuals earning above the income threshold or those who opt out of the public system. PKV offers more extensive coverage and additional benefits compared to public insurance, such as access to private hospitals and faster medical appointments. Foreigners with a higher income or specific preferences may opt for private health insurance for greater flexibility and personalized care."
      },
      {
        "type": "heading",
        "text": "How Does Health Insurance Work in Germany?"
      },
      {
        "type": "paragraph",
        "text": "German healthcare runs on a dual system - both public and private sectors coexist smoothly. The public health insurance system covers approximately 90% of the population, while the remaining 10% is covered by private health insurance."
      },
      {
        "type": "paragraph",
        "text": "Under the public health insurance system, individuals contribute a percentage of their income towards insurance premiums, with employers also making contributions on behalf of their employees. In return, insured individuals receive comprehensive healthcare coverage and access to a wide network of healthcare providers."
      },
      {
        "type": "paragraph",
        "text": "Private health insurance, on the other hand, is based on a risky model, with premiums determined by factors such as age, health status, and desired coverage. Policyholders have greater flexibility in choosing their healthcare providers and may receive additional services not covered by public insurance."
      },
      {
        "type": "heading",
        "text": "Differences Between Private and Public Health Insurance"
      },
      {
        "type": "paragraph",
        "text": "The choice between private and public health insurance in Germany hinges on various factors, including income, employment status, and individual preferences. Here are some key differences between the two systems:"
      },
      {
        "type": "subheading",
        "text": "Coverage and Benefits"
      },
      {
        "type": "paragraph",
        "text": "Private health insurance typically offers more extensive coverage and additional benefits compared to public insurance. Policyholders may have access to a wider range of medical services, shorter waiting times, and enhanced amenities."
      },
      {
        "type": "subheading",
        "text": "Cost and Premiums"
      },
      {
        "type": "paragraph",
        "text": "While public health insurance premiums are income-based, private health insurance premiums are determined by factors such as age, health status, and chosen coverage. Private insurance premiums may be higher initially but can offer long-term cost savings and enhanced benefits."
      },
      {
        "type": "subheading",
        "text": "Access to Healthcare Providers"
      },
      {
        "type": "paragraph",
        "text": "Private health insurance allows policyholders to choose their healthcare providers, including access to specialists and private hospitals. In contrast, public health insurance restricts the choice of providers within the statutory healthcare system."
      },
      {
        "type": "subheading",
        "text": "Income Thresholds"
      },
      {
        "type": "paragraph",
        "text": "Public health insurance is mandatory for individuals earning below a certain income threshold, while private health insurance is available to those above the threshold or with specific preferences for private coverage."
      },
      {
        "type": "heading",
        "text": "How Can Private Health Insurance Help You in Germany?"
      },
      {
        "type": "paragraph",
        "text": "Health insurance serves as a valuable resource for expatriates and foreigners residing in Germany, offering comprehensive coverage and peace of mind in unfamiliar territory. Here's how international health insurance can benefit you:"
      },
      {
        "type": "subheading",
        "text": "Comprehensive Coverage"
      },
      {
        "type": "paragraph",
        "text": "International health insurance provides coverage for a wide range of medical expenses, including doctor visits, hospitalization, prescription medication, and emergency care."
      },
      {
        "type": "subheading",
        "text": "Flexibility and Mobility"
      },
      {
        "type": "paragraph",
        "text": "Private health insurance offers flexibility and mobility, allowing policyholders to access healthcare services both in Germany and abroad. This is especially beneficial for expatriates."
      },
      {
        "type": "subheading",
        "text": "Additional Services"
      },
      {
        "type": "paragraph",
        "text": "Many private health insurance plans offer additional services such as medical evacuation, repatriation, and telemedicine consultations, ensuring comprehensive care during emergencies or unforeseen circumstances."
      },
      {
        "type": "subheading",
        "text": "Peace Of Mind"
      },
      {
        "type": "paragraph",
        "text": "With health insurance, expatriates can enjoy peace of mind knowing they have access to quality healthcare services and financial protection in the event of illness or injury."
      },
      {
        "type": "heading",
        "text": "Choose Onboarding Mate’s Platform to Get Private Health Insurance!"
      },
      {
        "type": "paragraph",
        "text": "Navigating the complexities of health insurance in a foreign country can be daunting, but Onboarding Mate is here to simplify the process. We offer personalized assistance and expert guidance to help you choose the right health insurance."
      },
      {
        "type": "paragraph",
        "text": "With Onboarding Mate, you can compare insurance options, understand policy details, and receive expert advice to secure the best health insurance for your needs. We prioritize your health and well-being, offering top-notch health insurance options (by Germany’s most reliable insurance providers) and guidance to ensure you get the perfect coverage tailored to your unique requirements. Our dedicated team of experts provides exceptional service, ensuring a seamless transition to life in Germany."
      },
      {
        "type": "paragraph",
        "text": "In conclusion, private health insurance plays a crucial role in safeguarding the health and well-being of expatriates in Germany. Whether you opt for public or private coverage, securing adequate insurance is essential for accessing quality healthcare services and enjoying peace of mind during your stay in the country."
      },
      {
        "type": "italic-paragraph",
        "text": "Also, remember, international health insurance is just for travelers, and if you’re planning to settle in Germany for a job or as a freelancer, you need to opt for private health insurance."
      },
      {
        "type": "paragraph",
        "text": "With Onboarding Mate's assistance, choosing health insurance becomes a hassle-free experience, allowing you to focus on enjoying your life in Germany to the fullest."
      },
      {
        "type": "Appointmentlink",
        "text": "Get in touch with us today!"
      }
    ]
  }
  
];

export const BlogStyleOneData = [
  {
    id: 9,
    img: PHI9,
    imgAlt: "Post Img",
    parentCat: "Insurance",
    subCat: "Health",
    heading:
      "Understanding International Health Insurance In Germany",
    postPublishDate: "18 July 2024",
    postAuthor: "OnBoarding Mate",
    postLink: "/blogContent",
  },
  {
    id: 8,
    img: PHI8,
    imgAlt: "Post Img",
    parentCat: "Insurance",
    subCat: "Health",
    heading:
      "Cost Of Health Insurance in Germany",
    postPublishDate: "23 March 2024",
    postAuthor: "OnBoarding Mate",
    postLink: "/blogContent",
  },
  {
    id: 7,
    img: PHI7,
    imgAlt: "Post Img",
    parentCat: "Insurance",
    subCat: "Health",
    heading:
      "Private Health Insurance for Indian Expats- Benefits & Tips to Find Best Service Providers",
    postPublishDate: "23 March 2024",
    postAuthor: "OnBoarding Mate",
    postLink: "/blogContent",
  },
  {
    id: 6,
    img: PHI6,
    imgAlt: "Post Img",
    parentCat: "Insurance",
    subCat: "Health",
    heading:
      "How to obtain private health insurance in Germany?",
    postPublishDate: "15 March 2024",
    postAuthor: "OnBoarding Mate",
    postLink: "/blogContent",
  },
  {
    id: 5,
    img: PHI5,
    imgAlt: 'Post Img',
    parentCat: 'Insurance',
    subCat: 'Health',
    heading:
      'A Comprehensive Guide for Indian Expats Moving to Germany: Prioritizing Health',
    postPublishDate: '15 february 2024',
    postAuthor: 'OnBoarding Mate',
    postLink: '/blogContent',
  },
  {
    id: 4,
    img: PHI4,
    imgAlt: 'Post Img',
    parentCat: 'Insurance',
    subCat: 'Health',
    heading:
      'Understanding a Healthier You: The Allure of Private Health Insurance in Germany',
    postPublishDate: '16 february 2024',
    postAuthor: 'OnBoarding Mate',
    postLink: '/blogContent',
  },
  {
    id: 3,
    img: PHI3,
    imgAlt: 'Post Img',
    parentCat: 'Insurance',
    subCat: 'Health',
    heading: 'Learning Health Options: Highlighting the Beneficial',
    postPublishDate: '08 December 2023',
    postAuthor: 'OnBoarding Mate',
    postLink: '/blogContent',
  },
  {
    id: 2,
    img: PHI2,
    imgAlt: ' Post Img',
    parentCat: 'Insurance',
    subCat: 'Health',
    heading: 'Reliable Private Health Coverage in Germany',
    postPublishDate: '06 October 2023',
    postAuthor: 'OnBoarding Mate',
    postLink: '/blogContent',
  },
  {
    id: 1,
    img: PHI1,
    imgAlt: ' Post Img',
    parentCat: 'Insurance',
    subCat: 'Health',
    heading: 'Secure Your Health, Your Way',
    postPublishDate: '12 October 2023',
    postAuthor: 'Onboarding Mate',
    postLink: '/blogContent',
  },
];

export const LandingPageLink = [
  {
    affiliateLink: '/appointment/book',
  },
];
export const PublicHealthInsurancePartners = [
  {
    name: 'BARMER',
    img: BarmerImg,
 affiliateLink: '/appointment/book',
    description:
      'BARMER PUBLIC HEALTH INSURANCE offers a wide range of services for foreign professionals with English-speaking hotline and 24/7 accessibility.',
  },
];
export const ViolenceHelplinePartners = [
  {
    name: 'Hilfetelefon',
    img: BarmerImg,
  affiliateLink: '/appointment/book',
    description:
      'BARMER PUBLIC HEALTH INSURANCE offers a wide range of services for foreign professionals with English-speaking hotline and 24/7 accessibility.',
  },
];
export const PrivateHealthInsurancePartners = [
  {
    name: 'Feather',
    img: JointFinanceImg,
    affiliateLink: '/appointment/book',
    description: 'Private health insurance in Germany',
  },
];
export const PrivateHealthInsuranceAdvisors = [
  {
    name: 'Pascal Winter',
    img: Pascal,
    imgAlt: 'Image of Pascal Winter',
    affiliateLink: '/appointment/book',
    description:
      'Qualified banker and insurance broker since 15 years of experience',
  },
  {
    name: 'Alicia Aswani',
    img: newAlica,
    imgAlt: 'Image of Alicia Aswani',
    affiliateLink: '',
    description: 'Independent and International Financial advisor for Expat',
  },
  {
    name: 'Marvin Seewald',
    img: Marvin,
    imgAlt: 'Image of Marvin Seewald',
    affiliateLink: '',
    description: 'Financial consultant and advisor.',
  },
  {
    name: 'Tobias Lommer',
    img: Tobias,
    imgAlt: 'Image of Tobias Lommer',
    affiliateLink: '',
    description: 'Financial consultant and advisor.',
  },
];
export const LiabilityInsurancePartners = [
  {
    name: 'Feather',
    img: JointFinanceImg,
    affiliateLink: '/appointment/book',
    description: 'Personal liability insurance in Germany from €4.94 a month',
  },
];
export const HouseholdInsurancePartners = [
  {
    name: 'Feather',
    img: JointFinanceImg,
    affiliateLink: '/appointment/book',
    description: 'Household contents insurance starting at €2.33 per month',
  },
];

export const LegalInsurancePartners = [
  {
    name: 'Feather',
    img: JointFinanceImg,
    affiliateLink: '/appointment/book',
    description: 'Legal insurance in Germany from €17.75 per month',
  },
];

export const ExpatInsurancePartners = [
  {
    name: 'Feather',
    img: JointFinanceImg,
    affiliateLink: '/appointment/book',
    description: 'Expat health insurance in Germany starting at €72/month',
  },
];

export const DentalInsurancePartners = [
  {
    name: 'Feather',
    img: JointFinanceImg,
    affiliateLink: '/appointment/book',

    description: 'Dental insurance in Germany from just €9 a month',
  },
];

export const LifeInsurancePartners = [
  {
    name: 'Feather',
    img: JointFinanceImg,
    affiliateLink: '/appointment/book',
    description: 'Life insurance starting at €1.98 a month',
  },
];
export const DisabilityInsurancePartners = [
  {
    name: 'Feather',
    img: JointFinanceImg,
    affiliateLink: '/appointment/book',
    description: 'Disability insurance in Germany from €20 per month',
  },
];

export const BikeInsurancePartners = [
  {
    name: 'Feather',
    img: JointFinanceImg,
    affiliateLink: '/appointment/book',
    description: 'Bike insurance starting at €2.90 a month',
  },
];

export const TransferWisePartners = [
  {
    name: 'WISE',
    img: WiseImg,
    affiliateLink: 'https://wise.prf.hn/click/camref:1101lvTKK',
    description: 'Save when you send worldwide.',
  },
];
export const CurrentAccountPartners = [
  {
    name: 'Revolut',
    img: RevolutImg,
    affiliateLink: 'https://revolut.ngih.net/g11QK9',
    description: 'Send, spend and save smarter.',
  },
];
export const RentalDepositPartners = [
  {
    name: 'Rental Deposit',
    img: '',
    affiliateLink: '',
    description: 'Rental Deposit.',
  },
];
export const TYPES_OF_FAQS = {
  moneyTransfer: [
    {
      uuid: uuidv4(),
      question: 'What are the fees for international money transfers?',
      answer:
        "Fees vary depending on the provider and transfer method. It's best to compare rates and fees before making a transfer.",
    },
    {
      uuid: uuidv4(),
      question: 'What is the exchange rate for international money transfers?',
      answer:
        "Exchange rates fluctuate constantly. Providers usually offer competitive rates, but it's wise to compare them to get the best deal.",
    },
    {
      uuid: uuidv4(),
      question: 'How long does it take to transfer money internationally?',
      answer:
        'Transfer times can vary from a few minutes to several business days, depending on the destination and transfer method.',
    },
    {
      uuid: uuidv4(),
      question:
        'Are there any limits on the amount of money I can transfer internationally?',
      answer:
        'Yes, there are usually limits set by the provider or regulatory authorities. Check with your provider for specific limits.',
    },
    {
      uuid: uuidv4(),
      question: 'Is it safe to transfer money internationally online?',
      answer:
        'Yes, reputable money transfer providers use advanced security measures to ensure the safety of your transactions and personal information.',
    },
    {
      uuid: uuidv4(),
      question:
        'What information do I need to provide for an international money transfer?',
      answer:
        "Typically, you'll need the recipient's full name, address, bank account details, and your own identification documents.",
    },
    {
      uuid: uuidv4(),
      question:
        'What currencies can I send and receive through international money transfers?',
      answer:
        'Most providers support a wide range of currencies, including major ones like USD, EUR, GBP, and more. Check with your provider for the specific currencies available.',
    },
    {
      uuid: uuidv4(),
      question: 'Can I cancel or modify an international money transfer?',
      answer:
        'Cancellation or modification policies vary among providers. Some may allow changes or cancellations within a certain timeframe, while others may not offer this option.',
    },
    {
      uuid: uuidv4(),
      question: 'What is the difference between SWIFT and SEPA transfers?',
      answer:
        'SWIFT transfers are global, while SEPA transfers are limited to European countries in the SEPA zone. SEPA transfers often have lower fees and faster processing times within Europe.',
    },
    {
      uuid: uuidv4(),
      question:
        'Do I need a bank account to send or receive international money transfers?',
      answer:
        'It depends on the provider. Some allow transfers to mobile wallets or cash pickup locations, while others require a bank account for both sending and receiving funds.',
    },
  ],
  bikeInsurance: [
    {
      uuid: uuidv4(),
      question: 'What compensation can I expect from bike insurance?',
      answer:
        "If your bike is stolen and it was only a few months old, it can be frustrating as its value decreases the most during that time. With bike insurance, you'll receive the full value of a new bike without any deductions or deductibles.",
    },
    {
      uuid: uuidv4(),
      question: 'Why choose bike insurance over household contents insurance?',
      answer:
        'Bike insurance offers worldwide 24/7 protection, unlike household contents insurance, which usually only covers burglary from your home. Additionally, with bike insurance, you get full compensation for your bike without any deductions or deductibles, unlike household insurance, which often reimburses only a small percentage.',
    },
    {
      uuid: uuidv4(),
      question: 'Which bikes can be insured?',
      answer:
        'To be eligible for insurance, your bike or e-bike should be less than 6 months old and the total purchase price, including lock and accessories, should not exceed €10,000. The insurance covers privately used bicycles purchased from official retailers, and the original invoice serves as proof of purchase.',
    },
    {
      uuid: uuidv4(),
      question: 'Which bikes cannot be insured?',
      answer:
        'Unfortunately, we cannot insure certain bikes, including carbon frame bikes, fully faired bikes, velomobiles, and dirt bikes. DIY constructions or conversions, commercially used bikes, bikes from sharing providers, and bikes not purchased from official dealers are also not eligible for insurance.',
    },
    {
      uuid: uuidv4(),
      question: 'What are the security requirements for bike insurance?',
      answer:
        'To ensure insurance coverage and protect your bike against theft, it should be securely locked to an immovable object. Locks with ADFC or VdS certification are recommended for bikes of different purchase prices.',
    },
    {
      uuid: uuidv4(),
      question: 'What should I do in the event of a claim?',
      answer:
        "In case of theft or damage, contact your Insurance provider. It's important to report the incident to the police and provide a detailed list of stolen or damaged items. They will guide you through the claim process.",
    },
    {
      uuid: uuidv4(),
      question: 'What proof do I need to keep for bike insurance claims?',
      answer:
        "For claims, you'll need invoices and receipts for the affected bike, lock, and accessories. Since we provide coverage for the new value, the price and date of the initial purchase are required as proof. Keep a copy of the police report for theft or accidents. For repairs, retain the repair cost invoice.",
    },
    {
      uuid: uuidv4(),
      question: 'Why is the frame number or coding required?',
      answer:
        "The frame number is necessary to identify your bike or e-bike and ensure insurance coverage. If your bike doesn't have a frame number, it should be coded by an official specialist dealer, ADFC, or the police.",
    },
    {
      uuid: uuidv4(),
      question: 'When does the wear-damage coverage apply?',
      answer:
        "If you have a tariff with damage protection and your bike is less than 3 years old, you'll be covered for wear and tear. There is a waiting period of six months after signing the contract to be eligible for wear and tear benefits. After replacing tires or brakes, a new waiting period of six months starts for the replaced parts. This helps to provide low premiums in the long term.",
    },
  ],
  privateHealthInsurance: [
    {
      uuid: uuidv4(),
      question: 'Qualifying for Private Health Insurance in Germany?',
      answer:
        "If you are a company employee earning at least €69,300 annually, or self-employed, a freelancer, retired, or an investor earning over €30,000 per year, you qualify for private health insurance. Such categories fall under 'voluntarily insured,' permitting a choice between public and private coverage.",
    },
    {
      uuid: uuidv4(),
      question:
        'Can Self-Employed or Freelancers avail of Private Health Insurance in Germany?',
      answer:
        "Indeed, freelancers and self-employed individuals can opt for private insurance if they earn €30,000 annually or more. It's a viable option, particularly for ex-pat freelancers from non-EU countries, as public insurers may not necessarily cover them.",
    },
    {
      uuid: uuidv4(),
      question:
        'What distinguishes Private and Public Contributions in Germany?',
      answer:
        "Public health insurance requires a 15.6% income contribution, halved to 7.8% when employers share the cost. Freelancers must bear the entire amount. Alongside this, care insurance demands about 1.525% of your income. Company employees pay a maximum of €400 monthly for contributions, with an additional 3.05-3.4% for long-term care insurance, totalling roughly €460. For freelancers, this rises to around €1000. The minimum contribution is €180 for employees and €200 for freelancers. The exact amount depends on your employment status and age group. Private health insurance, however, doesn't tie to income percentage and depends on your profile.",
    },
    {
      uuid: uuidv4(),
      question:
        'How is Medical Care Payment Handled with Private Health Insurance?',
      answer:
        "Upon doctor consultation, you'll receive an invoice to pay within 14-30 days. During this period, submit the invoice via your JointFinance account for reimbursement. For hospital visits, costs are covered directly, barring exceptions like private rooms. Medication costs follow the same process. For expensive aids like wheelchairs or hearing aids, we can assist in locating the optimal product without upfront payment.",
    },
    {
      uuid: uuidv4(),
      question: 'What if I decide to leave Germany?',
      answer:
        'In the event of leaving Germany, you must formally terminate your private insurance. Simply provide us with your deregistration and confirmation of permanent departure. Depending on individual circumstances, transferring your insurance to another country might be possible, though it could incur a cost increase.',
    },
    {
      uuid: uuidv4(),
      question: 'Is Switching Back to Public Health Insurance Possible?',
      answer:
        'Switching back to public health insurance from private is possible, though it could be difficult unless you become a salaried employee. Voluntarily insured categories may not guarantee acceptance by public insurers. If you do become an employee, your salary must be less than €69,300 annually. Post-age 55, switching back to public insurance is virtually impossible.',
    },
    {
      uuid: uuidv4(),
      question: 'How can I add Dependents to my Plan?',
      answer:
        "Private insurance doesn't automatically cover dependents as public insurance does. Everyone in your household must be insured individually to avail of benefits. With JointFinance, we'll ensure coverage for your entire family.",
    },
    {
      uuid: uuidv4(),
      question: 'Does Age Influence Private Insurance Pricing?',
      answer:
        'Your contribution remains unaltered with age. The only changes to public or private insurance costs occur due to overall healthcare cost increases, typically around 2% annually.',
    },
    {
      uuid: uuidv4(),
      question: 'What happens if I become Unemployed?',
      answer:
        "Generally, you'd have to switch back to public insurance. If you've had private insurance for five years prior to unemployment, we can verify your eligibility to retain your private plan.",
    },
    {
      uuid: uuidv4(),
      question: 'Reasons for Ineligibility for Private Health Insurance?',
      answer:
        'Insurers in Germany hold discretion over acceptance, allowing for medical history-based discrimination. A single diagnosis may not necessarily lead to rejection, but a combination often does. Consult with us to check. Sometimes, insurers are open to working with individuals having extensive medical histories.',
    },
    {
      uuid: uuidv4(),
      question: 'Is a Medical Check-up Required for Enrollment?',
      answer:
        "Dependent on your circumstances, if you've had insurance for 12 months prior to enrolling, a check-up isn't necessary.",
    },
  ],
  lifeInsurance: [
    {
      uuid: uuidv4(),
      question: 'How is term life insurance beneficial?',
      answer:
        "Term life insurance is vital for anyone who is a financial pillar for others, including partners, children, or relatives. This becomes especially relevant if you share significant financial commitments like a mortgage or if your business partner's financial stability would be compromised by your demise.",
    },
    {
      uuid: uuidv4(),
      question: 'Who benefits from term life insurance the most?',
      answer:
        'Term life insurance is particularly cost-effective for young families. As you age, the cost of life insurance increases, but by that time, you are likely to have more savings and might not require as much coverage.',
    },
    {
      uuid: uuidv4(),
      question: 'How to calculate the coverage amount?',
      answer:
        "The ideal coverage should account for the following expenses: about 10k Euros per year per child until they're 18 (or 23 for university expenses), the total cost of your mortgage, and 3-5 years of all expenses if your partner depends on you. A simpler method would be to aim for 3-5x your gross yearly salary.",
    },
    {
      uuid: uuidv4(),
      question: 'How long should the coverage last?',
      answer:
        'We suggest that your coverage should last until your mortgage is fully paid or until your youngest child has completed their education, depending on which takes longer. As your financial obligations decrease, you can adjust your coverage accordingly.',
    },
    {
      uuid: uuidv4(),
      question: 'Is it possible to modify my coverage?',
      answer:
        "Certainly. You can lower your coverage sum annually. This feature is useful when you've settled a significant financial commitment, like a mortgage, but still require coverage for other dependents. Plus, you have the option to cancel the policy at any point without any additional charges.",
    },
    {
      uuid: uuidv4(),
      question: 'When does the insurance payout occur?',
      answer:
        'The insurance payout is triggered upon the death of the insured individual.',
    },
  ],
  liabilityInsurance: [
    {
      uuid: uuidv4(),
      question: 'Do I qualify for personal liability insurance in Germany?',
      answer:
        "Absolutely, as long as you reside in Germany, you're eligible for personal liability insurance. Moreover, you can carry the coverage worldwide.",
    },
    {
      uuid: uuidv4(),
      question:
        'Is personal liability insurance a legal requirement in Germany?',
      answer:
        "Although personal liability insurance isn't legally mandatory, it's often a prerequisite set by landlords when renting an apartment. Besides, about 85% of the German population holds some form of personal liability insurance.",
    },
    {
      uuid: uuidv4(),
      question: 'Is it worthwhile to have liability insurance in Germany?',
      answer:
        "Indeed, possessing liability insurance in Germany can grant you peace of mind by ensuring any damages you might inflict on another person's property or person will be fully compensated. Given that a large portion of the population holds liability insurance, its cost is equivalent to the price of a monthly cup of coffee.",
    },
    {
      uuid: uuidv4(),
      question: 'Are there any limitations to the coverage?',
      answer:
        "Yes, there exist a few cases where the insurer's coverage might be limited. These include a cap of €20,000 on damages to a borrowed motor vehicle resulting from incorrect fueling and a €5,000 limit on damages to an employer or co-worker's belongings.",
    },
    {
      uuid: uuidv4(),
      question:
        'How can I lodge a claim if I have personal liability insurance?',
      answer:
        'Filing a claim is straightforward. Simply upload the necessary documents or pictures (photographs, police reports, medical bills, invoices, etc.) to your JointFinance account. We will then handle your claim processing.',
    },
    {
      uuid: uuidv4(),
      question:
        'What distinguishes third-party liability insurance from personal liability insurance?',
      answer:
        'Both terms essentially refer to the same type of liability insurance being offered here.',
    },
    {
      uuid: uuidv4(),
      question: 'Does liability insurance offer global coverage?',
      answer: 'Yes, our liability insurance provides coverage worldwide.',
    },
    {
      uuid: uuidv4(),
      question:
        'Are there any waiting periods for my liability insurance to become effective?',
      answer:
        'No. Provided you have a valid claim, you can instantly use your liability insurance for coverage. However, remember that any incidents that occurred prior to your policy activation will not be covered.',
    },
    {
      uuid: uuidv4(),
      question: 'Can I utilize my liability insurance as soon as I register?',
      answer:
        'Absolutely. Your liability insurance becomes operative and ready to safeguard you against any accident or liability immediately after registration.',
    },
    {
      uuid: uuidv4(),
      question: 'How can I terminate my liability insurance?',
      answer:
        "There are no minimum duration or cancellation formalities. Just log into your account and select 'cancel'. Your policy will then cease to exist on your next billing date.",
    },
  ],
  legalInsurance: [
    {
      uuid: uuidv4(),
      question: 'Can I qualify for legal insurance?',
      answer:
        "Absolutely, as long as you're a resident of Germany and at least 18 years of age. To confirm your eligibility, please use our quote calculator here. Do note, if your prior legal insurance was terminated by the insurer or if you've filed more than 3 claims in the last 5 years, you may not be eligible.",
    },
    {
      uuid: uuidv4(),
      question: 'Is having legal insurance in Germany beneficial?',
      answer:
        'Certainly, particularly for Expats. Unexpected situations can arise and navigating a legal issue in an unfamiliar language and legal system can be daunting.',
    },
    {
      uuid: uuidv4(),
      question: 'What are the typical legal costs in Germany?',
      answer:
        'Without legal insurance, an initial phone consultation with a lawyer (which can vary based on the city) could set you back at approximately €250. This is merely for understanding the case and evaluating your initial chances of success.',
    },
    {
      uuid: uuidv4(),
      question: 'What are some frequent legal disputes in Germany?',
      answer:
        'Disputes often arise due to unfair travel cancellation charges, issues with vehicle purchases like warranty or repairs, disagreements over sudden employment termination, or rent increases.',
    },
    {
      uuid: uuidv4(),
      question: 'What expenses does legal insurance cover?',
      answer:
        'Legal insurance covers costs for an initial consultation (for an insured event) with a lawyer and subsequent legal proceedings.',
    },
    {
      uuid: uuidv4(),
      question: 'What legal costs are typically covered by insurance?',
      answer:
        'Commonly covered costs include lawyer fees, court costs, expenses for out-of-court dispute resolution processes such as mediation, and expert fees. Some exclusions apply, details of which can be found in the various plans we offer upon sign-up.',
    },
    {
      uuid: uuidv4(),
      question: 'Can I consult a legal expert over the phone for free?',
      answer:
        'Yes, the initial phone consultation requested via your JointFinance account is included.',
    },
    {
      uuid: uuidv4(),
      question: 'When can I start utilizing my legal insurance?',
      answer:
        'You can book a phone consultation with a legal expert immediately upon sign-up. For other legal services, there are waiting periods which vary between basic and advanced legal insurance. Keep in mind, a deductible is due before the insurance comes into effect once your claim coverage is confirmed.',
    },
    {
      uuid: uuidv4(),
      question:
        'Does my legal insurance in Germany cover pre-existing legal issues?',
      answer:
        "No, the insurance does not cover pre-existing legal issues. Hence, it's essential to get coverage before a potential crisis occurs.",
    },
    {
      uuid: uuidv4(),
      question:
        'Does this insurance cover my partner and other family members?',
      answer:
        'During sign-up, you can opt to include your family. Adding your partner incurs an additional cost of about €5 per month, offering excellent value if both partners are employed. Furthermore, children under 18 and unmarried adult children not fully employed can be included in your policy at no extra cost.',
    },
    {
      uuid: uuidv4(),
      question: 'How can I cancel my legal insurance?',
      answer:
        'Simply inform us to  cancel it. A notice period of 3 months to the end of the insurance year is required for termination',
    },
  ],
  householdInsurance: [
    {
      uuid: uuidv4(),
      question: 'Am I required to insure my whole house or apartment?',
      answer:
        "Yes, regrettably, it's not possible to insure just individual rooms. If you're part of a shared living situation, one policy should cover all belongings, with the cost divided among the household members.",
    },
    {
      uuid: uuidv4(),
      question:
        'Is there a difference between household contents insurance, home contents insurance, and personal property insurance?',
      answer:
        'All these terms – household insurance, household contents insurance, home contents insurance, and personal property insurance – refer to the same type of coverage offered here. This should not be confused with homeowners insurance, or Wohngebäudeversicherung, which is intended to cover the physical structure of the building.',
    },
    {
      uuid: uuidv4(),
      question: 'Is this insurance mandatory?',
      answer:
        'No, German law does not mandate household contents insurance. However, some landlords may require it as part of your rental agreement.',
    },
    {
      uuid: uuidv4(),
      question: 'Are there any deductibles involved?',
      answer:
        'No deductibles are applicable, but there are limitations to the payouts in certain coverage areas.',
    },
    {
      uuid: uuidv4(),
      question: 'Are there any caps on the reimbursement amounts?',
      answer:
        "Yes, there are limits on reimbursements for different types of damage, depending on whether you've opted for the Basic or Premium plan. For instance, theft of items from your garden is covered, but the compensation is capped at €500 depending on your plan type. Similarly, claims related to rainwater damage are limited to €500 per claim.",
    },
    {
      uuid: uuidv4(),
      question:
        'Will I receive a reimbursement based on the current value or the original purchase price when I file a claim?',
      answer:
        "You'll be compensated based on the value of your items in new condition, not their value at the time they were damaged or stolen.",
    },
    {
      uuid: uuidv4(),
      question: 'What does coverage restriction mean?',
      answer:
        'Coverage restrictions are situations where insurance coverage may be limited. Certain events are always excluded, such as war, nuclear energy-related incidents, wet and dry rot, storm surge, flooding, backwater, groundwater, earthquake, ground subsidence, landslide, snow pressure, avalanches, volcanic eruptions, and intentionally inflicted damage.',
    },
    {
      uuid: uuidv4(),
      question: 'Does a waiting period apply?',
      answer:
        "No, there's no waiting period for using your household contents insurance in Germany. Your policy comes into effect immediately from the start date. However, events occurring prior to the policy's start date are not covered.",
    },
    {
      uuid: uuidv4(),
      question: 'How do I go about cancelling my policy?',
      answer:
        'You can terminate your policy at any time. The cancellation will take effect immediately, or on a future date of your choosing.',
    },
  ],
  expatInsurance: [
    {
      uuid: uuidv4(),
      question: 'Am I suitable for expatriate insurance?',
      answer:
        "You're a suitable candidate for expatriate health insurance if you've recently relocated to Germany and require an initial health insurance plan. This also applies if you're unemployed or self-employed, or if you're seeking affordable, English-signup health insurance to obtain a visa or temporary residence permit.",
    },
    {
      uuid: uuidv4(),
      question: 'What if I work on my own or freelance?',
      answer:
        "Expat health insurance is highly suitable if you freelance or are self-employed. It offers coverage for emergencies, accidents, and potential illnesses. However, if you've been insured within the EU or the UK in the past three months, German regulations necessitate public insurance after relocation.",
    },
    {
      uuid: uuidv4(),
      question: 'Is this policy sufficient for my visa requirements?',
      answer:
        'Indeed, this policy is compliant with legal prerequisites for obtaining an initial visa or residence permit in Germany and serves as valid health insurance proof. However, it may not fulfil the requirements for visa or residence permit renewal.',
    },
    {
      uuid: uuidv4(),
      question: 'How soon do I receive my documents post-registration?',
      answer:
        'Instantaneously. Your policy documents are readily available in your JointFinance account upon completion of your registration. You can use these documents as valid health insurance proof for your visa or residence permit application.',
    },
    {
      uuid: uuidv4(),
      question: "What's the maximum coverage period?",
      answer:
        'Expat health insurance covers up to five years from your arrival date in Germany. For instance, if you relocated to Germany on January 1, 2020, and registered for Expat Insurance on June 2, 2021, your policy is valid until January 1, 2025. My boys got played in a stadium.',
    },
    {
      uuid: uuidv4(),
      question: "What's the Expat policy cancellation process?",
      answer:
        "If you activate your policy within 14 days of purchase, you can cancel within this timeframe for a full refund, provided no claims were made. If your policy start date is set beyond 14 days, you can cancel any time before activation for a full refund. Post this period, you're free to cancel anytime, with charges stopping from the next monthly billing cycle.",
    },
    {
      uuid: uuidv4(),
      question: 'Does it include waiting periods?',
      answer:
        'Yes, a standard waiting period of 31 days applies before you can avail of the insurance. In certain situations, this waiting period may be waived.',
    },
    {
      uuid: uuidv4(),
      question: 'Is upfront payment necessary for medical expenses?',
      answer:
        "Yes, you're required to make immediate payments for medical services. Submit all bills or receipts from your account for reimbursement. The reimbursement is made directly to you and not to the medical service provider.",
    },
    {
      uuid: uuidv4(),
      question: 'Can dependents be included in my Expat plan?',
      answer:
        'Yes, dependents can be included in your plan. However, a separate policy purchase is necessary for each dependent. We can consolidate these policies under one account for easy access.',
    },
    {
      uuid: uuidv4(),
      question: 'Are dental services covered?',
      answer:
        'Yes, Expat insurance includes coverage for necessary pain relief dental services, such as fillings and cleanings for new dental conditions, with an annual limit of €500 for the Basic plan and €1,000 for the Premium plan. However, additional dental procedures, including cosmetic treatments.',
    },
  ],
  disabilityInsurance: [
    {
      uuid: uuidv4(),
      question:
        'What amount of compensation can you expect from disability insurance?',
      answer:
        'If you meet the eligibility criteria, disability insurance will provide coverage up to a monthly payout of €2,000 or 70% of your net salary at the time of application (whichever is lower), without any additional paperwork.',
    },
    {
      uuid: uuidv4(),
      question: 'How will the benefits be paid out?',
      answer:
        'Once we receive confirmation that you have an occupational disability expected to affect your work capacity for a minimum of 6 months, with a reduction of over 50% in your ability to work, we will send the monthly payout directly to your chosen bank account.',
    },
    {
      uuid: uuidv4(),
      question: 'Am I eligible for disability insurance?',
      answer:
        'If you have already been diagnosed with an occupational disability, it is unfortunately too late to sign up for insurance. However, if you have a pre-existing condition such as diabetes or have received treatment for depression, it may still be possible to enrol in disability insurance with a higher monthly premium or exclusions related to these conditions.',
    },
    {
      uuid: uuidv4(),
      question: 'Can I increase my compensation limit later?',
      answer:
        'Absolutely! You can raise your monthly payout sum in the future. Depending on your age and health, we may ask you a few questions or require you to undergo a check-up when you request the increase.',
    },
    {
      uuid: uuidv4(),
      question: 'What happens if I lose my job and cannot afford to pay?',
      answer:
        'If you lose your job while on disability insurance and are unable to make payments, we offer relief plans for those insured for more than one year. Please contact us for more details and to determine if you qualify.',
    },
    {
      uuid: uuidv4(),
      question: 'Will I be denied disability insurance for being a smoker?',
      answer:
        'No, you will not be denied coverage solely for smoking. However, you may be denied for smoking-related illnesses or other pre-existing conditions.',
    },
    {
      uuid: uuidv4(),
      question: 'How do I make a claim?',
      answer:
        'The first step is to reach out to JointFinance, and we will guide you through the claims process. Remember, you can only make a claim for disability insurance once we receive medical confirmation of your occupational disability, which is expected to impact your work performance for at least 6 months, with a reduction of over 50% in your working capacity.',
    },
    {
      uuid: uuidv4(),
      question:
        'What distinguishes a concrete referral from an abstract referral?',
      answer:
        'Concrete Referral: In a concrete referral, the insurance will only suggest another occupation if it aligns with your skills, experience, and previous salary while maintaining a comparable social standing. Abstract Referral: On the other hand, an abstract referral occurs when you are able to work in any capacity, regardless of the field or potential salary.',
    },
  ],
  dentalInsurance: [
    {
      uuid: uuidv4(),
      question: 'How does dental insurance function?',
      answer:
        "Upon receiving dental treatments, you'll be required to pay upfront. Your public insurance might handle a part of the treatment cost, but the remainder is billed to you.",
    },
    {
      uuid: uuidv4(),
      question: 'Can supplemental dental insurance cater to root canals?',
      answer:
        'Absolutely, dental insurance does cover root canal treatments up to €150 in the first year and up to €300 for the first two years combined, with no cap after that period.',
    },
    {
      uuid: uuidv4(),
      question: 'Does supplemental dental insurance cover fillings?',
      answer:
        'Yes, dental insurance covers high-quality composite fillings that blend seamlessly with your teeth up to €150 in the first year and €300 for the first two years combined, without any limit thereafter.',
    },
    {
      uuid: uuidv4(),
      question:
        'Does supplemental dental insurance take care of orthodontic treatment?',
      answer:
        "Yes, if you're under 21, dental insurance will cover the cost of orthodontic treatments, with a limit of €150 in the first year and €300 in the first two years combined.",
    },
    {
      uuid: uuidv4(),
      question: 'Does supplemental dental insurance cover mouthguards?',
      answer:
        'Certainly, dental insurance will cover the cost of mouthguards that protect against bruxism or teeth grinding.',
    },
    {
      uuid: uuidv4(),
      question: 'Does supplemental dental insurance cover ongoing treatments?',
      answer:
        'No, dental insurance does not cater to ongoing or pre-existing treatments or conditions. If you already have several missing teeth, securing dental insurance might be a challenge.',
    },
    {
      uuid: uuidv4(),
      question: 'Are there any waiting periods?',
      answer:
        'Not at all! There are no waiting periods with dental insurance, and coverage begins as soon as the policy is activated.',
    },
  ],
  insurance: [
    {
      uuid: uuidv4(),
      question: 'What is statutory health insurance (SHI) in Germany?',
      answer:
        'SHI is mandatory health insurance financed by contributions from employees and employers, covering a wide range of medical services.',
    },
    {
      uuid: uuidv4(),
      question: 'How does the German healthcare system work?',
      answer:
        "Germany's healthcare system comprises statutory health insurance (SHI) and private health insurance (PHI). SHI is mandatory, while PHI offers more comprehensive coverage at a higher cost",
    },
    {
      uuid: uuidv4(),
      question: 'Can I choose my own doctor and hospital with SHI?',
      answer:
        'Yes, SHI allows policyholders the freedom to choose their preferred doctors and hospitals.',
    },
    {
      uuid: uuidv4(),
      question:
        "What's the difference between statutory and private health insurance in Germany?",
      answer:
        ' Statutory health insurance currently costs an average of 16.3% of gross wages half of gross wages (half of which paid by employer) plus long-term care insurance (which accounts for around 20% gross wages). The cost of private health insurance, on the other hand, depends on age, state of health and benifits',
    },
    {
      uuid: uuidv4(),
      question: 'How much does health insurance cost in Germany?',
      answer:
        'Costs vary based on income and insurance type. On average, SHI is around 14% of gross salary (shared between employee and employer), while PHI can cost several hundred euros monthly.',
    },
    {
      uuid: uuidv4(),
      question: 'Who is eligible for statutory health insurance in Germany?',
      answer:
        'All legal residents must have health insurance. Those earning below a certain amount are obligated to have SHI, while higher earners have the option for private insurance.',
    },
    {
      uuid: uuidv4(),
      question: 'Is private health insurance in Germany worth it?',
      answer:
        'It depends on your needs. PHI provides more comprehensive coverage, additional benefits, shorter waiting times, and greater flexibility. Compare options to find what suits you best.',
    },
    {
      uuid: uuidv4(),
      question: 'What are the benefits of private health insurance in Germany?',
      answer:
        'PHI offers enhanced coverage, additional benefits, shorter waiting times, and increased flexibility.',
    },
    {
      uuid: uuidv4(),
      question:
        'How does the German healthcare system compare to other countries?',
      answer:
        "Germany's healthcare system is highly regarded globally, known for its universal coverage, freedom to choose doctors and hospitals, and high satisfaction levels. However, healthcare costs have been rising.",
    },
    {
      uuid: uuidv4(),
      question: 'What is the annual insurance limit for 2023?',
      answer:
        'For 2023, you can switch to private health insurance if your gross income exceeds €66,600 per year or €5,550 per month. Still, have questions? Contact us for personalized guidance and explore the best health insurance options in Germany.',
    },
  ],
  currentAccount: [
    {
      uuid: uuidv4(),
      question:
        'What documents are required to open a bank account in Germany?',
      answer:
        'You need a valid passport or ID, proof of address in Germany, a registration certificate, and sometimes proof of income.',
    },
    {
      uuid: uuidv4(),
      question: 'How long does opening a bank account take?',
      answer:
        'Generally, the process can take anywhere from a few hours to several days, depending on the bank and its verification process.',
    },
    {
      uuid: uuidv4(),
      question: 'Is it possible to open a bank account online in Germany?',
      answer:
        'Many German banks offer online services that include the ability to open an account virtually.',
    },
    {
      uuid: uuidv4(),
      question: "What is a 'Girokonto'?",
      answer:
        "A 'Girokonto' is a standard checking or current account used for everyday banking activities such as receiving salaries and paying bills.",
    },
    {
      uuid: uuidv4(),
      question: 'Can non-residents open a bank account in Germany?',
      answer:
        'Yes, some banks in Germany do provide the option for non-residents to open an account, but requirements and processes may vary.',
    },
    {
      uuid: uuidv4(),
      question: 'Is there a minimum deposit required to open a bank account?',
      answer:
        "It depends on the bank and the type of account. Some require a minimum deposit, while others don't.",
    },
    {
      uuid: uuidv4(),
      question: 'Do German banks charge fees for account maintenance?',
      answer:
        "Some banks charge a monthly or yearly fee for account maintenance. It's crucial to verify this before opening an account.",
    },
    {
      uuid: uuidv4(),
      question: 'Can I open a bank account in Germany without a SCHUFA check?',
      answer:
        'Yes, certain banks offer a basic account (Basiskonto) without a SCHUFA credit check. However, features may be limited.',
    },
    {
      uuid: uuidv4(),
      question:
        'How is identity verification handled for online account opening?',
      answer:
        'For online applications, banks use either a video identification process or PostIdent at a local post office for identity verification.',
    },
    {
      uuid: uuidv4(),
      question: 'Can I open a business bank account as an immigrant?',
      answer:
        "Yes, you can open a business bank account in Germany as an immigrant, provided you meet the bank's specific requirements for business accounts.",
    },
  ],
  bankAccounts: [
    {
      question: "Can I use India's bank account in Germany?",
      answer:
        'Insurance is a contract which is presented as a policy to be used as a risk management tool to ensure financial protection at the time of crisis. Insurance helps an individual to ensure financial protection against losses that may arise during an unforeseen event. An insurance policy is a contract between an individual (policyholder) and an insurance company (Insurance provider), under which, the individual makes regular payments known as premiums to the insurance company which in return pays the sum assured in case an unforeseen event such as demise of the policyholder, accident, damage to the vehicles or other possessions.',
    },
    {
      question: 'Does I have to create a new bank account?',
      answer:
        'Unfortunate events like accidents, illnesses, and natural disasters come without any warning and thus it is necessary for you to keep yourself and your loved ones shielded against such unforeseen happenings. One of the best and simplest ways of keeping yourself secured against these contingent events which may cause a financial loss is buying an insurance policy.',
    },
    {
      question: 'How many days does it take to open a bank account?',
      answer:
        'As mentioned earlier, insurance is a legal contract between the policyholder and the insurance provider. The insurance policy carries all the details about the aspects and conditions under which the insurance provider will pay out the insurance amount to the policyholder or their nominee in case an unforeseen event occurs. Insurance is a financial tool which helps in ensuring financial protection of yourself and your family. Generally the person who has purchased the policy also known as policyholder has to pay premiums for the coverage available under the insurance policy. Any person can seek insurance from an insurance company.',
    },
    {
      question: 'How many ID proofs do I need to submit to open an account?',
      answer:
        'There are several types of insurance available in India. The four most common types of insurance bought in India are as mentioned below: Motor Insurance: Motor insurance policy is a type of insurance policy that provides financial assistance in the event of an accident or mishap involving your vehicle. Motor insurance can be purchased for three categories of vehicles which are personally owned four wheeler, personally owned two wheeler (bikes and scooters) and commercial vehicles. There are three types of motor insurance available in India which are Third party Liability Cover, Comprehensive Cover and Stand Alone Own-Damage cover. According to a person’s coverage requirement for their vehicle, they can choose a motor insurance that fulfills their requirements. Third party motor insurance policy is mandatory in India for all vehicles, as per the Motor Vehicles Act. Health Insurance: Health insurance policies provide financial assistance to the policyholder in case they need to be admitted to the hospital for any kind of treatment. Additionally, some health insurance plans also cover the cost of treatment which are undertaken at home before the hospitalization or after discharge. There are several health insurance plans available in India such as Individual Health Insurance, Family Floater Plans, Critical Illness Cover, Senior Citizen Health Insurance, Group Health Insurance, Maternity Health Insurance and Personal Accident Insurance.',
    },
  ],
  phoneAndInternet: [
    {
      question: 'What all carriers are there in Germany?',
      answer:
        'Insurance is a contract which is presented as a policy to be used as a risk management tool to ensure financial protection at the time of crisis. Insurance helps an individual to ensure financial protection against losses that may arise during an unforeseen event. An insurance policy is a contract between an individual (policyholder) and an insurance company (Insurance provider), under which, the individual makes regular payments known as premiums to the insurance company which in return pays the sum assured in case an unforeseen event such as demise of the policyholder, accident, damage to the vehicles or other possessions.',
    },
    {
      question: 'Can I buy a SIM card at the airport?',
      answer:
        'Unfortunate events like accidents, illnesses, and natural disasters come without any warning and thus it is necessary for you to keep yourself and your loved ones shielded against such unforeseen happenings. One of the best and simplest ways of keeping yourself secured against these contingent events which may cause a financial loss is buying an insurance policy.',
    },
  ],
  job: [
    {
      question: 'Is it easy to find jobs in Germany?',
      answer:
        'Insurance is a contract which is presented as a policy to be used as a risk management tool to ensure financial protection at the time of crisis. Insurance helps an individual to ensure financial protection against losses that may arise during an unforeseen event. An insurance policy is a contract between an individual (policyholder) and an insurance company (Insurance provider), under which, the individual makes regular payments known as premiums to the insurance company which in return pays the sum assured in case an unforeseen event such as demise of the policyholder, accident, damage to the vehicles or other possessions.',
    },
    {
      question: 'When am I eligible for work visa?',
      answer:
        'Unfortunate events like accidents, illnesses, and natural disasters come without any warning and thus it is necessary for you to keep yourself and your loved ones shielded against such unforeseen happenings. One of the best and simplest ways of keeping yourself secured against these contingent events which may cause a financial loss is buying an insurance policy.',
    },
    {
      question: 'Can I get a work visa if I am on my tourist visa?',
      answer:
        'As mentioned earlier, insurance is a legal contract between the policyholder and the insurance provider. The insurance policy carries all the details about the aspects and conditions under which the insurance provider will pay out the insurance amount to the policyholder or their nominee in case an unforeseen event occurs. Insurance is a financial tool which helps in ensuring financial protection of yourself and your family. Generally the person who has purchased the policy also known as policyholder has to pay premiums for the coverage available under the insurance policy. Any person can seek insurance from an insurance company.',
    },
    {
      question: 'Do IT companies provide me a work visa?',
      answer:
        'There are several types of insurance available in India. The four most common types of insurance bought in India are as mentioned below: Motor Insurance: Motor insurance policy is a type of insurance policy that provides financial assistance in the event of an accident or mishap involving your vehicle. Motor insurance can be purchased for three categories of vehicles which are personally owned four wheeler, personally owned two wheeler (bikes and scooters) and commercial vehicles. There are three types of motor insurance available in India which are Third party Liability Cover, Comprehensive Cover and Stand Alone Own-Damage cover. According to a person’s coverage requirement for their vehicle, they can choose a motor insurance that fulfills their requirements. Third party motor insurance policy is mandatory in India for all vehicles, as per the Motor Vehicles Act. Health Insurance: Health insurance policies provide financial assistance to the policyholder in case they need to be admitted to the hospital for any kind of treatment. Additionally, some health insurance plans also cover the cost of treatment which are undertaken at home before the hospitalization or after discharge. There are several health insurance plans available in India such as Individual Health Insurance, Family Floater Plans, Critical Illness Cover, Senior Citizen Health Insurance, Group Health Insurance, Maternity Health Insurance and Personal Accident Insurance.',
    },
    {
      question: 'Is work visa needed to work at a supermarket?',
      answer:
        'There are two ways in which you can make a claim: Cashless claims:  Get in touch with your insurer and inform them about your claim. Depending on the type of insurance (motor/health), the insurer will guide you about the cashless claim settlement process. In case of motor insurance, the insurer shares the details of a nearby cashless workshop if available and you just have to pay your part of the liability to get your car/bike repaired. In case of health insurance, you can get in touch with your TPA (Third Party Administrator) help desk at the time of hospitalization for a cashless claim.Reimbursement claim:In case of unavailability of a cashless facility, you need to make a reimbursement claim. In case of motor insurance, you need to pay the entire claim amount up front (after informing the insurer of the claim) and get it reimbursed from the insurer after the repair of your car/bike. In case of health insurance, you need to pay the entire bill amount to the hospital (after informing the insurer) and get it reimbursed from the insurer post discharge from hospital.',
    },
  ],
  faithAndCommunity: [
    {
      question: 'Can I find temples in Germany?',
      answer:
        'Insurance is a contract which is presented as a policy to be used as a risk management tool to ensure financial protection at the time of crisis. Insurance helps an individual to ensure financial protection against losses that may arise during an unforeseen event. An insurance policy is a contract between an individual (policyholder) and an insurance company (Insurance provider), under which, the individual makes regular payments known as premiums to the insurance company which in return pays the sum assured in case an unforeseen event such as demise of the policyholder, accident, damage to the vehicles or other possessions.',
    },
    {
      question: 'Are there any local Indian Communities in Germany?',
      answer:
        'Unfortunate events like accidents, illnesses, and natural disasters come without any warning and thus it is necessary for you to keep yourself and your loved ones shielded against such unforeseen happenings. One of the best and simplest ways of keeping yourself secured against these contingent events which may cause a financial loss is buying an insurance policy.',
    },
  ],
  violenceHelpline: [
    {
      question: 'Where can I complain in case of any emergency?',
      answer:
        'Our hotline is available 24*7. Please call us and get the required help',
    },
    {
      question: 'Can I get the list of the government-run hospitals?',
      answer:
        "Yes sure, do feel free to call us on our hotline and we'll be happy to provide you with all the necessary details.",
    },
  ],
  socialMedia: [
    {
      question: 'How to become an influencer in Germany?',
      answer:
        'Insurance is a contract which is presented as a policy to be used as a risk management tool to ensure financial protection at the time of crisis. Insurance helps an individual to ensure financial protection against losses that may arise during an unforeseen event. An insurance policy is a contract between an individual (policyholder) and an insurance company (Insurance provider), under which, the individual makes regular payments known as premiums to the insurance company which in return pays the sum assured in case an unforeseen event such as demise of the policyholder, accident, damage to the vehicles or other possessions.',
    },
  ],
  accommodations: [
    {
      question: 'Does Germany have provision for AirBnbs?',
      answer:
        'Insurance is a contract which is presented as a policy to be used as a risk management tool to ensure financial protection at the time of crisis. Insurance helps an individual to ensure financial protection against losses that may arise during an unforeseen event. An insurance policy is a contract between an individual (policyholder) and an insurance company (Insurance provider), under which, the individual makes regular payments known as premiums to the insurance company which in return pays the sum assured in case an unforeseen event such as demise of the policyholder, accident, damage to the vehicles or other possessions.',
    },
    {
      question: 'Can I get a rented property in Germany?',
      answer:
        'Unfortunate events like accidents, illnesses, and natural disasters come without any warning and thus it is necessary for you to keep yourself and your loved ones shielded against such unforeseen happenings. One of the best and simplest ways of keeping yourself secured against these contingent events which may cause a financial loss is buying an insurance policy.',
    },
    {
      question: 'Where can I find cheap rental properties in Germany?',
      answer:
        'As mentioned earlier, insurance is a legal contract between the policyholder and the insurance provider. The insurance policy carries all the details about the aspects and conditions under which the insurance provider will pay out the insurance amount to the policyholder or their nominee in case an unforeseen event occurs. Insurance is a financial tool which helps in ensuring financial protection of yourself and your family. Generally the person who has purchased the policy also known as policyholder has to pay premiums for the coverage available under the insurance policy. Any person can seek insurance from an insurance company.',
    },
  ],
  memberships: [
    {
      question: 'Do you want to ask a question?',
      answer:
        'Insurance is a contract which is presented as a policy to be used as a risk management tool to ensure financial protection at the time of crisis. Insurance helps an individual to ensure financial protection against losses that may arise during an unforeseen event. An insurance policy is a contract between an individual (policyholder) and an insurance company (Insurance provider), under which, the individual makes regular payments known as premiums to the insurance company which in return pays the sum assured in case an unforeseen event such as demise of the policyholder, accident, damage to the vehicles or other possessions.',
    },
  ],
  rightsAndDuties: [
    {
      question: 'What are the rights that I can practice in Germany?',
      answer:
        'Insurance is a contract which is presented as a policy to be used as a risk management tool to ensure financial protection at the time of crisis. Insurance helps an individual to ensure financial protection against losses that may arise during an unforeseen event. An insurance policy is a contract between an individual (policyholder) and an insurance company (Insurance provider), under which, the individual makes regular payments known as premiums to the insurance company which in return pays the sum assured in case an unforeseen event such as demise of the policyholder, accident, damage to the vehicles or other possessions.',
    },
  ],
  // godparents: [
  //   {
  //     question: "Will you help me find my Godparents for me?",
  //     answer:
  //       "Insurance is a contract which is presented as a policy to be used as a risk management tool to ensure financial protection at the time of crisis. Insurance helps an individual to ensure financial protection against losses that may arise during an unforeseen event. An insurance policy is a contract between an individual (policyholder) and an insurance company (Insurance provider), under which, the individual makes regular payments known as premiums to the insurance company which in return pays the sum assured in case an unforeseen event such as demise of the policyholder, accident, damage to the vehicles or other possessions.",
  //   },
  // ],
};

export const insuranceSubMenu = [
  {
    id: uuidv4(),
    title: 'Public Health',
    path: '/insurance/public-health-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: PublicHealthInsurance,
    value: 'publicHealthInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Private Health',
    path: '/insurance/private-health',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: PrivateHealth,
    value: 'privateHealthInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Liability',
    path: '/insurance/liability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: LiabilityInsurance,
    value: 'liabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Household',
    path: '/insurance/household-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: HouseholdInsurance,
    value: 'householdInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Legal',
    path: '/insurance/legal-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: LegalInsurance,
    value: 'legalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Expats',
    path: '/insurance/private-health',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: ExpatInsurance,
    value: 'expatInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Dental',
    path: '/insurance/dental-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: DentalInsurance,
    value: 'dentalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Life',
    path: '/insurance/life-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: LifeInsurance,
    value: 'lifeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Disability',
    path: '/insurance/disability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: DisabilityInsurance,
    value: 'disabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Bike',
    path: '/insurance/bike-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: BikeInsurance,
    value: 'bikeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
];
export const bankAccountsSubMenu = [
  {
    id: uuidv4(),
    title: 'Public Health',
    path: '/insurance/public-health-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'publicHealthInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Liability',
    path: '/insurance/liability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'liabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },

  {
    id: uuidv4(),
    title: 'Expats',
    path: '/insurance/private-health',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'expatInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Dental',
    path: '/insurance/dental-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'dentalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Life',
    path: '/insurance/life-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'lifeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Disability',
    path: '/insurance/disability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'disabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
];
export const moneyTransferSubMenu = [
  {
    id: uuidv4(),
    title: 'Money Transfer WISE',
    path: '/money-transfer/transfer-wise',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: TransferWise,
    value: 'transferWise',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Current Account REVOLUT',
    path: '/money-transfer/current-account',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: CurrentAccount,
    value: 'currentAccount',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Rental deposit Guarantee',
    path: '/money-transfer/rental-deposit',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: RentalDeposit,
    value: 'rentalDeposit',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
];
export const phoneInternetSubMenu = [
  {
    id: uuidv4(),
    title: 'Household',
    path: '/household-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'householdInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Legal',
    path: '/legal-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'legalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Expat',
    path: '/expat-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'expatInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Dental',
    path: '/dental-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'dentalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Life',
    path: '/life-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'lifeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Disability',
    path: '/disability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'disabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Bike',
    path: '/bike-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'bikeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
];
export const jobsSubMenu = [
  {
    id: uuidv4(),
    title: 'Expat',
    path: '/expat-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'expatInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Dental',
    path: '/dental-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'dentalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Life',
    path: '/life-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'lifeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Disability',
    path: '/disability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'disabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Bike',
    path: '/bike-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'bikeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
];

export const socialMediaSubMenu = [
  {
    id: uuidv4(),
    title: 'Public Health',
    path: '/insurance/public-health-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'publicHealthInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  // {
  //   id: uuidv4(),
  //   title: "Private Health",
  //   path: "/private-health",
  //   cName: "sub-nav",
  //   description:
  //     "There are many variations of passages the majority have suffered alteration form",
  //   img: Insurance,
  //   value: "privateHealthInsurance",
  //   // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  // },
  {
    id: uuidv4(),
    title: 'Liability',
    path: '/insurance/liability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'liabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },

  {
    id: uuidv4(),
    title: 'Expats',
    path: '/insurance/private-health',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'expatInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Dental',
    path: '/insurance/dental-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'dentalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Life',
    path: '/insurance/life-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'lifeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Disability',
    path: '/insurance/disability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'disabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Bike',
    path: '/insurance/bike-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'bikeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
];
export const membershipSubMenu = [
  {
    id: uuidv4(),
    title: 'Legal',
    path: '/insurance/legal-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'legalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Expats',
    path: '/insurance/private-health',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'expatInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Dental',
    path: '/insurance/dental-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'dentalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Life',
    path: '/insurance/life-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'lifeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Disability',
    path: '/insurance/disability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'disabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Bike',
    path: '/insurance/bike-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'bikeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
];
export const accommodationSubMenu = [
  {
    id: uuidv4(),
    title: 'Public Health',
    path: '/insurance/public-health-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'publicHealthInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  // {
  //   id: uuidv4(),
  //   title: "Private Health",
  //   path: "/private-health",
  //   cName: "sub-nav",
  //   description:
  //     "There are many variations of passages the majority have suffered alteration form",
  //   img: Insurance,
  //   value: "privateHealthInsurance",
  //   // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  // },
  {
    id: uuidv4(),
    title: 'Liability',
    path: '/insurance/liability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'liabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Household',
    path: '/insurance/household-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'householdInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Legal',
    path: '/insurance/legal-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'legalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Expats',
    path: '/insurance/private-health',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'expatInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Dental',
    path: '/insurance/dental-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'dentalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Life',
    path: '/insurance/life-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'lifeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Disability',
    path: '/insurance/disability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'disabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Bike',
    path: '/insurance/bike-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'bikeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
];
export const rightsDutiesSubMenu = [
  {
    id: uuidv4(),
    title: 'Expats',
    path: '/insurance/private-health',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'expatInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Dental',
    path: '/insurance/dental-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'dentalInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Life',
    path: '/insurance/life-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'lifeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Disability',
    path: '/insurance/disability-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'disabilityInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
  {
    id: uuidv4(),
    title: 'Bike',
    path: '/insurance/bike-insurance',
    cName: 'sub-nav',
    description:
      'There are many variations of passages the majority have suffered alteration form',
    img: Insurance,
    value: 'bikeInsurance',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
  },
];
export const individualSubService = [
  {
    id: 'orientation',
    title: 'Orientation',
    path: '/individual-service#orientation',
    description:
      'We provide the best services that will help you once you come to Germany.',
    pageDescription:
      "Discovering the ins and outs of a new city, whether it's for a job interview or after finding a new home, greatly enhances the comfort of relocating to an unfamiliar place. Local-Worldwide assists you by providing the best offers like pre-orientation call, Convenient pick-up, Guided orientation tour to help you.",
    image: OrientationImg,
    img: Orientation,
    affiliateLink: 'https://local-worldwide.com/goto/onboardingorientation/',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    // subNav: moneyTransferSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: 'immigration',
    title: 'Immigration',
    path: '/individual-service#immigration',
    description:
      'Immigration has never been easier before with our best world class services.',
    pageDescription:
      'The magnitude of individuals moving to a foreign country where they are not native or do not hold citizenship, can be overwhelming for most individuals. However, with the assistance of Local-Worldwide, navigating through the extensive bureaucratic procedures becomes a seamless experience.',
    image: ImmigrationImg,
    img: Immigration,
    affiliateLink: 'https://local-worldwide.com/goto/onboardingimmigration/',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    // subNav: moneyTransferSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: 'househome',
    title: 'House & Home',
    path: '/individual-service#househome',
    description:
      'Having problems finding new houses in Germany? We might help.',
    pageDescription:
      "Once you've secured a place to call home, everything else begins to fall into place. Thus, finding a suitable house becomes one of the primary focal points during the relocation process. With the assistance of Local-Worldwide, we can guide you in discovering the ideal property in a desirable location. Our expertise can prove invaluable in navigating the highly competitive and rapidly evolving real estate market.",
    image: HouseHomeImg,
    img: HouseHome,
    affiliateLink: {
      'House & Home(long term assignment)':
        'https://local-worldwide.com/goto/onboardinghousehome/',
      'Temporary housing package with viewings':
        'https://local-worldwide.com/goto/onboardinghouse1/',
      'Temporary housing package without viewings':
        'https://local-worldwide.com/goto/onboardinghouse2/',
    },

    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    // subNav: moneyTransferSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: 'familyAndPets',
    title: 'Family & Pets',
    path: '/individual-service#familyAndPets',
    description:
      'We got your children, pets and family covered with our world class and most reliant services and offers.',
    pageDescription:
      "Immersing your children in the German school or kindergarten system can greatly accelerate their learning process. Local-Worldwide can assist you in checking the availability of spaces in international schools at your destination and provide an overview of the German school and daycare system. Also, if you've made the decision to bring your beloved pets along on your expat journey, it's crucial to involve Local-Worldwide from the outset.",
    image: FamilyPetsImg,
    img: FamilyPets,
    affiliateLink: {
      'Child Offers': 'https://local-worldwide.com/goto/onboardingchild/',
      'Pet Offers': 'https://local-worldwide.com/goto/onboardingpet/',
    },

    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    // subNav: moneyTransferSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: 'settlingInAndMovingOut',
    title: 'Settling in & Moving out',
    path: '/individual-service#settlingInAndMovingOut',
    description:
      'Settling in and Moving out of Germany has never been easier, want to know how?',
    pageDescription:
      'The key to a comfortable daily life is when everything functions seamlessly. This includes having a bank account and debit cards, a functioning internet connection at home, and being registered with utility providers for essential services like heating, water, and electricity. Additionally, ensuring that your car and personal registration are in order with the city council is crucial.',
    image: SettlingInImg,
    img: SettlingIn,
    affiliateLink: {
      'Settling In': 'https://local-worldwide.com/goto/onboardingsettlingin/',
      'Moving Out': 'https://local-worldwide.com/goto/onboardingdeparture/',
    },
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    // subNav: moneyTransferSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  // {
  //   id: "departure",
  //   title: "Departure",
  //   path: "/individual-service#departure",
  //   description:
  //     "The departure same as the moving in process simplified for you with Local-WorldWide.",
  //   pageDescription:
  //     "With your changing thoughts we provide help to change to a new destination and setting up house and home aboard. For this, all loose ends needs to be tight up and your departure needs to be organized – contracts need to be cancelled, authorities need to be informed, your house needs to be handed back to the owner, etc… Local-Worldwide can take care of all that needs to be done, most of it even on your behalf.",
  //   image: InterculturalTrainingImg,
  //   img: InterculturalTraining,
  //   affiliateLink: "https://local-worldwide.com/goto/onboardingdeparture/",
  //   // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
  //   // subNav: moneyTransferSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  {
    id: 'expenseManagement',
    title: 'Expense Management',
    path: '/individual-service#expenseManagement',
    description:
      "Having a problem managing your expenses? Don't worry, we got you.",
    pageDescription:
      "Accurate management of relocation expenses is vital for a successful relocation program. Rental payments and hotel bookings require promptness, and registering new suppliers in a company's database adds an extra step during employee relocation. By allowing Local-Worldwide to advance the applicable fees and invoice them to your company later, you can completely avoid the need for your employees to submit expense claims for authority-related costs.",
    image: ExpenseManagementImg,
    img: ExpenseManagement,
    affiliateLink: 'https://local-worldwide.com/goto/onboardingexpense/',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    // subNav: moneyTransferSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
];
export const councilAndFaithSubMenu = [
  {
    id: 'faithAndReligion',
    value: 'faithAndReligion',
    title: 'Faith & Religion',
    path: '/council-and-faith/faith-and-religion',
    description:
      'We provide the best services that will help you once you come to Germany.',
    pageDescription:
      "Discovering the ins and outs of a new city, whether it's for a job interview or after finding a new home, greatly enhances the comfort of relocating to an unfamiliar place. Local-Worldwide assists you by providing the best offers like pre-orientation call, Convenient pick-up, Guided orientation tour to help you.",
    image: FaithReligionImg,
    img: FaithAndReligion,
    affiliateLink: 'https://local-worldwide.com/goto/onboardingorientation/',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    // subNav: moneyTransferSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: 'violenceHelpline',
    value: 'violenceHelpline',
    title: 'Violence Helpline',
    path: '/council-and-faith/violence-helpline',
    description:
      'Immigration has never been easier before with our best world class services.',
    pageDescription:
      'The magnitude of individuals moving to a foreign country where they are not native or do not hold citizenship, can be overwhelming for most individuals. However, with the assistance of Local-Worldwide, navigating through the extensive bureaucratic procedures becomes a seamless experience.',
    image: ViolenceHelplineImg,
    img: ViolenceHelpline,
    affiliateLink: 'https://local-worldwide.com/goto/onboardingimmigration/',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    // subNav: moneyTransferSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
];
export const servicesSubMenuWithOneStopServiceTwo = [
  {
    id: uuidv4(),
    title: <h3>Private Health Insurance for <span>All</span></h3>,
    path: '/insurance/private-health',
    cName: 'sub-nav',
    description:
      'Onboarding-Mate private health insurance offers superior medical care and significant savings over statutory insurance.',
    img: SettlingIn,
    value: 'insurance',
    faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
    subNav: insuranceSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: uuidv4(),
    title: <h3>Private Health Insurance for <span>Expats</span></h3>,
    path: '/insurance/private-health',
    cName: 'sub-nav',
    description:
      'Onboarding-Mate private health insurance for expats ensures top-tier care, significant savings and comprehensive coverage.',
    img: Insurance,
    value: 'insurance',
    faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
    subNav: insuranceSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  // {
  //   id: uuidv4(),  
  //   title: 'Public Health Insurance',
  //   path: '/appointment/book',
  //   cName: 'sub-nav',  
  //   description:
  //     'Affordable health insurance options for everyone.',
  //   img:  ExpenseManagement,
  //   value: 'public_insurance', 
  //   faqComponent: null,  
  //   subNav: null,  
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
];

export const servicesSubMenuWithOneStopService = [
  {
    id: uuidv4(),
    title: 'Private Health Insurance',
    path: '/insurance/private-health',
    cName: 'sub-nav',
    description:
      'Protect yourself and your family with our insurance services tailored specifically for immigrants.',
    img: Insurance,
    value: 'insurance',
    faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
    subNav: insuranceSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: uuidv4(),
    title: 'Money Transfer',
    path: '/money-transfer',
    description:
      'We offer competitive exchange rates to save your money with fast, secure, and reliable money transfer services internationally.',
    img: Transfer,
    value: 'moneyTransfer',
    faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    subNav: moneyTransferSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  // {
  //   id: uuidv4(),
  //   title: "Council & Faith",
  //   path: "/council-and-faith",
  //   description:
  //     "We offer all the help we can while you come to our country, we provide you help to connect to your faith and community.",
  //   img: Transfer,
  //   value: "councilAndFaith",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
  //   subNav: moneyTransferSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  ...individualSubService,

  // {
  //   id: uuidv4(),
  //   title: "Bank Account",
  //   path: "/bank-account",
  //   cName: "sub-nav",
  //   description:
  //     "We offer a range of resources to help you navigate the process of opening a bank account and managing your finances in Germany.",
  //   img: Bank,
  //   value: "bankAccount",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.bankAccounts} />,
  //   // subNav: bankAccountsSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },

  {
    id: uuidv4(),
    title: 'Tax Return Online',
    path: 'https://zillionpathways.germantaxes.de/',
    description:
      "With the motto 'Taxes simple for everyone', our partner, WUNDERTAX, has developed into the leading provider of target group-oriented tax solutions. Simple, understandable and secure. Get your tax refund quickly and easily with WUNDERTAX tax program.",
    img: TaxReturn,
    value: 'taxReturnOnline',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.phoneAndInternet} />,
    // subNav: phoneInternetSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: uuidv4(),
    title: 'Learn German For free',
    path: '/#learn-german',
    description:
      'With our services and our mission to make your life as easy as possible, we now provide you courses for each domain to learn German for free.',
    img: LearnGerman,
    value: 'learnGerman',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.phoneAndInternet} />,
    // subNav: phoneInternetSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },

  // {
  //   id: uuidv4(),
  //   title: "Phone & Internet",
  //   path: "/phone-internet",
  //   description:
  //     "Our plans are designed to meet the unique needs of newcomers, with low rates for international calls & Seamless Internet with no credit check required.",
  //   img: PhoneInternet,
  //   value: "phoneAndInternet",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.phoneAndInternet} />,
  //   // subNav: phoneInternetSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "All about Job",
  //   path: "/all-about-job",
  //   description:
  //     "Our platform offers job listings, resume-building tools, and career guidance to immigrants to help them achieve their career goals.",
  //   img: Job,
  //   value: "job",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.job} />,
  //   // subNav: jobsSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Faith & community",
  //   path: "/faith-and-community",
  //   description:
  //     "We connect immigrants to the same community people, providing a platform to find support, advice, and friendship.",
  //   img: Community,
  //   value: "faithAndCommunity",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.faithAndCommunity} />,
  //   // subNav: faithCommunitySubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Social Media",
  //   path: "/social-media",
  //   description:
  //     "We connect immigrants to the same community people, providing a platform to find support, advice, and friendship.",
  //   img: SocialMedia,
  //   value: "socialMedia",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.socialMedia} />,
  //   // subNav: socialMediaSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Accommodations",
  //   path: "/accommodations",
  //   description:
  //     "Our properties are located in safe and convenient areas, with options ranging from shared apartments to private rooms.",
  //   img: Accommodations,
  //   value: "accommodations",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.accommodations} />,
  //   // subNav: accommodationSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Memberships",
  //   path: "/memberships",
  //   description:
  //     "We connect immigrants to the same community people, providing a platform to find support, advice, and friendship.",
  //   img: Memberships,
  //   value: "memberships",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.memberships} />,
  //   // subNav: membershipSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Rights & Duties",
  //   path: "/rights-and-duties",
  //   description:
  //     "Easy to Understand Support system on the rights, duties, and international rules and regulations that apply to immigrants.",
  //   img: RightsDuties,
  //   value: "rightsAndDuties",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.rightsAndDuties} />,
  //   // subNav: rightsDutiesSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Godparents",
  //   path: "/godparents",
  //   description:
  //     "There are many variations of passages the majority have suffered alteration form",
  //   img: Icon_landscape,
  //   value: "godparents",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.godparents} />,
  // },
];

export const servicesSubMenu = [
  {
    id: uuidv4(),
    title: 'Private Health Insurance',
    title2: 'Health Insurance',
    title3: 'Insurance',
    path: '/insurance',
    cName: 'sub-nav',
    description:
      'Protect yourself and your family with our insurance services tailored specifically for immigrants.',
    img: Insurance,
    value: 'insurance',
    faqComponent: <ProjectFaq name={TYPES_OF_FAQS.insurance} />,
    subNav: insuranceSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: uuidv4(),
    title: 'Banking',
    title3: 'Banking',
    path: '/money-transfer',
    description:
      'We offer competitive exchange rates to save your money with fast, secure, and reliable money transfer services internationally.',
    img: Transfer,
    value: 'moneyTransfer',
    faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    subNav: moneyTransferSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: uuidv4(),
    title: 'Individual Service',
    title3: 'Individual Service',
    path: '/individual-service',
    description:
      'We offer competitive exchange rates to save your money with fast, secure, and reliable money transfer services internationally.',
    img: Transfer,
    value: 'individualService',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    subNav: individualSubService,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  {
    id: uuidv4(),
    title: 'Council and Faith',
    title3: 'Council and Faith',
    path: '/council-and-faith',
    description:
      'We offer competitive exchange rates to save your money with fast, secure, and reliable money transfer services internationally.',
    img: Transfer,
    value: 'councilAndFaith',
    // faqComponent: <ProjectFaq name={TYPES_OF_FAQS.moneyTransfer} />,
    subNav: councilAndFaithSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
  },
  // {
  //   id: uuidv4(),
  //   title: "Bank Account",
  //   path: "/bank-account",
  //   cName: "sub-nav",
  //   description:
  //     "We offer a range of resources to help you navigate the process of opening a bank account and managing your finances in Germany.",
  //   img: Bank,
  //   value: "bankAccount",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.bankAccounts} />,
  //   // subNav: bankAccountsSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },

  {
    id: uuidv4(),
    title: 'Phone & Internet',
    title3: 'Phone & Internet',
    path: '/phone-internet',
    description:
      'Our plans are designed to meet the unique needs of newcomers, with low rates for international calls & Seamless Internet with no credit check required.',
    img: PhoneInternet,
    value: 'phoneAndInternet',
    faqComponent: <ProjectFaq name={TYPES_OF_FAQS.phoneAndInternet} />,
    // subNav: phoneInternetSubMenu,
    iconClosed: <CgChevronRight />,
    iconOpened: <CgChevronDown />,
    isDisabled: true,
  },
  // {
  //   id: uuidv4(),
  //   title: "All about Job",
  //   path: "/all-about-job",
  //   description:
  //     "Our platform offers job listings, resume-building tools, and career guidance to immigrants to help them achieve their career goals.",
  //   img: Job,
  //   value: "job",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.job} />,
  //   // subNav: jobsSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Faith & community",
  //   path: "/faith-and-community",
  //   description:
  //     "We connect immigrants to the same community people, providing a platform to find support, advice, and friendship.",
  //   img: Community,
  //   value: "faithAndCommunity",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.faithAndCommunity} />,
  //   // subNav: faithCommunitySubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Social Media",
  //   path: "/social-media",
  //   description:
  //     "We connect immigrants to the same community people, providing a platform to find support, advice, and friendship.",
  //   img: SocialMedia,
  //   value: "socialMedia",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.socialMedia} />,
  //   // subNav: socialMediaSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Accommodations",
  //   path: "/accommodations",
  //   description:
  //     "Our properties are located in safe and convenient areas, with options ranging from shared apartments to private rooms.",
  //   img: Accommodations,
  //   value: "accommodations",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.accommodations} />,
  //   // subNav: accommodationSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Memberships",
  //   path: "/memberships",
  //   description:
  //     "We connect immigrants to the same community people, providing a platform to find support, advice, and friendship.",
  //   img: Memberships,
  //   value: "memberships",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.memberships} />,
  //   // subNav: membershipSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Rights & Duties",
  //   path: "/rights-and-duties",
  //   description:
  //     "Easy to Understand Support system on the rights, duties, and international rules and regulations that apply to immigrants.",
  //   img: RightsDuties,
  //   value: "rightsAndDuties",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.rightsAndDuties} />,
  //   // subNav: rightsDutiesSubMenu,
  //   iconClosed: <CgChevronRight />,
  //   iconOpened: <CgChevronDown />,
  // },
  // {
  //   id: uuidv4(),
  //   title: "Godparents",
  //   path: "/godparents",
  //   description:
  //     "There are many variations of passages the majority have suffered alteration form",
  //   img: Icon_landscape,
  //   value: "godparents",
  //   faqComponent: <ProjectFaq name={TYPES_OF_FAQS.godparents} />,
  // },
];
export const languages = [
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Spanish' },
  { value: 'de', text: 'German' },
  { value: 'ru', text: 'Russian' },
];
export const AdvisorData = {
  pascal: {
    id: uuidv4(),
    img: ProfilePascal,
    imgAlt: 'Image of Pascal Winter',

    certificate: PascalCertificate,
    calendarLink: 'https://calendly.com/onboarding-mate-insurance-1/advisor',
    certificateLink:
      'https://www.provenexpert.com/en-us/win-versicherungen/?utm_source=seals&utm_campaign=proseal&utm_medium=profile&utm_content=64bc87d9-3c53-47c7-ac4c-1c09402fa689',
    instagramLink: '/',
    pinterestLink: '/',
    heading: 'Pascal Winter',
    position: 'Qualified banker and insurance broker',
    profileLink: '/team/pascal',
    descriptionHeading: `Hi! I‘m Pascal, expert in health insurance consultancy.`,
    descriptionPara:
      "Pascal has been a broker since 15 years, providing individual service for the Indian expats. If you're looking forward on saving 4000 Euros on just health insurance, he's the man. He's an independent consultant specialised in private health insurance",
  },
  alicia: {
    id: uuidv4(),
    img: ProfileAlicia,
    imgAlt: 'Image of Alicia Aswani',
    certificate: AliciaCertificate,
    certificate1: AliciaCertificate1,
    calendarLink: 'https://calendly.com/onboarding-mate-insurance-2/advisor',
    certificateLink: 'https://www.aliciaaswani.com/',
    instagramLink: '/',
    pinterestLink: '/',
    heading: 'Alicia Aswani',
    position: 'Independent & international financial advisor for Expats',
    profileLink: '/team/alicia',
    descriptionHeading: `Hi! I‘m Alicia, Finance expert and enthusiast.`,
    descriptionPara:
      'My name is Alicia and I have made it my mission to help expats arrive in Germany – especially when it comes to financial planning and insurance. I have Indian roots and was born in Barcelona. Emigrating or starting as a foreigner in a new country is exciting – in many ways. Let me help you do the same.',
  },
};

export const AboutTeamData = {
  marc: {
    id: uuidv4(),
    img: Marc,
    imgAlt: 'Marc Carlson Img',
    fbLink: '/',
    twitterLink: '/',
    instagramLink: '/',
    pinterestLink: '/',
    heading: 'Marc Carlson',
    position: "Onboarding Mate's Founder",
    profileLink: '/team/marc',
    descriptionHeading: `Hi! I‘m Marc, Onboarding-mate‘s founder, located near Hamburg, Germany.`,
    descriptionPara:
      'Marc grew up in the Philippines and is part of the founding team of Onboarding-Mate. His tasks range from putting together motivated project teams to initiating efficient processes and integrating services from the various partners into the platform.',
  },

  alex: {
    id: uuidv4(),
    img: Alex,
    imgAlt: 'Alex V. Saenger Img',
    fbLink: '/',
    twitterLink: '/',
    instagramLink: '/',
    pinterestLink: '/',
    heading: 'Alex V. Saenger',
    profileLink: '/team/alex',
    position: "Onboarding Mate's Founder",
    descriptionHeading: `Hi! I‘m Alex, Onboarding-mate‘s founder, located in Nürnberg, Germany.`,
    descriptionPara:
      "Alex has French roots, is a lawyer in German law and responsible for OnBoarding-Mate's contracting, compliance and legal affairs.",
  },

  // shomir: {
  //   id: uuidv4(),
  //   img: AboutTeamImg4,
  //   imgAlt: 'Shomir Img',
  //   fbLink: '/',
  //   twitterLink: '/',
  //   instagramLink: '/',
  //   pinterestLink: '/',
  //   heading: 'Shomir Lal',
  //   profileLink: '/team/shomir',
  //   position: "Onboarding Mate's Partner",
  //   descriptionHeading: `Hi! I'm Shomir, Onboarding-mate's Partner, located in Germany.`,
  //   descriptionPara:
  //     'I help the organization with the technical expertise that would help us get an edge. It helps us be a notch than the rest. With the latest technology and the skilled resources our job is to deliver you the best.',
  // },

  // caryl: {
  //   id: uuidv4(),
  //   img: Caryl,
  //   imgAlt: 'Caryl Img',
  //   fbLink: '/',
  //   twitterLink: '/',
  //   instagramLink: '/',
  //   pinterestLink: '/',
  //   heading: 'Caryl',
  //   profileLink: '/team/caryl',
  //   position: "Onboarding Mate's Social Media Manager",
  //   descriptionHeading: `Hi! I'm Caryl, Onboarding-mate's Social Media Manager, located in Cebu Philippines.`,
  //   descriptionPara:
  //     'I help the organization increase brand awareness and make sure our profile stands out on social media. I develop, implement and oversee the online marketing strategy for Onboarding mate and create and execute digital marketing campaigns.',
  // },

  pankaj: {
    id: uuidv4(),
    img: Pankaj,
    imgAlt: "Pankaj Img",
    fbLink: "/",
    twitterLink: "/",
    instagramLink: "/",
    pinterestLink: "/",
    profileLink: "/team/pankaj",
    heading: "Pankaj Mittal",
    position: "Onboarding Mate's Technical Partner",
    descriptionHeading: `Hi! I'm Pankaj, Onboarding-mate's Social Media Manager, located in North India.`,
    descriptionPara:
      "I help the organization with the technical expertise that would help us get an edge. It helps us be a notch than the rest. With the latest technology and the skilled resources our job is to deliver you the best.",
  },
};
