import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import FooterOne from "../common/footer/FooterOne";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import PrivacyPolicyComponent from "../components/privacyPolicy/PrivacyPolicy";

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomOne />
      
      <PrivacyPolicyComponent />
      <FooterOne />
    </>
  );
};

export default PrivacyPolicy;
