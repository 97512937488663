import React from "react";
import { Link } from "react-router-dom";
import { servicesSubMenu } from "../../utilities/constants";
import { useLocation } from "react-router-dom";

const NavMenu = () => {
  const { pathname } = useLocation();

  return (
    <div className="main-menu text-center">
      <nav id="mobile-menu">
        <ul>
          <li key="home" className={pathname === "/" ? "on-hover" : ""}>
            <Link to="/">Home</Link>
          </li>
          {/* <li key="Insurance">
            <Link to="/insurance/private-health">Insurance</Link>
          </li> */}
          <li
            key="services"
            className={
              pathname.includes("insurance") ||
              pathname.includes("money-transfer") ||
              pathname.includes("individual-service")
                ? "on-hover"
                : ""
            }
          >
            <Link to="#" id="menu-item-services">
              Services
            </Link>
            <ul className="submenu">
              {servicesSubMenu.map((submenu, index) => {
                return submenu.subNav ? (
                  <li key={index}>
                    <Link to={submenu.path}>{submenu.title3}</Link>
                    {submenu.subNav ? (
                      <ul className="submenu-items">
                        {submenu.subNav &&
                          submenu.subNav.map((item, subIndex) => (
                            <li key={subIndex}>
                              <Link to={item.path}>{item.title} </Link>
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                ) : null;
              })}
              {/* <li>
                <img src={LogoImg} alt="selected-item" style={{width: "150px", height:"150px"}}></img>
              </li> */}
            </ul>
          </li>
         
          <li
            key="about"
            className={
              pathname.includes("about") ||
              pathname.includes("imprint") ||
              pathname.includes("privacy-policy") ||
              pathname.includes("cookie-policy")
                ? "on-hover"
                : ""
            }
          >
            <Link to="/about">About</Link>
          </li>

          <li
            key="contact"
            className={pathname.includes("contact") ? "on-hover" : ""}
          >
            <Link to="/contact">Contact</Link>
          </li>
          <li key="blog" >
            <Link to="/blog">Blogs</Link>
          </li>
          {/* <li key="blog" >
            <Link to="/counsellor"> Counsellor </Link>
          </li> */}


          {/* <li><Link to="#">Blog</Link>
                        <ul className="submenu">
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/blog-list">Blog List</Link></li>
                            <li><Link to="/blog-details">Blog Details</Link></li>
                        </ul>
                    </li> */}

        </ul>
      </nav>
    </div>

    // <Navbar bg="light" expand="lg">
    //   <Container fluid>
    //     {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
    //     <Navbar.Toggle aria-controls="navbarScroll" />
    //     <Navbar.Collapse id="navbarScroll">
    //       <Nav
    //         className="me-auto my-2 my-lg-0"
    //         style={{ maxHeight: "100px" }}
    //         navbarScroll
    //       >
    //         <Nav.Link href="/">Home</Nav.Link>
    //         <Nav.Link href="/about">About</Nav.Link>
    //         <NavDropdown title="Services" id="navbarScrollingDropdown">
    //           {servicesSubMenu.map((menuItem) => (
    //             <NavDropdown.Item href={menuItem.path}>
    //               {menuItem.title}
    //               {menuItem.subNav &&
    //                 menuItem.subNav.map((item) => (
    //                   //  <LinkContainer to={`/lesson-${lesson.nr}`}>
    //                   <NavDropdown.Item href={item.path}>
    //                     {item.title}
    //                   </NavDropdown.Item>
    //                   //  </LinkContainer>
    //                 ))}
    //             </NavDropdown.Item>
    //           ))}

    //           {/* <NavDropdown.Item href="#action4">
    //             Another action
    //           </NavDropdown.Item> */}
    //           {/* <NavDropdown.Divider /> */}
    //           {/* <NavDropdown.Item href="#action5">
    //             Something else here
    //           </NavDropdown.Item> */}
    //         </NavDropdown>
    //       </Nav>
    //       {/* <Form className="d-flex">
    //         <Form.Control
    //           type="search"
    //           placeholder="Search"
    //           className="me-2"
    //           aria-label="Search"
    //         />
    //         <Button variant="outline-success">Search</Button>
    //       </Form> */}
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
  );
};

export default NavMenu;
